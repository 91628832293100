<template>
  <div id="tabPage" class="tabPage" :style="{ height: windowHeight - 60 - 32 - 100 - 194 + 'px' }">
    <el-table :data="tableData"
              :cell-style="cellStyle"
              @cell-click="cellClick"
              :cell-class-name="addClass"
              @selection-change="checkSelect"
              border>
      <!--多选-->
      <el-table-column v-if="tablep.isChecks"
                       type="selection"
                       width="55">
      </el-table-column>
      <!--序号-->
      <el-table-column v-if="tablep.isNum"
                       type="index"
                       width="80"
                       label="序号"
                       align="left">
      </el-table-column>
      <el-table-column v-for="(item,key) in tableKey"
                       :show-overflow-tooltip="tooltip"
                       :key="key"
                       :width="item.width"
                       :min-width="item.minWidth"
                       :prop="item.value"
                       :formatter="item.formatVal"
                       :label="item.name">
      </el-table-column>
      <!--自定义操作功能-->
      <slot></slot>
    </el-table>
    <el-pagination
        id="pager"
        ref="pager"
        name="pager"
        :page-size="pageSizeObj.pageSize"
        :page-sizes="[50,100,200]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total=total>
    </el-pagination>
  </div>

</template>

<script>
export default {
  name: "tablePager",
  components: {},
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
      tooltip: true,
      tableData: [],
      currentPage: 1,
      total: 0,
    }
  },
  props: {
    pageSizeObj: {
      type: Object,
      default: () => {
        return {
          pageSize: 50
        }
      }
    },
    checkSelect: {
      type: Function,
      default: () => {

      }
    },
    tableKey: {
      type: Array,
    },
    tablep: {},
    params: {},
    cellStyle: {
      type: Function,
      default: () => {

      }
    },
    cellClick: {
      type: Function,
      default: () => {

      }
    },
    addClass: {
      type: Function,
      default: () => {

      }
    }
  },
  methods: {
    //修改每页条数触发
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSizeObj.pageSize = val;
      this.tableChange();
    },
    //翻页触发
    handleCurrentChange(val) {
      this.currentPage = val;
      this.tableChange();
    },
    //更新table数据
    tableChange() {
      this.params.data.page.pageNum = this.currentPage;
      this.params.data.page.pageSize = this.pageSizeObj.pageSize;
      //console.log(this.params);
      this.$post(this.tablep.url, this.params).then(res => {
        //console.log(res.data.body);
        //总页数
        if (res.data.rtnCode === '10000') {
          if (res.data.body.page) {
            this.total = res.data.body.page.total;
          } else {
            if (res.data.body.data) {
              this.total = res.data.body.data.length;
            } else {
              this.total = 0;
            }
          }
          //表格数据
          if (res.data.body !== undefined) {
            this.tableData = res.data.body.customerList || res.data.body.bunkList || res.data.body.floorList || res.data.body.screenVOList || res.data.body.indoorPoiList || res.data.body.outdoorPoiList || res.data.body.regionVOList || res.data.body.trafficVOList || res.data.body.businessTypeVOList || res.data.body.landMassVOList || res.data.body.kinetonemaVOList || res.data.body.customerInspectStatisticVOList || res.data.body.operationLogVOList||res.data.body.regionList||res.data.body.adGuideStatisticList||res.data.body.brightDotInfoList||res.data.body.list||res.data.body.whiteUserList||res.data.body.clientLogVOList;
            this.$emit('sendData', res.data.body);
          } else {
            this.tableData = [];
            //this.tableData=[{name:'xxx'}]
          }
          //console.log(this.tableData);
          if (document.querySelector('.el-table__body-wrapper')) {
            document.querySelector('.el-table__body-wrapper').scrollTop = 0;
          }
        } else {
          this.tableData=[]
        }
      }).catch(err => {
        // console.log(err);
        this.$_info({
          type: "error",
          message: err.message || "获取数据失败"
        });
      })
    },
  },
  created() {
  },
  mounted() {
    //该变elementui分页组件的前往为跳转到
    //this.$refs.pager.$el.children[5].childNodes[0].nodeValue = '跳转到';
  },
  watch: {
    //监听从父组件传过来的表格参数，一旦有变化就重新调接口
    'params.data': {
      deep: true,
      immediate: true,
      handler: function () {
        this.currentPage = 1;
        this.tableChange();
      }
    },
  }
}
</script>

<style lang="scss">
#tabPage {
  margin: 0 24px 0;
  font-size: 16px;
  color: rgba(166, 166, 166, 1);
  $gridBorder: 1px solid rgba(229, 229, 229, 1);
  .el-table--border::after, .el-table--group::after, .el-table::before {
    background-color: transparent;
  }
  .el-table {
    border: none;
    color: rgba(166, 166, 166, 1);
    width: 100%;
    height: 580px;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 12px 12px 0 0!important;
    .cell {
      padding: 0 0 0 24px !important;
      .focusing {
        outline: none;
      }

      &:empty {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;

        &::after {
          display: flex;
          align-items: center;
          content: "--";
          width: 100%;
          height: 100%;
          line-height: 66px;
          color: gray;
        }
      }
    }
    td, th.is-leaf {
      padding: 0;
    }
    .el-table__header-wrapper {
      height: 66px !important;
      border-radius: 12px 12px 0 0!important;
      min-height: 66px;
      thead {
        tr {
          color: $normal;
          th.is-leaf {
            height: 66px !important;
            line-height: 66px;
            color: $white !important;
            opacity: 0.8;
            font-size: 18px;
            background-color: $bgColor !important;
            box-shadow: 19px 4px 20px 0 rgba(0, 0, 0, 0.05);
            border: none;
            .cell {

            }
          }
        }
      }
    }
    .el-table__body-wrapper {
      width:100%;
      height: 100%;
      overflow-y: auto;
      tbody {
        border: none;
        color: rgba(103, 111, 117, 1);
        tr {
          background-color:$white;
          font-size: 16px;
          border-bottom: 1px solid $gridBorder;
          td {
            border-right: none;
            height: 56px;
            padding: 0;
          }
        }
      }
    }
    .el-table__body tr:hover > td {
      background-color: transparent;
    }
  }

  #pager {
    padding: 25px 0 27px;
    text-align: center;
    font-weight: normal;
    height: 28px;
    line-height: 28px;
    /*padding: 0;*/
    background-color: rgba(255, 255, 255, 1);

    %epBoxShadow {
      box-shadow: 0 1px 0 0 #E5E5E5;
    }

    %epArrowColor {
      color: #C4C6CC;
    }

    %epBorder {
      font-size: 16px;
      color: $normal;
      min-width: 28px;
      height: 28px;
      line-height: 28px;
    }

    .el-pagination__sizes {
      pointer-events: none;

      .el-input {
        .el-input__inner {
          height: 28px;
          line-height: 28px;
          border: none;
          font-size: 16px;

          &:hover {
            border-color: $pagerColor;
          }
        }
      }
    }

    .el-select .el-input__inner:focus {
      border-color: $pagerColor;
    }

    button, span:not([class*=suffix]) {
      @extend %epBorder;
    }

    span:not([class*=suffix]) {
      @extend %epBorder;
    }

    .btn-next {
      @extend %epArrowColor;
      padding: 0;
      width:50px;
      height:34px;
      border-radius: 4px;
      border: 1px solid rgba(209, 209, 209, 1);
      margin-left: 16px;
      .el-icon-arrow-right{
        margin-left: 13px;
        width:24px;
        height:24px;
        background: url('../assets/next.png') center center no-repeat;
        background-size: cover;
        &::before {
          content: '替';
          font-size: 16px;
          visibility: hidden;
        }
      }
    }

    .btn-prev {
      @extend %epArrowColor;
      width:50px;
      height:34px;
      padding: 0;
      border-radius: 4px;
      border: 1px solid rgba(209, 209, 209, 1);
      margin-right: 16px;
      .el-icon-arrow-left{
          width:24px;
          height:24px;
          margin-left: 13px;
          background: url('../assets/prev.png') center center no-repeat;
          background-size: cover;
          &::before {
            content: '替';
            font-size: 16px;
            visibility: hidden;
          }
      }
    }

    span.el-pagination__jump {
      box-shadow: none;

      .el-pagination__editor {
        width: 60px !important;
        margin: 0 8px;

        .el-input__inner {
          font-size: 16px;
          border: 1px solid $borderColor;
          width: 50px;
          height: 34px;
          line-height: 34px;
          border-radius: 4px;
          color: rgba(56, 56, 56, 1);
        }
      }
    }

    .el-pager {
      li {
        font-size: 16px;
        min-width: 34px;
        height: 34px;
        line-height: 34px;
        margin: 0 16px 0 0;
        border-radius: 4px;
        border: 1px solid rgba(209, 209, 209, 1);
        &:last-child{
          margin-right: 0;
        }
      }

      li.active, {
        background: $white;
        border: 1.5px solid $pagerColor;
        color: $pagerColor;
        border-radius: 4px;
      }

      li:hover {
        color: $pagerColor;
      }

      .more:before {
        line-height: 32px;
      }

      .el-icon-more:before {
        font-size: 10px;
      }

      .el-icon-d-arrow-left {
        @extend %epArrowColor;
        font-weight: bold;
      }

      .el-icon-d-arrow-right {
        @extend %epArrowColor;
        font-weight: bold;
      }
    }

    .custom-pagination-select {
      display: inline-flex;
      margin-right: 20px;

      #select {
        .el-input {
          height: 28px;
          width: 60px;
          margin: 0 8px;

          .el-input__inner {
            height: 26px;
            line-height: 26px;
            padding: 0 28px 0 0;
            font-size: 16px;
            text-indent: 8px;
          }

          .el-input__suffix {
            margin-top: -1px;

            .el-icon-arrow-up {
              width: 25px;
              line-height: 25px;
              font-size: 18px;
              margin: 0;
              color: $normal;
              height: 100%;
              background: none;
            }
          }
        }
      }

      .custom-pagination-text {
        display: inline-block;
        height: 100%;
      }
    }
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $pagerColor;
    border-color: $pagerColor;
  }
}
</style>






