<template>
  <div id="app">
      <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
let vm;
import base from "./utils/base.js";
import {mapActions} from "vuex";

export default {
  name: "app",
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  mounted() {
    this.$store.state.windowHeight = this.getWindowHeight();
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (localStorage.getItem('store')) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem('store'))
          )
      )
    }

    //从localStorage里读取是否保存了vuex的数据--头部标签
    let _tags = localStorage.getItem(base.tags);
    if (_tags) {
      this.initTags(_tags);
    }
    //监听页面刷新，在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      //用户信息
      localStorage.setItem(base.userInfo, JSON.stringify(this.$store.state.userInfo));
      //头部标签
      localStorage.setItem(base.tags, JSON.stringify(this.$store.state.tags));
      localStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },
  methods: {
    reload(){
      this.isRouterAlive=false;
      this.$nextTick(()=>{
        this.isRouterAlive=true;
      });
    },
    ...mapActions({
      setTags: "setTags"
    }),
    getWindowHeight() {
      return document.documentElement.clientHeight;
    },
    initTags(_tags) {
      var jsonTagsObj = JSON.parse(_tags);
      var tempTags = [];
      //除了默认页面和选中的页面，其他全部清除
      if (jsonTagsObj && jsonTagsObj.length > 0) {
        for (var i = 0; i < jsonTagsObj.length; i++) {
          if (
              jsonTagsObj[i].status === "active" ||
              jsonTagsObj[i].type === "default"
          ) {
            tempTags.push(jsonTagsObj[i]);
          }
        }
      }
      jsonTagsObj = tempTags;
      this.setTags(jsonTagsObj);
    },
  },
};
</script>
<style lang="scss">
#app {
  height:100%;
  min-width: 1500px;
  #nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;
      &.router-link-exact-active {
        color: #42b983
      }
    }
  }
}
.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>
