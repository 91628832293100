<template>
<!--  <div>
    <div @mousemove="swiperleft"
         @mouseup="mouseup"
         @mousedown="mousedown">
      <div v-for="(item,i) in imglist"
           :key="item">
        <img draggable="false"
             :class="i===curIndex?'show':'noshow'"
             :src="imglist[curIndex]"
             alt="">
      </div>
    </div>
  </div>-->

  <div class="massif">
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :cellClick="cellClick"
                       :params="listParams">
      <el-table-column width="180px"
                       label="操作">
        <template v-slot="scope">
          <basic-button :type="'text'"
                        :title="'编辑'"
                        @click.native="editMassif(scope.row)">
          </basic-button>
        </template>
      </el-table-column>
    </basic-table-pager>
    <comfort-dia v-if="editDetDia"
                 :title="editDetTitle"
                 ref="dia"
                 :dialogVisible="editDetDia"
                 :isTextArea="false"
                 :cancelTitle="'取消'"
                 :isCancel="editDetTitle==='地块详情'?false:true"
                 :submitTitle="editDetTitle==='地块详情'?'关闭':'确认'"
                 :cancel="editCancel"
                 :submit="editDetTitle==='地块详情'?detSubmit:editSubmit">
      <div slot="header"
           class="massifEdit">
        <div class="basicInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">基础信息</span>
          </div>
          <ul class="basicInfoUl">
            <li class="basicInfoLi">
              <span class="label">地块编号：</span>
              <span class="val">{{this.regionInfo.id}}</span>
            </li>
            <li class="basicInfoLi">
              <span class="label">地块名称：</span>
              <span class="val">{{this.regionInfo.regionName}}</span>
            </li>
          </ul>
        </div>
        <div class="attrInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">属性信息</span>
          </div>
          <el-form class="editForm"
                   id="editForm"
                   v-if="editDetTitle==='编辑地块'"
                   :model="editForm"
                   :rules="editFormRules"
                   autocomplete="on"
                   ref="editForm">
            <el-form-item label="开业时间"
                          prop="openTime">
              <select-input v-model="editForm.openTime"
                            :option="openTimeOptions"
                            placeholder="请输入">
              </select-input>
            </el-form-item>
            <el-form-item label="地块定位"
                          class="textareaItem"
                          prop="introduction">
              <op-input :type="'textarea'"
                        v-model="editForm.introduction"
                        placeholder="请输入">
              </op-input>
            </el-form-item>
          </el-form>
          <ul class="detUl"
              v-if="editDetTitle==='地块详情'">
            <li class="detLi">
              <span class="label">开业时间：</span>
              <span class="val">{{regionInfo.openTime}}</span>
            </li>
            <li class="detLi">
              <span class="label">地块定位：</span>
              <span class="val">{{regionInfo.introduction}}</span>
            </li>
          </ul>
        </div>
      </div>
    </comfort-dia>
  </div>
</template>

<script>
/*export default {
  data () {
    return {
      curIndex: 0,
      newindex: 0,
      trunc: 0,
      newx: 0,
      newx2: 0,
      zd: false,
      imglist: [
            require("./lvImg/001(1).jpg"),
            require("./lvImg/002(1).jpg"),
            require("./lvImg/003(1).jpg"),
            require("./lvImg/004(1).jpg"),
            require("./lvImg/005(1).jpg"),
            require("./lvImg/006(1).jpg"),
            require("./lvImg/007(1).jpg"),
            require("./lvImg/008(1).jpg"),
            require("./lvImg/009(1).jpg"),
            require("./lvImg/010(1).jpg"),
            require("./lvImg/011(1).jpg"),
            require("./lvImg/012(1).jpg"),
            require("./lvImg/013(1).jpg"),
            require("./lvImg/014(1).jpg"),
            require("./lvImg/015(1).jpg"),
            require("./lvImg/016(1).jpg"),
            require("./lvImg/017(1).jpg"),
            require("./lvImg/018(1).jpg"),
            require("./lvImg/019(1).jpg"),
            require("./lvImg/020(1).jpg"),
            require("./lvImg/021(1).jpg"),
            require("./lvImg/022(1).jpg"),
            require("./lvImg/023(1).jpg"),
            require("./lvImg/024(1).jpg"),
            require("./lvImg/025(1).jpg"),
            require("./lvImg/026(1).jpg"),
            require("./lvImg/027(1).jpg"),
            require("./lvImg/028(1).jpg"),
            require("./lvImg/029(1).jpg"),
            require("./lvImg/030(1).jpg"),
            require("./lvImg/031(1).jpg"),
            require("./lvImg/032(1).jpg"),
            require("./lvImg/033(1).jpg"),
            require("./lvImg/034(1).jpg"),
            require("./lvImg/035(1).jpg"),
            require("./lvImg/036(1).jpg"),
            require("./lvImg/037(1).jpg"),
            require("./lvImg/038(1).jpg"),
            require("./lvImg/039(1).jpg"),
            require("./lvImg/040(1).jpg"),
            require("./lvImg/041(1).jpg"),
            require("./lvImg/042(1).jpg"),
            require("./lvImg/043(1).jpg"),
            require("./lvImg/044(1).jpg"),
            require("./lvImg/045(1).jpg"),
            require("./lvImg/046(1).jpg"),
            require("./lvImg/047(1).jpg"),
            require("./lvImg/048(1).jpg"),
            require("./lvImg/049(1).jpg"),
            require("./lvImg/050(1).jpg"),
            require("./lvImg/051(1).jpg"),
            require("./lvImg/052(1).jpg"),
            require("./lvImg/053(1).jpg"),
            require("./lvImg/054(1).jpg"),
            require("./lvImg/055(1).jpg"),
            require("./lvImg/056(1).jpg"),
            require("./lvImg/057(1).jpg"),
            require("./lvImg/058(1).jpg"),
            require("./lvImg/059(1).jpg"),
            require("./lvImg/060(1).jpg"),
            require("./lvImg/061(1).jpg"),
            require("./lvImg/062(1).jpg"),
            require("./lvImg/063(1).jpg"),
            require("./lvImg/064(1).jpg"),
            require("./lvImg/065(1).jpg"),
            require("./lvImg/066(1).jpg"),
            require("./lvImg/067(1).jpg"),
            require("./lvImg/068(1).jpg"),
            require("./lvImg/069(1).jpg"),
            require("./lvImg/070(1).jpg"),
            require("./lvImg/071(1).jpg"),
            require("./lvImg/072(1).jpg"),
            require("./lvImg/073(1).jpg"),
            require("./lvImg/074(1).jpg"),
            require("./lvImg/075(1).jpg"),
            require("./lvImg/076(1).jpg"),
            require("./lvImg/077(1).jpg"),
            require("./lvImg/078(1).jpg"),
            require("./lvImg/079(1).jpg"),
            require("./lvImg/080(1).jpg"),
            require("./lvImg/081(1).jpg"),
            require("./lvImg/082(1).jpg"),
            require("./lvImg/083(1).jpg"),
            require("./lvImg/084(1).jpg"),
            require("./lvImg/085(1).jpg"),
            require("./lvImg/086(1).jpg"),
            require("./lvImg/087(1).jpg"),
            require("./lvImg/088(1).jpg"),
            require("./lvImg/089(1).jpg"),
            require("./lvImg/090(1).jpg"),
            require("./lvImg/091(1).jpg"),
            require("./lvImg/092(1).jpg"),
            require("./lvImg/093(1).jpg"),
            require("./lvImg/094(1).jpg"),
            require("./lvImg/095(1).jpg"),
            require("./lvImg/096(1).jpg"),
            require("./lvImg/097(1).jpg"),
            require("./lvImg/098(1).jpg"),
            require("./lvImg/099(1).jpg"),
            require("./lvImg/100(1).jpg"),
            require("./lvImg/101(1).jpg"),
            require("./lvImg/102(1).jpg"),
            require("./lvImg/103(1).jpg"),
            require("./lvImg/104(1).jpg"),
            require("./lvImg/105(1).jpg"),
            require("./lvImg/106(1).jpg"),
            require("./lvImg/107(1).jpg"),
            require("./lvImg/108(1).jpg"),
            require("./lvImg/109(1).jpg"),
            require("./lvImg/110(1).jpg"),
            require("./lvImg/111(1).jpg"),
            require("./lvImg/112(1).jpg"),
            require("./lvImg/113(1).jpg"),
            require("./lvImg/114(1).jpg"),
            require("./lvImg/115(1).jpg"),
            require("./lvImg/116(1).jpg"),
            require("./lvImg/117(1).jpg"),
            require("./lvImg/118(1).jpg"),
            require("./lvImg/119(1).jpg"),
            require("./lvImg/120(1).jpg"),
        ],
      /!*imglist: [
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/abbf9ddedff04ac3b70d12ea22226d20~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/6bd133b3c0df46e5b16fcca10bd39dd5~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/258f397621f749469996c4853e159191~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/4dd76bb51bb7485ca7376a1f408e9318~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/2f3149bcc69c482c8ad4b4918fe66a9d~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/59d6dc080a0948d89464c38552e4041d~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/51b02c1db4c3421aa1577a1734a40c0d~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/fa0a1ff0759a47229848076ba17f047a~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/0bf7dfd895244a5095b47b2c45756ad1~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/9b2468068cf743a783d1e1777a522d34~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/49b71149397344ec8d5be1fb515ead86~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/f6c5275ed2674204bfc8f2bd03df8716~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/f6c5275ed2674204bfc8f2bd03df8716~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/d4c03f339bb547e6a2bdae6c6a465872~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/544bd4983cb74b619218a3b0c2f7ed85~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/1e789c686a3146a2a674a1b953538eb1~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/ab99442d5ac5458184635fca7ccf4ec3~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/073a1154b01148808224c1aac90f9dbb~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/ccfd02234a9e4404880399b80bb7da83~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/cec1476cfa13456fa2682b94e37a47fb~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/01b9cb2f60f04d6b9492f928226770cc~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/6573b31cafff4814a4137dd9afa0e221~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/79c55fea9f444e1b8dd54f39721e1117~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/64d6de15d1574d6283df0826d3d481c6~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/987968c95ee2422bb9a0cc31c45b406e~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/964ac687af654480994786e19a10f836~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/ceaa76b53dba4ab48ee0c24ed09b8624~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/27bdae01eedf4c3f948ef237207e468d~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/13e763d532494c428209aa00c9dfd0ac~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/1a4f6bd68cd9458b960fb2ebd3978ee5~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/7c07bd6c01964672ae06b8d7fcf5fc87~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/a2fb2e67a6514b8d9e1471274c82185a~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/72646b43557944b0b0879bb171d891cc~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/bf35b93ec309441f86c999ac4f9e28a6~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/b6c3d41b17de44c3afc34bee0110d527~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/d1ea655d9eb7428c805594b56566906a~1200x0.webp',
        'https://p3.dcarimg.com/img/tos-cn-i-0000c0030/e3f840438c1245ad984907ac2b9f3458~1200x0.webp',
      ]*!/
    };
  },
  computed: {},
  created () { },
  watch: {
    // 如果 `textValue` 发生改变，这个函数就会运行
    trunc: function () {
      this.updateValue();
    }
  },
  methods: {
    mousedown (e) {
      //按上开始滑动
      this.zd = true
      //存储在x轴哪里开始点击的
      this.newx = e.x
      //存储在点击的时候的图片是哪张
      this.newindex = this.curIndex
    },
    //松开不能滑动
    mouseup() {
      this.zd = false
    },
    swiperleft(e) {
      //计算是往左滑动还是右面滑动
      this.newx2 = this.newx - e.x
      //每30px滑动一张  在这里调节帧率 越小越流畅
      this.trunc = Math.trunc(this.newx2 / 10) + 1
    },
    updateValue() {
      if (this.zd) {
        //当index大于数组长度了重置到第一张
        if (this.curIndex > this.imglist.length - 1) {
          this.curIndex = 0
        } else {
          //判断是往左还是往右
          if (this.trunc > 0) {
            //往右
            this.curIndex = this.newindex - this.trunc%this.imglist.length
            //滑动到index第一个了
            if (this.curIndex < 1) {
              this.curIndex = this.imglist.length - 1
              this.newindex = this.imglist.length - 1
            }
          } else {
            this.curIndex = this.newindex - this.trunc%this.imglist.length
            //滑动到index最后一个了
            if (this.curIndex > this.imglist.length - 1) {
              this.curIndex = 0
              this.newindex = 0
            }
          }
        }
      }
    }
  },
};*/
import BasicTablePager from '../../components/basicTablePager';
import BasicButton from '../../components/basic/basicButton';
import ComfortDia from '../../components/basic/comfortDialog';
import OpInput from '../../components/basic/opInput';
import SelectInput from '../../components/basic/selectInput';
import rules from "@/utils/rules";

export default {
  components: {BasicTablePager,BasicButton,ComfortDia,OpInput,SelectInput},
  inject:['reload'],
  data() {
    return {
      openTimeOptions:[{label: "2023", value: 2023},{label: "2024", value: 2024},{label: "2025", value: 2025},{label: "2026", value: 2026},{label: "2027", value: 2027},{label: "2028", value: 2028},{label: "2029", value: 2029},{label: "2030", value: 2030}],
      searchData: {},
      tableKey: [
        {
          name: '地块名称',
          value: 'regionName',
        },
        {
          name: '开业时间',
          value: 'openTime',
          formatVal: (row, column, cellValue) => {
            return new Date(cellValue).Format('yyyy-MM-dd hh:mm:ss')
          }
        },
        {
          name: '地块定位',
          value: 'introduction',
        },
      ],
      tablep: {
        url: '/private/o-wms/v1/queryRegionList',
        isNum:true,
      },
      listParams: {
        data: {},
      },
      editDetDia:false,
      editDetTitle:'',
      editForm:{
        openTime:'',
        introduction:'',
        regionId: '',
        dataVersion:''
      },
      editFormRules:{
        openTime: [{
          validator: rules.validateOpenTime,
          trigger: 'change',
          required: true
        }],
        introduction: [{
          validator: rules.validateIntroduction,
          trigger: 'blur',
          required: true
        }],
      },
      regionInfo:{
        //地块编号
        id:'',
        //地块名称
        regionName:'',
        //开业时间
        openTime:'',
        //地块定位
        introduction:''
      }
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page={pageNum: '', pageSize: ''};
  },
  methods: {
    addClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1) {
        return 'confirm'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '地块名称') {
        this.editDetDia = true;
        this.editDetTitle='地块详情';
        this.getRegionInfo(row.id,2);
      }
    },
    getRegionInfo(val,type){
      this.$post('/private/o-wms/v1/getRegionInfo', {data: {regionId: val}}).then((res) => {
        if (res.data.rtnCode === '10000') {
           let info=res.data.body.regionInfo;
           this.regionInfo.id=info.id;
           this.regionInfo.regionName=info.regionName;
           if(type===1){
             this.editForm.openTime=info.openTime;
             this.editForm.introduction=info.introduction;
             this.editForm.regionId=info.id;
             this.editForm.dataVersion=info.dataVersion;
           }else{
             this.regionInfo.openTime=info.openTime;
             this.regionInfo.introduction=info.introduction;
           }
        }
      }).catch(() => {

      });
    },
    editCancel() {
      this.editDetDia = false;
    },
    editMassif(row){
      this.editDetDia=true;
      this.editDetTitle='编辑地块';
      this.getRegionInfo(row.id,1);
    },
    detSubmit(){
      this.editDetDia = false;
    },
    editSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
            this.$post('/private/o-wms/v1/setRegionInfo', {data: this.editForm}).then((res) => {
              if (res.data.rtnCode === '10000') {
                this.$_info({
                      type: "success",
                      message: "编辑成功",
                    },
                    () => {
                      this.editDetDia = false;
                      this.reload();
                });
              }
            }).catch(() => {});
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss">
/*.show{
  opacity: 1;
  position: absolute;
  width: 800px;
}
.noshow{
  opacity: 0;
  position: absolute;
}*/
.massif {
  min-width: 1070px;
  font-size: 16px;
  padding-top: 27px;
  .tabPage .confirm .cell {
    color: rgba(87, 139, 189, 1);
    cursor: pointer;
  }
  .massifEdit{
    .basicInfo, .attrInfo {
      .infoLabel {
        height: 29px;
        margin-bottom: 8px;

        .infoIcon {
          display: inline-block;
          width: 4px;
          height: 17px;
          line-height: 29px;
          border-radius: 1px;
          background: rgba(17, 53, 86, 1);
        }

        .infoTitle {
          display: inline-block;
          margin-left: 8px;
          font-size: 20px;
          height: 29px;
          line-height: 29px;
          color: rgba(56, 56, 56, 1);
        }
      }
    }
    .basicInfoUl {
      padding: 24px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);

      .basicInfoLi {
        margin-bottom: 8px;
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        color: rgba(56, 56, 56, 1);
        margin-right: 99px;

        &:last-child {
          margin-bottom: 0;
          margin-right: 0;
        }

        .label {
          display: inline-block;
          width: 96px;
          color: rgba(128, 128, 128, 1);
        }

        .val {
          color: rgba(56, 56, 56, 1);
        }
      }
    }
    .attrInfo {
      margin-top: 24px;
      .editForm,.detUl {
        padding: 24px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        .el-form-item {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: rgba(152, 152, 152, 1) !important;
          &:last-child{
            height:102px;
          }
          &.textareaItem{
            .el-form-item__content .el-form-item__error {
              top: 100px;
            }
          }

          .el-form-item__label {
            width: 116px !important;
            height: 44px;
            line-height: 44px;
            text-align: left;
            padding: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          .el-form-item__content {
            height: 40px;
            .el-form-item__error {
              left: 116px;
            }

            #select, #opInput {
              display: inline-block;
              height:40px;

              .el-input {
                width: 280px;
                height:40px;

                .el-input__inner {
                  padding: 0 10px;
                  width: 280px;
                  height:40px;
                  &::placeholder {
                    font-size: 16px;
                    color: rgba(214, 214, 214, 1);;
                  }
                }
              }
              .el-textarea {
                width: 280px;
                height: 102px;
                .el-textarea__inner {
                  resize: none;
                  width: 280px;
                  height: 102px!important;
                  font-size: 16px!important;
                  color: rgba(56, 56, 56, 1) !important;
                  &::placeholder {
                    font-size: 16px;
                    color: rgba(214, 214, 214, 1);;
                  }
                }
              }
            }
          }
        }
      }
      .detUl{
        .detLi{
          font-size: 18px;
          margin-bottom: 21px;
          &:last-child{
            margin-bottom: 0;
          }
          .label{
            display: inline-block;
            width:116px;
            height:40px;
            color: rgba(128, 128, 128, 1);
            vertical-align: top;
          }
          .val{
            display: inline-block;
            width:368px;
            min-height: 39px;
            vertical-align: top;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
          }

        }
      }
    }
  }
}
</style>
