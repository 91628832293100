let cookie = {
    setCookie: function (name, value, iDay) {
        iDay = iDay || 3;
        let oDate = new Date();
        //用来设置过期时间用的，获取当前时间加上传进来的iDay就是过期时间
        oDate.setDate(oDate.getDate() + iDay);
        document.cookie = name + '=' + value + ';expires=' + oDate;
    },
    getCookie: function (name) {
        //多个cookie值是以; 分隔的，用split把cookie分割开并赋值给数组
        let arr = document.cookie.split('; ');
        for (let i = 0; i < arr[i].length; i++) {
            let arr2 = arr[i].split('=');
            if (arr2[0] === name) {
                return arr2[1];
            }
        }
        return ''
    },
    removeCookie: function (name) {
        this.setCookie(name, 1, -1); //-1就是告诉系统已经过期，系统就会立刻去删除cookie
    }
};
export default cookie;

Date.prototype.Format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
    let o = {
        "M+": this.getMonth() + 1,                      //月份
        "d+": this.getDate(),                           //日
        "h+": this.getHours(),                          //小时
        "m+": this.getMinutes(),                        //分
        "s+": this.getSeconds(),                        //秒
        "q+": Math.floor((this.getMonth() + 3) / 3),    //季度
        "S": this.getMilliseconds()                     //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt.includes("NaN") ? "" : fmt;
};

Date.prototype.DayFirst = function (sign) { // meng
    let date = new Date(this.valueOf());
    date.setDate(1);
    return new Date((sign ? this : date).toLocaleDateString());
};
Date.prototype.last = function (num) {
    const date = new Date();
    date.setTime(date.getTime() - 3600 * 1000 * 24 * (num ? (num - 1) : 1));
    return new Date(date.Format("yyyy-MM-dd 00:00:00"));
};
String.prototype.splice = function(index, len, v) {
    return this.slice(0, index) + v + this.slice(index + len);
};
// Base64字符串转二进制
export function dataURLtoBlob(dataurl) { // meng
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
        type: mime
    });
}


//秒数转化为时分秒
export function formatSeconds(value) {
    //  秒
    let second = parseInt(value)
    //  分
    let minute = 0
    //  小时
    let hour = 0
    //  天
    let day = 0
    //  如果秒数大于60，将秒数转换成整数
    if (second > 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        /*if (minute > 60) {
            //  获取小时，获取分钟除以60，得到整数小时
            hour = parseInt(minute / 60)
            //  获取小时后取佘的分，获取分钟除以60取佘的分
            minute = parseInt(minute % 60)
            //  如果小时大于24，将小时转换成天
             if (hour > 23) {
               //  获取天数，获取小时除以24，得到整天数
               day = parseInt(hour / 24)
               //  获取天数后取余的小时，获取小时除以24取余的小时
               hour = parseInt(hour % 24)
             }
        }*/
    }
    let result = '' + parseInt(second) + '秒'
    if (minute > 0) {
        result = '' + parseInt(minute) + '分' + result
    }
    /*if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
    }
     if (day > 0) {
       result = '' + parseInt(day) + '天' + result
     }*/
    //console.log('result：', result)
    return result
}


export function  formatDay(value){
    let currentTime=new Date().getTime();
    let diffTime=value-currentTime;
    console.log(parseInt(diffTime / 5184000));
    return parseInt(diffTime/5184000);
}

export function getDateTime (type) {
    let date = new Date();
    let hengGang = "-";
    let maoHao = ":";
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let curDate = date.getDate();
    let curHours = date.getHours();
    let curMinutes = date.getMinutes();
    let curSeconds = date.getSeconds();

    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (curDate >= 0 && curDate <= 9) {
        curDate = "0" + curDate;
    }
    if (curHours >= 0 && curHours <= 9) {
        curHours = "0" + curHours;
    }
    if (curMinutes >= 0 && curMinutes <= 9) {
        curMinutes = "0" + curMinutes;
    }
    if (curSeconds >= 0 && curSeconds <= 9) {
        curSeconds = "0" + curSeconds;
    }
    let currentdate = "";
    if (type === "year") {
        currentdate = year;
        return currentdate;
    } else if (type === "month") {
        currentdate = year + hengGang + month;
        return currentdate;
    } else {
        currentdate = year + hengGang + month + hengGang + curDate + " " + curHours + maoHao + curMinutes + maoHao + curSeconds;
        return currentdate;
    }
}