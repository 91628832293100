<template>
  <el-dialog
      class="comfortDialog"
      :title="title"
      :width="width"
      :visible.sync="visible"
      :show-close="close"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center>
    <slot name="header"></slot>
    <el-form v-if="isTextArea"
             :model="remarkForm"
             ref="remarkForm"
             :rules="rules">
      <el-form-item prop="remark">
        <!--描述-->
        <el-input :placeholder="placeholder"
                  v-model="remarkForm.remark"
                  type="textarea">
        </el-input>
      </el-form-item>
    </el-form>
    <slot name="footer"></slot>
    <!--按钮-->
    <div class="btn" v-if="isBtn">
      <el-button id="elBtn" v-if="isCancel" class="secBtnSH" :title="cancelTitle" @click="cancel">{{ cancelTitle }}
      </el-button>
      <el-button id="elBtn" class="mainBtnS" :title="submitTitle" @click="submit(remarkForm.remark)">{{ submitTitle }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "comfortDialog",
  components: {},
  props: {
    isTextArea: {
      type: Boolean,
      default: true
    },
    isBtn: {
      type: Boolean,
      default: true
    },
    isCancel: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '请输入驳回原因（7-200字）'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    close: {
      type: Boolean,
      default: false
    },
    switchStyle: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: "600px"
    },
    cancelTitle: {
      type: String,
      default: '取消'
    },
    submitTitle: {
      type: String,
      default: '提交'
    },
    cancel: {
      type: Function,
      default: () => {

      }
    },
    submit: {
      type: Function,
      default: () => {

      }
    },
    handleClose: {
      type: Function,
      default: () => {

      }
    }
  },
  data() {
    let validateDes = (rule, value, callback) => {
      let v = value.trim();
      this.remarkForm.remark = value.trim();
      if (v === '') {
        callback(new Error('不得为空'));
      } else if (v.length > 200 || v.length < 7) {
        callback(new Error('请输入7~200个字符'));
      } else {
        callback();
      }
    };
    return {
      //备注的文本框
      remarkForm: {
        remark: ''
      },
      rules: {
        remark: [{
          validator: validateDes,
          trigger: 'blur'
        }],
      },
      //remarkForm.remark
    }
  },
  computed: {
    visible: {
      get() {
        return this.dialogVisible;
      },
      set(v) {
        this.$emit("update:dialogVisible", v);
      }
    }
  },
}
</script>

<style lang="scss">
.comfortDialog {
  font-size: 16px;
  $black: #525459;
  $gray: #989BA6;
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;

  .el-dialog {
    margin: 0 !important;
    padding: 0;
    border-radius: 8px;
    background: rgba(237, 237, 237, 1);

    .el-dialog__header {
      position: relative;
      padding: 17px 24px 0 0;
      height: 31px;
      text-align: center;
      border-bottom: 1px solid rgba(229, 229, 229, 1);
      background-color: $bgColor;
      border-radius: 8px 8px 0 0;

      .el-dialog__title {
        font-size: 22px;
        line-height: 16px;
        vertical-align: top;
        color: $white;
        padding-left: 24px;
      }

      .el-dialog__headerbtn {
        position: absolute;
        top: 17px;
        right: 24px;

        .el-icon-close {
          color: #2F3033;;
        }
      }
    }

    .el-dialog__body {
      margin: 24px 24px 0;
      padding: 0 !important;

      .title {
        background-color: $white;
        padding: 34px 24px 24px;
        border-radius: 8px;
      }

      /*按钮*/
      .btn {
        padding: 24px 0 30px 0;
        text-align: right;
        .el-button{
          width:100px;
          height:50px;
        }

        .secBtnSH {
          margin-right: 12px;
        }

        .secBtnSH, .secBtnSH:hover {
          background: rgba(237, 237, 237, 1);
          border: 1px solid rgba(209, 209, 209, 1);
          color: rgba(166, 166, 166, 1);
        }

        .mainBtnS, .mainBtnS:hover {
          border: none !important;
          background: $btnA;
          color: $white;
        }
      }
    }
  }
  /*文本框*/
  .el-form {
    margin: 0;

    .el-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
    #opInput,#select {
      .el-input{
        .el-input__inner {
          border: 1px solid rgba(166, 166, 166, 1);
        }
      }
    }
    .el-textarea {
      .el-textarea__inner {
        resize: none;
        width: 280px;
        height: 102px;
        border: 1px solid rgba(166, 166, 166, 1);
      }
    }
  }
}
</style>
