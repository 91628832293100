<template>
  <el-menu
      class="menu"
      :default-active="$route.path"
      @open="handleOpen"
      @close="handleClose"
      :text-color="'rgba(135, 143, 150, 1)'"
      :active-text-color="''"
      :collapse="$store.state.isCollapse"
      :collapse-transition="false">
<!--    <el-menu-item
        index="/dashboard"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/dashboard"
        data-name="首页">
      <i class="el-icon-s-home"></i>
      <span slot="title">首页</span>
    </el-menu-item>-->
    <el-menu-item
        index="/customerManage"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/customerManage"
        data-name="客户管理">
      <i class="el-icon-customer"></i>
      <span slot="title">客户管理</span>
    </el-menu-item>
<!--    <el-menu-item
        index="/massifManage"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/massifManage"
        data-name="地块管理">
      <i class="el-icon-massif"></i>
      <span slot="title">地块管理</span>
    </el-menu-item>
    <el-menu-item
        v-if="$store.state.userInfo.role===3"
        index="/bunkManage"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/bunkManage"
        data-name="铺位管理">
      <i class="el-icon-menu"></i>
      <span slot="title">铺位管理</span>
    </el-menu-item>
    <el-menu-item
        v-if="$store.state.userInfo.role===3"
        index="/highLightsManage"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/highLightsManage"
        data-name="亮点管理">
      <i class="el-icon-menu"></i>
      <span slot="title">亮点管理</span>
    </el-menu-item>-->
<!--    v-if="$store.state.userInfo.role===3"-->
<!--    <el-menu-item

        index="/rentSaleStatus"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/rentSaleStatus"
        data-name="租售状态">
      <i class="el-icon-menu"></i>
      <span slot="title">租售状态</span>
    </el-menu-item>-->
<!--    v-if="$store.state.userInfo.role===3"-->
<!--    <el-submenu index="3">
      <template slot="title">
        <div class="first-lev-menu">
          <i class="el-icon-s-grid"></i>
          <span>内容管理</span>
        </div>
      </template>
      <el-menu-item
          class="sub-menu-item"
          index="/screen"
          @click="toPage($event)"
          data-path="/screen"
          data-name="大屏点位管理">
        大屏点位管理
      </el-menu-item>
      <el-menu-item
          index="/indoor"
          class="sub-menu-item"
          @click="toPage($event)"
          data-path="/indoor"
          data-name="室内点位管理">
        室内点位管理
      </el-menu-item>
      <el-menu-item
          index="/outdoor"
          class="sub-menu-item"
          @click="toPage($event)"
          data-path="/outdoor"
          data-name="室外点位管理">
        室外点位管理
      </el-menu-item>
      <el-menu-item
          index="/planInfo"
          class="sub-menu-item"
          @click="toPage($event)"
          data-path="/planInfo"
          data-name="规划信息管理">
        规划信息管理
      </el-menu-item>
    </el-submenu>-->
<!--    v-if="$store.state.userInfo.role===3"-->
<!--    <el-submenu index="4" v-if="$store.state.userInfo.role===3">
      <template slot="title">
        <div class="first-lev-menu">
          <i class="el-icon-dataStatistics"></i>
          <span>数据统计</span>
        </div>
      </template>
      <el-menu-item
          index="/franchiserVisit"
          class="first-lev-menu"
          @click="toPage($event)"
          data-path="/franchiserVisit"
          data-name="招商带看">
        招商带看
      </el-menu-item>
      <el-menu-item
          index="/customerVisit"
          class="first-lev-menu"
          @click="toPage($event)"
          data-path="/customerVisit"
          data-name="客户访问">
        客户访问
      </el-menu-item>
    </el-submenu>-->
<!--    <el-menu-item
        index="/authorization"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/authorization"
        data-name="授权统计">
      <i class="el-icon-customer"></i>
      <span slot="title">授权统计</span>
    </el-menu-item>-->
    <el-menu-item
        index="/staffManage"
        class="first-lev-menu"
        @click="toPage($event)"
        data-path="/staffManage"
        data-name="员工管理">
      <i class="el-icon-staff"></i>
      <span slot="title">员工管理</span>
    </el-menu-item>
    <el-submenu index="5" v-if="$store.state.userInfo.role===3">
      <template slot="title">
        <div class="first-lev-menu">
          <i class="el-icon-dataStatistics"></i>
          <span>日志管理</span>
        </div>
      </template>
      <el-menu-item
          v-if="$store.state.userInfo.role===3"
          index="/operationLog"
          class="first-lev-menu"
          @click="toPage($event)"
          data-path="/operationLog"
          data-name="管理日志">
        管理日志
      </el-menu-item>
      <el-menu-item
          index="/applicationLog"
          class="first-lev-menu"
          @click="toPage($event)"
          data-path="/applicationLog"
          data-name="应用日志">
        应用日志
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name:"leftMenus",
  data() {
    return {
      isCollapse: this.$store.state.isCollapse,
    };
  },
  methods: {
    ...mapActions({
      setTags: "setTags",
      addTags: "addTags",
    }),
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
    toPage(obj) {
      let _path = obj.$attrs["data-path"];
      let _name = obj.$attrs["data-name"];
      let _hasTag = false;
      _hasTag = this.$manageTags.checkTags(_path, _name);
      //同步头部标签
      if (_hasTag) {
        //已有标签
        this.$router.replace(_path);
      } else {
        //没有标签
        var _obj = {
          name: _name,
          status: "active",
          type: "",
          path: _path,
        };
        this.addTags(_obj);
        this.$router.replace(_path);
      }
    },
  },
};
</script>
<style lang="scss">
.menu{
  padding-top: 20px;
  border-right:0!important;
  .el-menu-item [class^=el-icon-],.el-submenu [class^=el-icon-]{
    margin-right: 21px!important;
    width: 26px!important;
  }
  .el-icon-s-home {
      width:26px;
      height:26px;
      background: url('../assets/home.png') center center no-repeat;
      background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
  .el-icon-customer{
    width:20px;
    height:16px;
    background: url('../assets/customer.png') center center no-repeat;
    background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
  .el-icon-dataStatistics{
    width:26px;
    height:26px;
    background: url('../assets/dataStatistics.png') center center no-repeat;
    background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
  .el-icon-operationLog{
    width:26px;
    height:26px;
    background: url('../assets/operationLog.png') center center no-repeat;
    background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
  .el-icon-staff{
    width:24px;
    height:24px;
    background: url('../assets/staff@2x.png') center center no-repeat;
    background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
}

.first-lev-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 24px;
  border-radius: 6px;
  padding-left: 24px !important;
  .el-tooltip{
    padding-left: 0 !important;
  }
}
.el-submenu {
  margin: 0 24px 0!important;
  .el-submenu__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 6px;
    padding: 0 !important;
    font-size: 16px;
    margin-bottom: 10px;
    &:hover{
      .el-icon-dataStatistics{
        width:26px;
        height:26px;
        background: url('../assets/dataStatisticsLight.png') center center no-repeat;
        background-size: cover;
        &::before {
          content: '替';
          font-size: 16px;
          visibility: hidden;
        }
      }
    }
  }
  .el-menu-item{
    margin:0 0 10px!important;
    &:last-child{
      margin-bottom: 0!important;
    }
  }
  .first-lev-menu{
    padding-left: 0!important;
  }
  .el-menu-item{
    padding-left: 72px!important;
  }
  .el-submenu__title:hover,.el-menu-item:active,.el-submenu__title:focus{
    outline: 0;
    background-color: $bgColor!important;
    opacity: 0.8;
    color: $white!important;
  }
}
.el-menu-item:hover,.el-menu-item:active,.el-menu-item:focus{
   outline: 0;
   background-color: $bgColor!important;
   color: $white!important;
   opacity: 0.8;
}
.el-menu-item{
  font-size: 16px!important;
  margin-bottom: 10px;
}
.el-menu-item.is-active,.el-menu-item:hover{
  .el-icon-customer{
    width:20px;
    height:16px;
    background: url('../assets/customerLight.png') center center no-repeat;
    background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
  .el-icon-operationLog{
    width:26px;
    height:26px;
    background: url('../assets/operationLogLight.png') center center no-repeat;
    background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
  .el-icon-staff{
    width:24px;
    height:24px;
    background: url('../assets/staffLight@2x.png') center center no-repeat;
    background-size: cover;
    &::before {
      content: '替';
      font-size: 16px;
      visibility: hidden;
    }
  }
}
.el-menu-item.is-active {
  outline: 0;
  background-color: $bgColor!important;
  opacity: 0.8;
  color: $white!important;
}

</style>
