<template>
    <div id="date-picker-simple">
        <el-date-picker
                :unlink-panels="true"
                :editable="false"
                :clearable="false"
                class="date-picker"
                v-model="timeR"
                type="daterange"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                popper-class="pop"
                align="left"
                :picker-options="pickerOptions">
        </el-date-picker>
    </div>

</template>

<script>
    export default {
        name: "CommonDatePick",
        props: {
            timeRange: {
                type: Array,
                default() {
                    return ['',''];
                }
            },
            startTime: {
                default: ""
            },
            endTime: {
                default: ""
            },
            monthTime:{
                type:Boolean,
                default:false
            }
        },
        computed: {
            timeR: {
                get() {
                    return [this.startTime, this.endTime];
                },
                set(v) {
                    this.$emit("update:timeRange", v);
                    this.$emit("update:startTime", v[0].Format("yyyy-MM-dd"));
                    this.$emit("update:endTime", v[1].Format("yyyy-MM-dd"));
                }
            }
        },
        data() {
            return {
                choiceDate: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(new Date().Format("yyyy-MM-dd 00:00:00")), new Date()]);
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', new Array(2).fill(new Date(date.Format("yyyy-MM-dd 00:00:00"))));
                        }
                    }, {
                        text: '最近7天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.DayFirst(true).getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [new Date(date.Format("yyyy-MM-dd 00:00:00")), new Date()]);
                        }
                    }, {
                        text: '最近30天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 29);
                            picker.$emit('pick', [new Date(date.Format("yyyy-MM-dd 00:00:00")), new Date()]);
                        }
                    }],
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime();
                        if (maxDate) {
                            this.choiceDate = ''
                        }
                    },
                    disabledDate: (time) => {
                        /*if (this.monthTime&&this.choiceDate) {
                            const one = 30 * 24 * 3600 * 1000;
                            const minTime = this.choiceDate - one;
                            const maxTime = this.choiceDate + one;
                            return time.getTime() < minTime || time.getTime() > maxTime || time.getTime() > Date.now();
                        } else {
                            // 如果没有选择日期，就要限制不能选择今天及以后
                            return time.getTime() > Date.now();
                        }*/

                      let choiceDateTime = new Date(this.choiceDate).getTime();
                      const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
                      const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
                      const min = minTime;
                      const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
                      const max = newDate < maxTime ? newDate : maxTime;
                      //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
                      if (this.choiceDate) {
                        return time.getTime() < min || time.getTime() > max;
                      }
                      //若一个日期也没选中 则 返回 当前日期以前日期可选
                      return time.getTime() > newDate;
                    }
                    /*disabledDate: (time) => {
                        return time.getTime() > Date.now();
                    }*/
                },
            }
        },
        methods:{
        }
    }

</script>

<style lang="scss">
    #date-picker-simple {
        .el-range-editor.el-input__inner {
            width: 190px;
            margin-right: 25px;
        }
        .el-range__icon {
            display: none;
        }
        .el-range-input {
            width: 75px;
        }
    }
    .el-picker-panel.el-date-range-picker.el-popper.has-sidebar.pop {
        height: 371px;
        width: 646px;
        .el-picker-panel__body {
            margin-left: 0;
        }
        .el-picker-panel__sidebar {
            height: 40px;
            width: 100%;
            top: 329px;
            .el-picker-panel__shortcut {
                display: inline-block;
                width: auto;
                margin-left: 30px;
                padding: 0;
            }
        }
    }


</style>
