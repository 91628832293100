import { render, staticRenderFns } from "./CustomBox.vue?vue&type=template&id=2ff7df8d"
import script from "./CustomBox.vue?vue&type=script&lang=js"
export * from "./CustomBox.vue?vue&type=script&lang=js"
import style0 from "./CustomBox.vue?vue&type=style&index=0&id=2ff7df8d&prod&lang=scss"
import style1 from "./CustomBox.vue?vue&type=style&index=1&id=2ff7df8d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports