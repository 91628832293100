<template>
  <el-container class="main-el-container">
    <!-- 头部 -->
    <el-header>
      <!-- 左侧标题区 -->
      <div class="headLeft"
          :style="{ width: $store.state.collWidth + 'px' }">
        <span class="manageIcon" @click="goHome"></span>
<!--        <span class="manageTitle">{{ $store.state.isCollapse ? "" : "管理系统" }}</span>-->
      </div>
      <!-- 中间区域 -->
<!--      <div class="headMiddle">
        <div class="collaspe" @click="controlLeftMenu()">
          <span class="icon"
                :class="[$store.state.isCollapse ? 'is-rotate' : '']">
          </span>
        </div>
      </div>-->
      <div class="headMiddle">
        <i class="personIcon"></i>
        <el-dropdown
            :hide-on-click="true"
            trigger="click"
            class="login-out"
            @command="handleCommand">
          <span class="dropdown-link">
            西岸金融城
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="opacity: 0 ">

          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 右侧按钮 -->
      <div class="headRight">
        <i class="personIcon"></i>
        <el-dropdown
            :hide-on-click="true"
            trigger="click"
            class="login-out"
            @command="handleCommand">
          <span class="dropdown-link">
            {{$store.state.userInfo.accountNo}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-switch-button" command="logout">
              退出
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container id="main" :style="{ 'height': windowHeight - 60 + 'px' }">
      <!-- 左侧 侧边栏-->
      <el-aside
          :width="$store.state.collWidth + 'px'"
          :style="{ 'height': windowHeight - 60 + 'px' }">
        <el-scrollbar>
          <left-menus></left-menus>
        </el-scrollbar>
      </el-aside>
      <!-- 中间内容区域 -->
      <el-container>
        <!-- 内容区头部标签栏 -->
        <main-tags></main-tags>
        <div id="mainContent">
          <!-- 内容区、内页容展示区域 -->
          <router-view/>
        </div>
        <!--底部区域 -->
        <el-footer></el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import LeftMenus from "./leftMenus";
import MainTags from "./mainTags";
import base from "../utils/base.js";
import {mapActions} from "vuex";

export default {
  name: "MainPage",
  components: {
    LeftMenus,
    MainTags,
  },
  mounted() {
    /* 挂载监听窗口变化方法,框架效果自适应大小 */
    const that = this;
    window.onresize = () => {
      return (() => {
        //console.log("==========");
        that.windowHeight = document.documentElement.clientHeight;
        that.setWindowHeight(that.windowHeight);
        //console.log(that.windowHeight);
      })();
    };
  },
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
      userName: "",
    };
  },
  watch: {
    /* windowHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      console.log(val);
      if (!this.timer) {
        // 一旦监听到的windowHeight值改变，就将其重新赋给data里的windowHeight
        this.windowHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function() {
          // 打印windowHeight变化的值
          that.timer = false;
        }, 400);
      }
    } */
  },
  methods: {
    ...mapActions({
      setWindowHeight: "setContentHeight",
      resetState: "resetState",
      changeCollapse: "changeCollapse",
      addTags: "addTags",
    }),
    goHome(){
      let _hasTag = false;
      _hasTag = this.$manageTags.checkTags("/customerManage", "客户管理");
      //同步头部标签
      if (_hasTag) {
        //已有标签
        this.$router.replace("/customerManage");
      } else {
        //没有标签
        var _obj = {
          name: "客户管理",
          status: "active",
          type: "",
          path: "/customerManage",
        };
        this.addTags(_obj);
        this.$router.replace("/customerManage");
      }
      //this.$router.push("/customerManage");
    },
    handleCommand(command) {
      if (command === "logout") {
        //退出登录
        //console.log("登出");
        //调用登出接口
        //成功后
        //清除state缓存
        localStorage.setItem(base.userInfo, "");
        localStorage.setItem(base.tags, "");
        //清除本地缓存
        this.resetState();
        //重定向到登录页面
        this.$router.replace("/login");
      }
    },
    /**
     * 控制左侧按钮导航栏收缩展开
     */
    controlLeftMenu() {
      this.changeCollapse();
    },
  },
  created() {
    this.setWindowHeight(this.windowHeight);
    this.userName = this.$store.getters.userName;
  },
};
</script>

<style lang="scss">
.main-el-container {
  //background-color: rgba(255, 255, 255, 1);
  color: rgba(166, 166, 166, 1);
  font-size: 16px;
  height:100%;
  background-color: rgba(245, 246, 250, 1);
  .el-header {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    opacity: 0.9;
    background: rgba(17, 53, 86, 1);
    box-shadow: 0 2px 5px 0 rgba(17, 53, 86, 0.5);
    .headLeft {
      display: flex;
      align-items: center;

      .manageIcon {
        cursor: pointer;
        display: inline-block;
        margin-left: 16px;
        width: 188px;
        height: 38px;
        background: url("../assets/manageIcon@2x.png") no-repeat center;
        background-size: cover;
      }

      /*.manageTitle {
        display: inline-block;
        padding-left: 16px;
        font-size: 18px;
      }*/
    }

    /*.headMiddle {
      .collaspe {
        cursor: pointer;
        width: 200px;
        height: 60px;
        display: flex;
        align-items: center;
        margin-left: 24px;

        .icon {
          display: inline-block;
          width: 34px;
          height: 34px;
          background: url("../assets/shrinExpand@2x.png") no-repeat center;
          background-size: cover;
        }

        .is-rotate {
          !* 图片旋转180 *!
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }*/
    .el-dropdown {
      font-size: 18px!important;
    }

    .headMiddle{
      display: flex;
      align-items: center;
      padding-right: 118px;
      margin-left: auto;
      font-size: 18px;
      .personIcon{
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../assets/exchangeIcon@2x.png") no-repeat center;
        background-size: cover;
      }
      .login-out {
        margin-left: 8px;
        cursor: pointer;
        color:$white;
        .el-dropdown-link {
          color: rgba(166, 166, 166, 1);
        }
      }
    }
    .headRight {
      display: flex;
      align-items: center;
      padding-right: 24px;
      font-size: 18px;
      .personIcon{
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url("../assets/personIcon@2x.png") no-repeat center;
        background-size: cover;
      }
      .login-out {
        margin-left: 8px;
        flex: 1;
        text-align: right;
        cursor: pointer;
        color:$white;
        .el-dropdown-link {
          color: rgba(166, 166, 166, 1);
        }
      }
    }
  }

  .el-footer {
    text-align: center;
    line-height: 32px;
    height: 32px !important;
  }
  #main {
    background-color: rgba(245, 246, 250, 1);
    overflow-y: auto;
    .el-aside {
      text-align: center;
      min-height: 850px;
      background-color: rgba(255, 255, 255, 1);
      .el-scrollbar__wrap{
        margin-bottom: 0!important;
      }
    }
  }
}
</style>
