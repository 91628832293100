const name = /^[\u4e00-\u9fa5a-zA-Z0-9 \（\）\(\)\《\》\<\>\“\”\"\"\/\-\_\——\.\。\，\,\！\!\、\：\:\；\;]+$/;
const mobile=/^\d{7,11}$/;
const password=/^[^\u4e00-\u9fa5]{6,10}$/;

const phone = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
const remark=/^[\u4e00-\u9fa5]{0,50}$/;

export default {
    accountNo: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请输入用户名'));
        } else {
            let strLen=value.length;
            if (strLen>20) {
                callback(new Error('用户名最多只能输入20位'));
            } else {
                callback();
            }
        }
    },
    validatePsw: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请输入密码'));
        } else {
            let strLen=value.length;
            if (strLen>20) {
                callback(new Error('密码最多只能输入20位'));
            } else {
                callback();
            }
        }
    },
    //机构名称   1-20个汉字
    validateOrganizationName: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('未满足要求格式，请检查字符长度或格式，重新输入'));
        } else {
            //let strLen=value.replace(/[^\x00-\xff]/g,"aaa").length;
            let strLen=value.length;
            if (name.test(value)&&strLen>=1&&strLen<=50) {
                callback();
            } else {
                callback(new Error('未满足要求格式，请检查字符长度或格式，重新输入'));
            }
        }
    },
    //客户名称
    validateCustomerName: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('未满足要求格式，请检查字符长度或格式，重新输入'));
        } else {
            //let strLen=value.replace(/[^\x00-\xff]/g,"aaa").length;
            let strLen=value.length;
            if (name.test(value)&&strLen>=1&&strLen<=50) {
                callback();
            } else {
                callback(new Error('未满足要求格式，请检查字符长度或格式，重新输入'));
            }
        }
    },
    //性别
    validateGender : (rule, value, callback) => {
        if (!value) {
            callback(new Error('请选择'));
        } else {
            callback();
        }
    },
    //手机
    validateAccountNo: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('未满足要求格式，请检查字符长度或格式，重新输入'));
        } else {
            if (mobile.test(value)) {
                callback();
            } else {
                callback(new Error('未满足要求格式，请检查字符长度或格式，重新输入'));
            }
        }
    },
    //密码
    validatePassword: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请输入6-10位字符，范围是英文的字母、数字及特殊符号'));
        } else {
            if (password.test(value)) {
                callback();
            } else {
                callback(new Error('请输入6-10位字符，范围是英文的字母、数字及特殊符号'));
            }
        }
    },
    validateOpenTime: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请选择'));
        }
    },
    validateIntroduction: (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请输入1-20位字符'));
        } else {
            let strLen=value.length;
            if (strLen>=1&&strLen<=20) {
                callback();
            } else {
                callback(new Error('请输入1-20位字符'));
            }
        }
    },
}
