<template>
  <div class="franchiserVisit">
    <search-form
        @clear="clear"
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <!--表格-->
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       @sendData="sendData"
                       :params="listParams">
    </basic-table-pager>
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import '../../utils/tools';
import {formatSeconds,getDateTime} from "@/utils/tools";


export default {
  components: {SearchForm, BasicTablePager},
  inject:['reload'],
  data() {
    return {
      searchData: {
        month:getDateTime('month').split('-')[1],
        year:getDateTime('month').split('-')[0],
        adName:'',
      },
      searchForm: [
        {
          type: 'MonthYear',
          class:'monthYearC',
          prop:['month','year','monthVal'],
          monthVal:getDateTime('month'),
          id:1
        },
        {
          type:'SearchAccurate',
          label: '',
          placeholder:'请输入招商名称',
          prop:'adName',
          id:2,
          class:'searchItem'
        },
        {
          type:'Button',
          class: 'reset',
          title:'重置',
          id:3,
        },
      ],
      tableKey: [
        {
          name: '招商人员（OA）',
          value: 'adName',
        },
        {
          name: '带看客户数',
          value: 'customerCount',
        },
        {
          name: '带看次数',
          value: 'guideTimes',
        },
        {
          name: '带看时长',
          value: 'totalGuideDuration',
          formatVal: (row, column, cellValue) => {
            return formatSeconds(cellValue);
          }
        },
        {
          name: '访问次数',
          value: 'inspectTimes',
        },
        {
          name: '访问时长',
          value: 'inspectDuration',
          formatVal: (row, column, cellValue) => {
            return formatSeconds(cellValue);
          }
        },
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryADGuideStatisticList',
        isNum:true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page={pageNum: '', pageSize: ''};
  },
  methods: {
    clear(){
      this.reload();
    },
    sendData(val){
      this.totalCount=val.totalCount;
    }
  }
}
</script>

<style lang="scss">
.franchiserVisit {
  min-width: 1070px;

}
</style>
