<template>
  <div>
    <el-button type="text" @click="confirm" v-if="0">点击打开 Message Box</el-button>
    <el-button type="text" @click="submit" v-if="0">点击打开 Message Box</el-button>
  </div>
</template>

<script>
export default {
  confirm(config, success, cancel) {
    this.$confirm(config.message || '', '', Object.assign({
      customClass: 'custom-box-one',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false,
    }, config)).then(() => {
      success && success();
    }).catch(() => {
      cancel && cancel();
    });
  },
  //submit(message, inputValue, success, cancel) {
  submit(obj = {}, success, cancel) {
    this.$prompt(obj.message, '', Object.assign({
      customClass: 'custom-box-two',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^\s*(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6}\s*$/,
      inputErrorMessage: '请输入6位数字+字母的组合',
      inputValue: ""
    }, obj)).then(({value}) => {
      success && success(value);
    }).catch(() => {
      cancel && cancel();
    });
    this.$nextTick(() => {
      document.querySelector('.custom-box-two .el-input__inner').setAttribute("spellcheck", "false");
    })
  },
  info(config = {}, success) {
    const h = this.$createElement;
    this.$msgbox({
      title: '',
      message: h('div', {class: 'el-message-custom'}, [h('div', {class: 'box'}, [
        h('div', {class: 'icon-out ' + config.type}, [h('div', {class: 'icon-' + config.type})]),
        h('div', {class: 'con'}, [h('div', null, config.message)])
      ]),]),
      showCancelButton: false,
      showConfirmButton: false,
      showClose: false,
      callback() {
        success && success();
      },
    });
    setTimeout(() => {
      document.querySelector('.el-message-box__wrapper').click();
    }, config.timeout || (config.type === 'success' ? 1000 : 2000))
  },
  warn(config = {}, success) {
    const h = this.$createElement;
    this.$msgbox({
      title: '',
      message: h('div', {class: 'el-message-warn'}, [h('div', {class: 'box'}, [
        h('div', {class: 'icon-out ' + config.type}, [h('div', {class: 'icon-' + config.type})]),
        h('div', {class: 'con'}, [h('div', null, config.message)])
      ]),]),
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: '确定',
      showClose: false,
      callback() {
        success && success();
      },
    });
    setTimeout(() => {
     // document.querySelector('.el-message-box__wrapper').click();
    }, config.timeout || (config.type === 'success' ? 1000 : 2000))
  },
}
</script>

<style lang="scss">
.el-message-box {
  position: relative;
  padding-bottom: 0;
  width: 400px;
  border-radius: 8px;

  .el-message-box__header {
    padding: 0;
  }

  .el-message-box__content {
    padding: 0;
    font-size: 20px;
    color: rgba(56, 56, 56, 1);;
  }

  .el-message-box__btns {
    padding: 0;

    .el-button {
      border: none;
    }

    .el-button--small {
      font-size: 15px;
    }

    .el-button--default {
      width: 140px;
      height: 50px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(209, 209, 209, 1);
      box-sizing: border-box;
      color: rgba(166, 166, 166, 1);
      font-size: 20px;
      padding: 0;
      line-height: 24px;
    }

    .el-button--primary {
      background: $btnA;
      border-radius: 6px;
      color: #FFFFFF;
    }
  }
}

.custom-box-one.el-message-box {
  width:390px;
  padding: 46px 40px 32px;
  border-radius: 8px;
  .el-message-box__message{
    p{
      line-height: 33px;
      color: rgba(56, 56, 56, 1);
    }
  }
  .el-message-box__btns {
    padding: 0;
    text-align: center;
    margin-top: 56px;
    .el-message-box__btns button:nth-child(2) {
      margin-left: 12px;
    }
  }
}

.custom-box-two.el-message-box {
  width: 300px;
  min-height: 186px;

  .el-input__inner {
    width: 210px;
    padding: 0 14px;
    border: 1px solid #ECEEF7;
    border-radius: 4px;
    font-size: 16px;
    color: #323C47;
  }

  .el-message-box__input {
    .el-textarea {
      .el-textarea__inner {
        resize: none;
        max-height: 150px;
        min-height: 50px;
        outline: none;

        &:focus {
          border: 1px solid #DCDFE6;
        }
      }
    }
  }
}
</style>

<style lang="scss">

.el-message-custom,.el-message-warn {
  width: 100%;
  padding: 47px 0 40px 0;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-out {
      background-size: cover;
    }

    .con {
      font-size: 20px;
      margin-top: 23px;
      height:29px;
      line-height: 29px;
      padding: 0 30px;
    }

    .success {
      width: 46px;
      height: 46px;
      background: url('../assets/success.png') center center no-repeat;
      background-size: cover;
    }

    .error {
      width: 60px;
      height: 60px;
      background: url('../assets/warning.png') center center no-repeat;
      background-size: cover;
    }

    .warning {
      width: 60px;
      height: 60px;
      background: url('../assets/warning.png') center center no-repeat;
      background-size: cover;
    }


    .icon-error {

    }
    .icon-warning {

    }
  }
}
.el-message-warn{
  .box{
    .success {
      width: 46px;
      height: 46px;
      background: url('../assets/warning.png') center center no-repeat;
      background-size: cover;
    }
  }
}
.el-message-box__btns{
  margin-bottom: 30px;
  text-align: center;
}
</style>
