<template>
  <div class="highLightsList">
    <search-form
        @clear="clear"
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :cellClick="cellClick"
                       :params="listParams">
      <el-table-column width="220px"
                       label="操作">
        <template v-slot="scope">
          <basic-button :type="'text'"
                        :title="'编辑'"
                        @click.native="editHighLights(scope.row)">
          </basic-button>
        </template>
      </el-table-column>
    </basic-table-pager>
    <comfort-dia v-if="editDetDia"
                 :title="editDetTitle"
                 ref="dia"
                 :dialogVisible="editDetDia"
                 :isTextArea="false"
                 :isCancel="editDetTitle==='亮点详情'?false:true"
                 :submitTitle="editDetTitle==='亮点详情'?'关闭':'确认'"
                 :cancel="editCancel"
                 :submit="editDetTitle==='亮点详情'?detSubmit:editSubmit"
                 :cancelTitle="'取消'">
      <div slot="header"
           class="highLightsEdit">
        <div class="basicInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">基础信息</span>
          </div>
          <ul class="basicInfoUl">
            <li class="basicInfoLi">
              <span class="label">所属地块：</span>
              <span class="val">{{brightDotInfo.regionName}}</span>
            </li>
            <li class="basicInfoLi">
              <span class="label">亮点位置：</span>
              <span class="val">{{brightDotInfo.position}}</span>
            </li>
          </ul>
        </div>
        <div class="detDiv" v-if="editDetTitle==='亮点详情'">
          <ul class="detUl">
            <li class="detLi">
              <span class="label">亮点名称（中文）：</span>
              <span class="val">{{brightDotInfo.brightenedDotName}}</span>
            </li>
            <li class="detLi">
              <span class="label">亮点名称（英文）：</span>
              <span class="val">{{brightDotInfo.brightenedDotNameEng}}</span>
            </li>
            <li class="detLi">
              <span class="label">开业时间（中文）：</span>
              <span class="val">{{brightDotInfo.openTime}}</span>
            </li>
            <li class="detLi">
              <span class="label">开业时间（英文）：</span>
              <span class="val">{{brightDotInfo.openTimeEng}}</span>
            </li>
          </ul>
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">业态信息</span>
          </div>
          <ul class="attrUl">
            <li class="attrLi" v-for="typeItem in brightDotInfo.businessTypes" :key="typeItem.id">
              <ul class="typeUl">
                <li class="typeLi">
                  <span class="label">所属业态：</span>
                  <span class="val">{{typeItem.typeCode}}</span>
                </li>
                <li class="typeLi">
                  <span class="label">楼层信息：</span>
                  <span class="val">{{typeItem.floors}}</span>
                </li>
                <li class="typeLi">
                  <span class="label">描述信息（中文）：</span>
                  <span class="val">{{typeItem.description}}</span>
                </li>
                <li class="typeLi">
                  <span class="label">描述信息（英文）：</span>
                  <span class="val">{{typeItem.descriptionEng}}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <el-form v-if="editDetTitle==='编辑亮点'"
                 class="editForm"
                 id="editForm"
                 :model="editForm"
                 :rules="editFormRules"
                 autocomplete="on"
                 ref="editForm">
          <div class="middleInfo">
            <el-form-item label="亮点名称（中文）："
                          prop="height">
              <op-input v-model="editForm.brightenedDotName"
                        placeholder="请输入">
              </op-input>
            </el-form-item>
            <el-form-item label="亮点名称（英文）："
                          prop="height">
              <op-input v-model="editForm.brightenedDotNameEng"
                        placeholder="请输入（m）">
              </op-input>
            </el-form-item>
            <el-form-item label="开业时间（中文）："
                          prop="busyType">
              <select-input v-model="editForm.openTime"
                            :option="openTimes"
                            placeholder="请选择">
              </select-input>
            </el-form-item>
            <el-form-item label="开业时间（英文）："
                          prop="busyType">
              <select-input v-model="editForm.openTimeEng"
                            :option="openTimesEng"
                            placeholder="请选择">
              </select-input>
            </el-form-item>
          </div>
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">业态信息</span>
          </div>
          <div class="businessTypes" v-for="businessType in editForm.businessTypes" :key="businessType.id">
            <el-form-item label="所属业态"
                          prop="busyType">
              <select-input v-model="businessType.typeName"
                            :option="typeNames"
                            placeholder="请选择">
              </select-input>
            </el-form-item>
            <el-form-item label="楼层信息"
                          prop="height">
              <op-input v-model="businessType.floors"
                        placeholder="请输入">
              </op-input>
            </el-form-item>
            <el-form-item label="描述信息（中文）"
                          class="textareaItem"
                          prop="introduction">
              <op-input :type="'textarea'"
                        v-model="businessType.description"
                        placeholder="请输入">
              </op-input>
            </el-form-item>
            <el-form-item label="描述信息（英文）"
                          class="textareaItem"
                          prop="introduction">
              <op-input :type="'textarea'"
                        v-model="businessType.descriptionEng"
                        placeholder="请输入">
              </op-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </comfort-dia>
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import ComfortDia from '../../components/basic/comfortDialog';
import OpInput from '../../components/basic/opInput';
import RadioBox from '../../components/basic/radioBox';
import BasicButton from '../../components/basic/basicButton';
import SelectInput from '../../components/basic/selectInput';
import {formatSeconds} from "@/utils/tools";

export default {
  components: {SearchForm, BasicTablePager, ComfortDia, OpInput, RadioBox, BasicButton, SelectInput},
  inject: ['reload'],
  data() {
    return {
      saleOptions: [],
      openTimes: [
        {
          label: "2023",
          value: "2023"
        },
        {
          label: "2024",
          value: "2024"
        },
        {
          label: "2025",
          value: "2025"
        },
        {
          label: "2026",
          value: "2026"
        },
        {
          label: "2027",
          value: "2027"
        },
        {
          label: "2028",
          value: "2028"
        },
        {
          label: "2029",
          value: "2029"
        },
        {
          label: "2030",
          value: "2030"
        }
      ],
      openTimesEng: [
        {
          label: "2023",
          value: "2023"
        },
        {
          label: "2024",
          value: "2024"
        },
        {
          label: "2025",
          value: "2025"
        },
        {
          label: "2026",
          value: "2026"
        },
        {
          label: "2027",
          value: "2027"
        },
        {
          label: "2028",
          value: "2028"
        },
        {
          label: "2029",
          value: "2029"
        },
        {
          label: "2030",
          value: "2030"
        }
      ],
      typeNames:[
        {
          label: "A1",
          value: "A1"
        },
        {
          label: "A2",
          value: "A2"
        },
        {
          label: "A3",
          value: "A3"
        }
      ],
      searchData: {
        //所属地块
        regionId: null,
        //亮点名称
        name: null,
      },
      searchForm: [
        //所属地块
        {
          type: 'Select',
          label: '所属地块',
          labelWidth: '70px',
          prop: 'regionId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          url: "/private/o-wms/v1/getAreaComboBox",
          id: 1,
          change: url => {
            if (url) {
              if (this.$store.state.userInfo.role === 3) {
                let sales = [];
                this.searchForm[0].options = [{label: "全部", value: null}];
                this.$post(url, {data: {}}).then((res) => {
                  if (res.data.rtnCode === '10000' && res.data.body.regionList.length) {
                    sales = res.data.body.regionList;
                    sales.forEach(item2 => {
                      this.searchForm[0].options.push({
                        label: item2.name,
                        value: item2.id
                      })
                    });
                  }
                }).catch((err) => {

                });
              }
            }
          }
        },
        {
          type: 'SearchAccurate',
          label: '',
          placeholder: '请输入亮点名称',
          prop: 'customerName',
          id: 2,
          class: 'searchItem'
        },
        {
          type: 'Button',
          class: 'reset',
          title: '重置',
          id: 3,
        }
      ],
      tableKey: [
        {
          name: '亮点名称',
          value: 'brightenedDotName',
        },
        {
          name: '所属地块',
          value: 'regionName',
        },
        {
          name: '亮点位置',
          value: 'position',
        },
        {
          name: '包含业态',
          value: 'businessTypes',
          formatVal: (row, column, cellValue) => {
            let typeNameArr = [];
            cellValue.forEach(item => {
              typeNameArr.push(item.typeName);
            });
            return typeNameArr.join(',');
          }
        }
      ],
      tablep: {
        url: '/private/o-wms/v1/queryBrightenedDotInfoList',
        isNum: true,
      },
      listParams: {
        data: {},
      },
      editDetTitle: '',
      editDetDia: false,
      editForm: {
        brightenedDotName:'',
        brightenedDotNameEng:'',
        openTime:'',
        openTimeEng:'',
        businessTypes:[],
        dataVersion:'',
        id: '',
      },
      editFormRules: {},
      brightDotInfo:{
        regionName:'',
        position:'',
        brightenedDotName:'',
        brightenedDotNameEng:'',
        openTime:'',
        openTimeEng:'',
        businessTypes:[],
      }
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page = {pageNum: '', pageSize: ''};
  },
  methods: {
    addClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1) {
        return 'confirm'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '亮点名称') {
        this.editDetDia = true;
        this.editDetTitle = '亮点详情';
        this.getBrightenedDotInfo(row.id,2);
      }
    },
    clear() {
      this.reload();
    },
    getBrightenedDotInfo(id,type){
      this.$post('/private/o-wms/v1/getBrightenedDotInfo',{data:{brightenedDotId:id}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          let brightDotObj=res.data.body.brightDotInfo;
          this.brightDotInfo.regionName=brightDotObj.regionName;
          this.brightDotInfo.position=brightDotObj.position;
          if(type===1){
            this.editForm.brightenedDotName=brightDotObj.brightenedDotName;
            this.editForm.brightenedDotNameEng=brightDotObj.brightenedDotNameEng;
            this.editForm.openTime=brightDotObj.openTime;
            this.editForm.openTimeEng=brightDotObj.openTimeEng;
            this.editForm.businessTypes=brightDotObj.businessTypes;
            this.editForm.dataVersion=brightDotObj.dataVersion;
            this.editForm.id=brightDotObj.id;
          }else{
            this.brightDotInfo.brightenedDotName=brightDotObj.brightenedDotName;
            this.brightDotInfo.brightenedDotNameEng=brightDotObj.brightenedDotNameEng;
            this.brightDotInfo.openTime=brightDotObj.openTime;
            this.brightDotInfo.openTimeEng=brightDotObj.openTimeEng;
            this.brightDotInfo.businessTypes=brightDotObj.businessTypes;
          }
        }
      }).catch(() => {
      });
    },
    editCancel() {
      this.editDetDia = false;
    },
    editSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$post('/private/o-wms/v1/setBrightenedDotInfo', {data: this.editForm}).then((res) => {
            if (res.data.rtnCode === '10000') {
              this.$_info({
                    type: "success",
                    message: "编辑成功",
                  },
                  () => {
                    this.editDetDia = false;
                    this.reload();
                  });
            }
          }).catch(() => {
          });
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    },
    detSubmit() {
      this.editDetDia = false;
    },
    editHighLights(row) {
      this.editDetDia = true;
      this.editDetTitle = '编辑亮点';
      this.getBrightenedDotInfo(row.id,1);
    },
  }
}
</script>

<style lang="scss">
.highLightsList {
  min-width: 1070px;
  font-size: 16px;

  .tabPage .confirm .cell {
    color: rgba(87, 139, 189, 1);
    cursor: pointer;
  }

  .highLightsEdit {
    height: 626px;
    overflow-y: auto;

    .basicInfo, .detDiv,#editForm {
      .infoLabel {
        height: 29px;
        margin-bottom: 8px;
        .infoIcon {
          display: inline-block;
          width: 4px;
          height: 17px;
          line-height: 29px;
          border-radius: 1px;
          background: rgba(17, 53, 86, 1);
        }
        .infoTitle {
          display: inline-block;
          margin-left: 8px;
          font-size: 20px;
          height: 29px;
          line-height: 29px;
          color: rgba(56, 56, 56, 1);
        }
      }
    }

    .basicInfoUl {
      padding: 24px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);

      .basicInfoLi {
        display: inline-block;
        margin-right: 99px;
        font-size: 18px;
        line-height: 27px;
        color: rgba(56, 56, 56, 1);

        &:last-child {
          margin-right: 0;
        }

        .label {
          display: inline-block;
          width: 96px;
          color: rgba(128, 128, 128, 1);
        }

        .val {
          color: rgba(56, 56, 56, 1);
        }
      }
    }

    .detDiv {
      margin-top: 24px;
      .detUl {
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        margin-bottom: 24px;
        .detLi {
          font-size: 18px;
          padding: 16px 0;
          margin:0 24px;
          border-bottom: 1px solid rgba(229, 229, 229, 1);
          &:last-child {
            margin-bottom: 0;
            border-bottom:none;
          }
          .label {
            display: inline-block;
            width: 92px;
            color: rgba(128, 128, 128, 1);
            vertical-align: top;
          }
          .val {
            display: inline-block;
            width: 368px;
            vertical-align: bottom;
          }
        }
      }
      .attrUl{
        .attrLi{
          .typeUl {
            background: rgba(255, 255, 255, 1);
            border-radius: 8px;
            margin-bottom: 14px;
            .typeLi {
              font-size: 18px;
              padding: 16px 0;
              margin:0 24px;
              border-bottom: 1px solid rgba(229, 229, 229, 1);
              .label {
                display: inline-block;
                width: 92px;
                color: rgba(128, 128, 128, 1);
                vertical-align: top;
              }
              .val {
                display: inline-block;
                width: 368px;
                vertical-align: bottom;
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
      /*.editForm {
        .el-form-item {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: rgba(152, 152, 152, 1) !important;

          &.textareaItem {
            height: 102px;
          }

          .el-form-item__label {
            width: 116px !important;
            height: 44px;
            line-height: 44px;
            text-align: left;
            padding: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          .el-form-item__content {
            height: 40px;

            .el-form-item__error {
              left: 116px;
            }

            #select, #opInput {
              display: inline-block;
              height: 40px;

              .el-input {
                width: 280px;
                height: 40px;

                .el-input__inner {
                  padding: 0 10px;
                  width: 280px;
                  height: 40px;

                  &::placeholder {
                    font-size: 16px;
                    color: rgba(214, 214, 214, 1);;
                  }
                }
              }

              .el-textarea {
                width: 280px;
                height: 102px;

                .el-textarea__inner {
                  resize: none;
                  width: 280px;
                  height: 102px !important;
                  font-size: 16px !important;
                  color: rgba(56, 56, 56, 1) !important;

                  &::placeholder {
                    font-size: 16px;
                    color: rgba(214, 214, 214, 1);;
                  }
                }
              }
            }
          }

          .orgPrompt {
            cursor: pointer;
            display: inline-block;
            margin-left: 12px;
            width: 20.9px;
            height: 20.9px;
            background: url("../../assets/prompt@2x.png") no-repeat center;
            background-size: cover;
          }

          .promptDia {
            width: 227px;
            padding: 16px;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0 2px 20px 0 rgba(46, 46, 46, 0.3);
            position: absolute;
            top: 18px;
            left: 430px;
            z-index: 100;

            .promptLi {
              .label {
                font-size: 14px;
                color: rgba(56, 56, 56, 1);
              }

              .val {
                font-size: 12px;
                color: rgba(56, 56, 56, 1);
                line-height: 20px;
              }
            }
          }

        }
      }*/
    }
    #editForm{
      margin-top:24px;
      .el-form-item {
          line-height: 40px;
          font-size: 18px;
          color: rgba(152, 152, 152, 1) !important;
          &.textareaItem{
            height:102px;
            .el-form-item__content .el-form-item__error {
              top: 100px;
            }
          }
          .el-form-item__label {
            width: 92px !important;
            line-height: 20px;
            text-align: left;
            padding: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            vertical-align: top;
          }
          .el-form-item__content {
            height: 40px;
            .el-form-item__error {
              left: 116px;
            }
            #select, #opInput {
              display: inline-block;
              height:40px;

              .el-input {
                width: 400px;
                height:40px;

                .el-input__inner {
                  padding: 0 10px;
                  width: 400px;
                  height:40px;
                  &::placeholder {
                    font-size: 16px;
                    color: rgba(214, 214, 214, 1);;
                  }
                }
              }
              .el-textarea {
                width: 400px;
                height: 102px;
                .el-textarea__inner {
                  resize: none;
                  width: 400px;
                  height: 102px!important;
                  font-size: 16px!important;
                  color: rgba(56, 56, 56, 1) !important;
                  &::placeholder {
                    font-size: 16px;
                    color: rgba(214, 214, 214, 1);;
                  }
                }
              }
            }
          }
        }
      .middleInfo{
        padding: 24px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        margin-bottom: 24px;
      }
      .businessTypes{
        padding: 24px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        margin-bottom: 24px;
      }
    }
  }
}

</style>
