<template>
  <div class="rentSaleStatusList">
    <search-form
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <!--表格-->
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :cellClick="cellClick"
                       :params="listParams">
      <el-table-column width="150px"
                       label="操作">
        <template v-slot="scope">
          <basic-button :type="'text'"
                        :title="'上传'"
                        @click.native="uploadImg(scope.row)">
          </basic-button>
        </template>
      </el-table-column>
    </basic-table-pager>
    <comfort-dia v-if="uploadDia"
                 :title="'上传图片'"
                 ref="dia"
                 :dialogVisible="uploadDia"
                 :isTextArea="false"
                 :cancelTitle="'取消'"
                 :submitTitle="'确认'"
                 :cancel="uploadCancel"
                 :submit="uploadSubmit"
                 :width="uploadWidth">
      <div slot="header"
         class="title">
        <div class="headInfo">
          <div>楼层id：{{}}</div>
          <div>所属区域：{{}}</div>
          <div>所属楼：{{}}</div>
          <div>所属层：{{}}</div>
        </div>
      </div>
    </comfort-dia>
    <viewer v-show="imgShow" ref="viewer">
      <img :src="imgUrl"/>
    </viewer>
<!--    <viewer class="shopImg">
      <img :src="require('../../assets/imgDied.png')"
           alt=""
           class="img">
    </viewer>-->
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import axios from 'axios';
import ComfortDia from '../../components/basic/comfortDialog';
import OpInput from '../../components/basic/opInput';
import RadioBox from '../../components/basic/radioBox';
import BasicButton from '../../components/basic/basicButton';
import tools from "@/utils/tools";
export default {
  components: {SearchForm, BasicTablePager,ComfortDia,OpInput,RadioBox,BasicButton},
  data() {
    return {
      disabled:false,
      //区域
      regionList:[],
      //建筑
      buildingMap:{},
      //楼层
      floorMap:{},


      uploadDia:false,
      uploadWidth:'500px',
      uploadForm:{

      },
      imgUrl:'',
      imgShow:false,

      searchData: {
        //区域
        regionId:null,
        //建筑
        buildingId:null,
        //楼层
        floorId:null,
        refresh:false,
      },
      searchForm: [
        //选择区域
        {
          type: 'Select',
          label: '所属区域',
          labelWidth: '70px',
          prop: 'regionId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          id: 1,
          change: () => {
            this.regionList.forEach(item=>{
              this.searchForm[0].options.push({label:item.name,value:item.id});
            })
          }
        },
        //选择建筑
        {
          type: 'Select',
          label: '所属建筑',
          labelWidth: '70px',
          prop: 'buildingId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          id: 2,
          change: () => {
            let currentRegionId=this.searchData.regionId;
            console.log(currentRegionId);
            this.buildingMap[currentRegionId].forEach(item=>{
              this.searchForm[1].options.push({label:item.name,value:item.id})
            })
          }
        },
        //选择楼层
        {
          type: 'Select',
          label: '所属楼层',
          labelWidth: '70px',
          prop: 'floorId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          id: 3,
          change: () => {
            let currentBuildingId=this.searchData.buildingId;
            this.floorMap[currentBuildingId].forEach(item=>{
              this.searchForm[2].options.push({label:item.name,value:item.id})
            })
          }
        },
      ],
      tableKey: [
        {
          name: '所属区域',
          value: 'regionName',
        },
        {
          name: '所属楼',
          value: 'buildingName',
        },
        {
          name: '所属层',
          value: 'floorName',
        },
        {
          name: '状态图预览',
          value: 'url',
        },
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryRentSaleStatusList',
        isNum:true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
    }
  },
  created() {
    //获取区域建筑楼层
    this.$post('/private/o-wms/v1/getAreaComboBox', {data: {}}).then((res) => {
      if(res.data.rtnCode==='10000'){
        console.log(res.data.body);
        let blockData=res.data.body;
        //区域
        this.regionList=blockData.regionList;
        //建筑
        this.buildingMap=blockData.buildingMap;
        //楼层
        this.floorMap=blockData.floorMap;
      }else{
        let errMessage = res.data.messages[0].message;
        this.$_info({
              type: "error",
              message: errMessage,
        });
      }
    }).catch(()=>{});
    this.listParams.data = this.searchData;
    this.listParams.data.page={pageNum: '', pageSize: ''};
  },
  methods: {
    error() {
      event.target.src = require('../../assets/imgDied.png');
    },
    addClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 4) {
        return 'confirm'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '状态图预览') {
        let aa=require('../../assets/img.jpg');
        this.imgUrl=aa;
        if(this.imgUrl){
          console.log(this.imgUrl);
          this.$refs["viewer"].$el.childNodes[0].click();
          //this.$refs.viewer.$viewer.show()
        }
      }
    },
    uploadCancel(){
      this.uploadDia=false;
    },
    uploadSubmit(){
        this.$post('/private/o-wms/v1/setBunkInfo', {data: this.uploadForm}).then((res) => {
          if (res.data.rtnCode === '10000') {
            this.$_info({
                  type: "success",
                  message: "编辑成功",
                },
                () => {
                  this.editDetDia = false;
                  this.searchData.refresh = !this.searchData.refresh;
                }
            );

          } else{
            let errMessage = res.data.messages[0].message;
            this.$_info({
                  type: "error",
                  message: errMessage,
                }
            );
          }
        }).catch(() => {
        });
    },
    uploadImg(val){
      this.uploadDia=true;
    },
  }
}
</script>

<style lang="scss">
.rentSaleStatusList {
  min-width: 1070px;
  padding: 40px 40px 24px!important;
  margin: 0 40px 0!important;
  font-size: 14px;
  $black: #323C47;
  $gray: #A7ADB3;
  $placeHolder: #D8DAE0;
  color: $black;
  #formSearch{
    .el-form-item {
      margin-bottom: 24px;
    }
    .el-form-item:last-child{
      margin-bottom: 24px;
    }
  }
  .btnArea{
    text-align: left;
    margin-bottom: 10px;
  }
  .headInfo{
    margin:20px;
    div{
      margin: 10px;
    }
    .expireTime{
      display: inline-block;
      margin-right: 50px;
    }
    .continue{
      cursor:pointer;
    }
  }
  #addForm {
    .el-form-item {
      margin-bottom: 24px;
      .el-form-item__label {
        width: 92px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        color: $gray;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
      .el-form-item__content {
        height: 40px;
        #opInput {
          display: inline-block;
          width: 200px;
        }
      }
      /*表单验证错误提示*/
      .el-form-item__error {
        color: #F56C6C;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 92px;
      }
    }
  }
  .tabPage .confirm .cell{
    color: #409EFF;
    cursor: pointer;
  }
}
</style>
