<template>
  <div id="checkBoxAry">
    <el-checkbox-group v-model="tempApp">
      <el-checkbox v-for="city in cities"
                   :label="city.id"
                   :key="city.id"
                   :disabled="disabled">{{ city.name }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "checkBoxAry",
  props: {
    app: {
      type: Array
    },
    cities: {
      type: Array
    },
    tempAppInit: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    tempApp: {
      get() {
        return this.tempAppInit;
      },
      set(val) {
        this.$emit('update:tempAppInit', val);
        this.$emit('change', val);
      }
    }
  },
}
</script>

<style lang="scss">
#checkBoxAry {
  margin-bottom: 15px;
  .el-checkbox__label {
    display: inline-block;
    padding-left: 8px;
    line-height: 19px;
    font-size: 18px;
    color: rgba(56, 56, 56, 1);
  }
  .el-checkbox-group {
    line-height: 30px;
  }

  .el-checkbox {
    width: 80px;
    margin-right: 61px;
    &:last-child{
      margin-right: 0;
    }
    .is-disabled .el-checkbox__inner {
      opacity: 0.5;
    }
  }
}
</style>
