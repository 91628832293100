<template>
  <div class="authorization">
    <search-form
        @clear="clear"
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <!--表格-->
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :cellClick="cellClick"
                       :params="listParams">
      <el-table-column width="180px"
                       label="操作">
        <template v-slot="scope">
          <basic-button :type="'text'"
                        :title="'编辑'"
                        @click.native="editMerchant(scope.row)">
          </basic-button>
          <basic-button :type="'text'"
                        :title="scope.row.accountState===1?'启用':'停用'"
                        @click.native="scope.row.accountState===1?openAccount(scope.row):stopAccount(scope.row)">
          </basic-button>
        </template>
      </el-table-column>
    </basic-table-pager>
    <comfort-dia v-if="addDia"
                 :title="addEditTitle"
                 ref="dia"
                 :dialogVisible="addDia"
                 :isTextArea="false"
                 :cancelTitle="'取消'"
                 :submitTitle="'确认'"
                 :cancel="addCancel"
                 :submit="addSubmit">
      <div slot="header"
           class="customerEdit">
        <div class="basicInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">基础信息</span>
          </div>
          <ul class="basicInfoUl">
            <li class="basicInfoLi">
              <span class="label">招商人员：</span>
              <span class="val">{{ customerInfo.salespersonName }}</span>
            </li>
            <li class="basicInfoLi">
              <span class="label">开户时间：</span>
              <span class="val">{{ customerInfo.accountOpeningTime }}</span>
            </li>
            <li class="basicInfoLi">
              <span class="label">登录次数：</span>
              <span class="val">{{ customerInfo.loginCount }}</span>
              <span v-if="addEditTitle==='编辑客户'&&customerInfo.accountState!==1"
                    class="continue"
                    @click="continueTime(1)">
                +5次
              </span>
            </li>
          </ul>
        </div>
        <div class="attrInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">属性信息</span>
          </div>
          <el-form class="editForm"
                   id="addForm"
                   :model="addForm"
                   :rules="addFormRules"
                   autocomplete="on"
                   ref="addForm">
            <el-form-item label="机构名称"
                          prop="organizationName">
              <op-input v-model="addForm.organizationName"
                        @input="inputs"
                        @blur="orgBlur"
                        placeholder="请输入机构名称"></op-input>
              <i class="orgPrompt" @mouseenter="promptIsShow=true" @mouseleave="promptIsShow=false"></i>
              <ul class="promptDia" v-show="promptIsShow">
                <li class="promptLi">
                  <p class="label">支持格式：</p>
                  <p class="val">中文、数字、字母（大小写）</p>
                  <p class="val">标点符号中文；：、！，。- —— / “”《》（）空格</p>
                  <p class="val">标点符号英文：;'!,._-/''<>()空格</p>
                </li>
                <li class="promptLi">
                  <p class="label">位数限制：</p>
                  <p class="val">支持中英文字符1-50位</p>
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="客户名称"
                          prop="customerName">
              <op-input v-model="addForm.customerName"
                        @input="customerNameInput"
                        @blur="cusBlur"
                        placeholder="请输入客户名称"></op-input>
              <i class="orgPrompt" @mouseenter="cusPromptIsShow=true" @mouseleave="cusPromptIsShow=false"></i>
              <ul class="promptDia" v-show="cusPromptIsShow">
                <li class="promptLi">
                  <p class="label">支持格式：</p>
                  <p class="val">中文、数字、字母（大小写）</p>
                  <p class="val">标点符号中文；：、！，。- —— / “”《》（）空格</p>
                  <p class="val">标点符号英文：;'!,._-/''<>()空格</p>
                </li>
                <li class="promptLi">
                  <p class="label">位数限制：</p>
                  <p class="val">支持中英文字符1-50位</p>
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="性别"
                          class="gender"
                          prop="gender">
              <radio-box :radioAry="genders"
                         @input="genderChange"
                         v-model="addForm.gender"></radio-box>
            </el-form-item>
            <el-form-item label="登录手机号"
                          prop="accountNo">
              <op-input v-model="addForm.accountNo"
                        @input="accountNoInput"
                        placeholder="请输入登录手机号"></op-input>
              <i class="orgPrompt" @mouseenter="accPromptIsShow=true" @mouseleave="accPromptIsShow=false"></i>
              <ul class="promptDia" v-show="accPromptIsShow">
                <li class="promptLi">
                  <p class="label">支持格式：</p>
                  <p class="val">纯数字</p>
                </li>
                <li class="promptLi">
                  <p class="label">位数限制：</p>
                  <p class="val">7-11位</p>
                </li>
              </ul>
            </el-form-item>
            <!--          <el-form-item label="设置密码"
                                    prop="password">
                        <op-input v-model="addForm.password"
                                  :password="true"
                                  placeholder="请输入密码"></op-input>
                      </el-form-item>
                      <el-form-item label="确认密码"
                                    prop="confirmPassword">
                        <op-input v-model="addForm.confirmPassword"
                                  :password="true"
                                  placeholder="请再次确认密码"></op-input>
                      </el-form-item>-->
          </el-form>
        </div>
      </div>
    </comfort-dia>
    <comfort-dia v-if="detDia"
                 :title="'查看客户'"
                 ref="dia"
                 :dialogVisible="detDia"
                 :isTextArea="false"
                 :isCancel="false"
                 :submitTitle="'关闭'"
                 :submit="detSubmit">
      <div slot="header"
           class="customerDet">
        <div class="basicInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">基础信息</span>
          </div>
          <ul class="basicInfoUl">
            <li class="basicInfoLi">
              <span class="label">招商人员：</span>
              <span class="val">{{ customerInfo.salespersonName}}</span>
            </li>
            <li class="basicInfoLi">
              <span class="label">开户时间：</span>
              <span class="val">{{ customerInfo.accountOpeningTime}}</span>
            </li>
            <li class="basicInfoLi">
              <span class="label">登录次数：</span>
              <span class="val">{{ customerInfo.loginCount}}</span>
            </li>
          </ul>
        </div>
        <div class="attrInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">属性信息</span>
          </div>
          <ul class="detUl">
            <li class="detLi">
              <span class="label">机构名称：</span>
              <span class="val">{{ detForm.organizationName }}</span>
            </li>
            <li class="detLi">
              <span class="label">客户名称：</span>
              <span class="val">{{ detForm.customerName }}</span>
            </li>
            <li class="detLi">
              <span class="label">性别：</span>
              <span class="val">{{ detForm.gender === '1' ? '男' : '女' }}</span>
            </li>
            <li class="detLi">
              <span class="label">登录手机号：</span>
              <span class="val">{{ detForm.accountNo }}</span>
            </li>
            <li class="detLi">
              <span class="label">密码信息：</span>
              <div class="val">
                <span class="pswVal">{{ flag ? detForm.password : (detForm.password).replace(/[^\u4e00-\u9fa5]/g, '*') }}</span>
                <span class="findPsw" @click="findPsw">{{ flag ? '隐藏密码' : '显示密码' }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </comfort-dia>
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import ComfortDia from '../../components/basic/comfortDialog';
import OpInput from '../../components/basic/opInput';
import RadioBox from '../../components/basic/radioBox';
import BasicButton from '../../components/basic/basicButton';
import rules from '../../utils/rules';
import base from "@/utils/base";

export default {
  components: {SearchForm, BasicTablePager, ComfortDia, OpInput, RadioBox, BasicButton},
  inject: ['reload'],
  data() {
    return {
      promptIsShow: false,
      cusPromptIsShow: false,
      accPromptIsShow: false,
      saleOptions: [],
      flag: 0,
      addEditTitle: '',
      addDia: false,
      detDia: false,
      detForm: {
        organizationName: '',
        customerName: '',
        gender: '',
        accountNo: '',
        password: '',
        //confirmPassword:'',
        customerId: '',
        loginCount: '',
      },
      addForm: {
        organizationName: '',
        customerName: '',
        gender: '',
        accountNo: '',
        //password:'',
        //confirmPassword:'',
        loginCount: '',
        customerId: '',
        authModifyTime: '',
        basicModifyTime: ''
      },
      addFormRules: {
        organizationName: [{
          validator: rules.validateOrganizationName,
          trigger: 'blur',
          required: true
        }],
        customerName: [{
          validator: rules.validateCustomerName,
          trigger: 'blur',
          required: true
        }],
        gender: [{
          validator: rules.validateGender,
          trigger: 'change',
          required: true
        }],
        accountNo: [{
          validator: rules.validateAccountNo,
          trigger: 'blur',
          required: true
        }],
        password: [{
          validator: rules.validatePassword,
          trigger: 'blur',
          required: true
        }],
        confirmPassword: [{
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error('确认密码不能为空，请输入'));
            } else {
              if (value === this.addForm.password) {
                callback();
              } else {
                callback(new Error('两次密码输入不一致'));
              }
            }
          },
          trigger: 'blur'
        }]
      },
      genders: [{label: '男', value: '1'}, {label: '女', value: '2'}],
      searchData: {
        //账号状态
        accountStatus: null,
        //员工id
        organizationId: null,
        //客户名称
        customerName: null,
      },
      searchForm: [
        //招商人员
        {
          type: 'Select',
          label: '招商人员',
          labelWidth: '70px',
          prop: 'organizationId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          url: "/private/o-wms/v1/getSalespersonList",
          id: 1,
          change: url => {
            if (url) {
              let sales = [];
              this.searchForm[0].options = [{label: "全部", value: null}];
              this.$post(url).then((res) => {
                if (res.data.rtnCode === '10000' && res.data.body.salespersonList.length) {
                  sales = res.data.body.salespersonList;
                  sales.forEach(item2 => {
                    this.searchForm[0].options.push({
                      label: item2.userName,
                      value: item2.uid
                    })
                  });
                }else{
                  /*let errMessage = res.data.messages[0].message;
                  this.$_info({
                        type: "error",
                        message: errMessage,
                      }
                  );*/
                }
              }).catch((err) => {
                this.$_info({
                  type: "error",
                  message: "获取查询条件内容失败，请重试"
                });
              });
            }
          }
        },
        //账号状态
        {
          type: 'Select',
          label: '账号状态',
          labelWidth: '70px',
          prop: 'accountStatus',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          id: 2,
          change: () => {
            if(this.$store.state.userInfo.role===3){
              this.searchForm[1].options = [{label: "全部", value: null}, {label: "已停用", value: "1"}, {
                label: "生效中",
                value: "0"
              }];
            }else{
              this.searchForm[0].options = [{label: "全部", value: null}, {label: "已停用", value: "1"}, {
                label: "生效中",
                value: "0"
              }];
            }
          }
        },
        {
          type: 'SearchAccurate',
          label: '',
          placeholder: '请输入客户名称',
          prop: 'customerName',
          id: 3,
          class: 'searchItem'
        },
        {
          type: 'Button',
          class: 'reset',
          title: '重置',
          id: 4,
        },
        {
          type: 'AddButton',
          class: 'addItem',
          id: 5,
          addFunc: () => {
            this.addMerchant();
          }
        }
      ],
      tableKey: [
        {
          name: '客户名称',
          value: 'customerName',
          minWidth: '100'
        },
        {
          name: '性别',
          value: 'gender',
          minWidth: '80',
          formatVal: (row, column, cellValue) => {
            return cellValue === 1 ? '男' : '女'
          }
        },
        {
          name: '机构名称',
          value: 'organizationName',
          minWidth: '100'
        },
        {
          name: '登录手机号',
          value: 'accountNo',
          minWidth: '150'
        },
        {
          name: '账号状态',
          value: 'accountState',
          minWidth: '100',
          formatVal: (row, column, cellValue) => {
            return cellValue === 1 ? '已停用' : '生效中'
          }
        },
        {
          name: '招商人员（OA）',
          minWidth: '200',
          value: 'salespersonName',
        },
        {
          name: '剩余次数',
          value: 'loginCount',
          minWidth: '100'
        },
        {
          name: '最后登录时间',
          value: 'lastLoginTime',
          minWidth: '200',
          formatVal: (row, column, cellValue) => {
            return new Date(cellValue).Format('yyyy-MM-dd hh:mm:ss')
          }
        },
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryCustomerList',
        isNum: true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
      customerInfo: {
        salespersonName: '',
        accountOpeningTime: '',
        loginCount: '',
        accountState: null,
      },
      rawData: {},
      diffData: {},
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page = {pageNum: '', pageSize: ''};
    //招商
    if (this.$store.state.userInfo.role === 1) {
      /*let userName=this.$store.state.userInfo.userName;
      let uid=this.$store.state.userInfo.uid;
      this.searchData.organizationId=uid;
      this.searchForm[0].options = [{label: userName, value: uid}];*/
      this.searchForm.shift();
      //let userName=this.$store.state.userInfo.userName;
      let uid=this.$store.state.userInfo.uid;
      this.searchData.organizationId=uid;
      //console.log(this.searchForm);
    } else {//admin
      this.searchForm[0].options = [{label: "全部", value: null}];
      this.searchForm.pop();
    }
  },
  methods: {
    addClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1) {
        return 'confirm'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '客户名称') {
        this.detDia = true;
        this.flag = 0;
        this.getCustomer(row.customerId, 2);
      }
    },
    checkInputChar(event, canUse) {
      let target = event.target;
      let valueCharArr = [...target.value].filter((char) => {
        let has = false;
        for (let _char of canUse) {
          if (typeof _char == "string") {
            has = _char === char;
          } else if (typeof _char == "object" && _char instanceof RegExp) {
            has = _char.test(char);
          }
          if (has) {
            break;
          }
        }
        return has;
      });
      target.value = valueCharArr.join("");
    },
    clear() {
      this.reload();
    },
    orgBlur(val) {
      val = val.replace(/^\s+|\s+$/g, "");
      this.addForm.organizationName = val;
    },
    cusBlur(val) {
      val = val.replace(/^\s+|\s+$/g, "");
      this.addForm.customerName = val;
    },
    inputs(val) {
      let lastCode = val[val.length - 1];
      if (/[^\u4e00-\u9fa5a-zA-Z0-9 \（\）\(\)\《\》\<\>\“\”\"\"\/\-\_\——\.\。\，\,\！\!\、\：\:\；\;]/.test(lastCode)) {
        val = val.substring(0, val.length - 1);
      }
      this.addForm.organizationName = val;
    },
    customerNameInput(val) {
      let lastCode = val[val.length - 1];
      if (/[^\u4e00-\u9fa5a-zA-Z0-9 \（\）\(\)\《\》\<\>\“\”\"\"\/\-\_\——\.\。\，\,\！\!\、\：\:\；\;]/.test(lastCode)) {
        val = val.substring(0, val.length - 1);
      }
      this.addForm.customerName = val;
    },
    accountNoInput(val) {
      let lastCode = val[val.length - 1];
      if (/[^\d]/.test(lastCode)) {
        val = val.substring(0, val.length - 1);
      }
      this.addForm.accountNo = val;
    },
    //停用账户
    stopAccount(val) {
      this.$_confirm({
        message: '停用后将限制目标客户的登录使用体验，请确定是否继续停用？',
        confirmButtonText: '确认停用',
      }, () => {
        this.$post('/private/o-wms/v1/disableCustomerAccount', {data: {customerId: val.customerId}}).then((res) => {
          if (res.data.rtnCode === '10000') {
            this.$_info({
                  type: "success",
                  message: "停用成功",
                },
                () => {
                  this.reload();
                }
            );

          } else {
            /*let errMessage = res.data.messages[0].message;
            this.$_info({
                  type: "error",
                  message: errMessage,
                }
            );*/
          }
        }).catch(() => {
        });
      })
    },
    //启用账户
    openAccount(val) {
      this.$_confirm({
        message: '启用后将会恢复目标客户的登录使用体验，请确定是否继续启用？',
        confirmButtonText: '确认启用',
      }, () => {
        this.$post('/private/o-wms/v1/enableCustomerAccount', {data: {customerId: val.customerId}}).then((res) => {
          if (res.data.rtnCode === '10000') {
            this.$_info({
                  type: "success",
                  message: "启用成功",
                },
                () => {
                  this.reload();
                }
            );

          } else {
            /*let errMessage = res.data.messages[0].message;
            this.$_info({
                  type: "error",
                  message: errMessage,
                }
            );*/
          }
        }).catch(() => {
        });
      });
    },
    clearAll() {
      this.reload();
    },
    findPsw() {
      if (this.flag) {
        this.flag = 0;
      } else {
        this.flag = 1;
      }
    },
    continueTime(type) {
      if (type === 1) {
        if (this.customerInfo.loginCount <= 45) {
          this.customerInfo.loginCount += 5;
          this.addForm.loginCount = this.customerInfo.loginCount;
        } else if (this.customerInfo.loginCount > 45 && this.customerInfo.loginCount < 50) {
          this.customerInfo.loginCount += 5;
          this.addForm.loginCount = this.customerInfo.loginCount;
          if (this.customerInfo.loginCount > 50) {
            this.customerInfo.loginCount = 50;
            this.addForm.loginCount = 50;
          }
        } else {
          this.$_info({
                type: "error",
                message: "登录次数已达到上限50次，不能再加了",
              }
          );
        }
      }
    },
    getDiffData() {
      this.diffData = {};
      Object.keys(this.rawData).forEach(item => {
        if (this.rawData[item] !== this.addForm[item]) {
          this.diffData[item] = this.addForm[item]
        }
      })
      //console.log(this.diffData);
    },
    //获取客户信息
    getCustomer(val, type) {
      this.$post('/private/o-wms/v1/getCustomer', {data: {customerId: val}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          let customerData = res.data.body.customer;
          this.customerInfo.salespersonName = customerData.salespersonName;
          this.customerInfo.accountOpeningTime = new Date(customerData.accountOpeningTime).Format('yyyy-MM-dd hh:mm:ss');
          this.customerInfo.loginCount = customerData.loginCount;
          this.customerInfo.accountState = customerData.accountState;
          if (type === 1) {
            this.addForm.organizationName = customerData.organizationName;
            this.addForm.customerName = customerData.customerName;
            this.addForm.gender = customerData.gender + '';
            this.addForm.accountNo = customerData.accountNo;
            this.addForm.customerId = customerData.customerId;
            this.addForm.loginCount = customerData.loginCount;
            this.addForm.authModifyTime = customerData.authModifyTime;
            this.addForm.basicModifyTime = customerData.basicModifyTime;
            this.rawData = {...this.addForm};
            //console.log(this.rawData);
          } else {
            this.detForm.organizationName = customerData.organizationName;
            this.detForm.customerName = customerData.customerName;
            this.detForm.gender = customerData.gender + '';
            this.detForm.accountNo = customerData.accountNo;
            this.detForm.customerId = customerData.customerId;
            this.detForm.loginCount = customerData.loginCount;
            this.getPassword(val,this.detForm);
          }
        } else {
          /*let errMessage = res.data.messages[0].message;
          this.$_info({
                type: "error",
                message: errMessage,
              }
          );*/
        }
      }).catch(() => {
      });
    },
    genderChange(val) {
      if (val) {
        this.$refs.addForm.validateField('gender');
      }
    },
    //新增
    addMerchant() {
      this.addEditTitle = '添加客户';
      this.addDia = true;
      this.customerInfo.salespersonName = this.$store.state.userInfo.userName;
      this.customerInfo.accountOpeningTime = '';
      this.customerInfo.loginCount = '';
      this.addForm.organizationName = '';
      this.addForm.customerName = '';
      this.addForm.gender = '';
      this.addForm.accountNo = '';
      //this.addForm.password='';
      //this.addForm.confirmPassword='';
    },
    addCancel() {
      this.addDia = false;
    },
    addSubmit() {
      //console.log(this.addForm);
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.addEditTitle === '添加客户') {
            delete this.addForm.customerId;
            delete this.addForm.authModifyTime;
            delete this.addForm.basicModifyTime;
            this.$post('/private/o-wms/v1/addVipCustomer', {data: this.addForm}).then((res) => {
              if (res.data.rtnCode === '10000') {
                this.$_info({
                      type: "success",
                      message: "添加成功",
                    },
                    () => {
                      this.addDia = false;
                      this.reload();
                    }
                );

              } else {
                /*let errMessage =  res.data.messages[0].message;
                this.$_info({
                      type: "error",
                      message: errMessage,
                    }
                );*/
              }
            }).catch(() => {
            });
          } else {
            this.getDiffData();
            this.diffData.customerId = this.addForm.customerId;
            this.diffData.authModifyTime = this.addForm.authModifyTime;
            this.diffData.basicModifyTime = this.addForm.basicModifyTime;
            if (Object.entries(this.diffData).length === 3) {
              this.addDia = false;
              this.reload();
            } else {
              this.$post('/private/o-wms/v1/setCustomer', {data: this.diffData}).then((res) => {
                if (res.data.rtnCode === '10000') {
                  this.$_info({
                        type: "success",
                        message: "编辑成功",
                      },
                      () => {
                        this.addDia = false;
                        this.reload();
                      }
                  );
                } else {
                  /*let errMessage = res.data.messages[0].message;
                  this.$_info({
                        type: "error",
                        message: errMessage,
                      }
                  );*/
                }
              }).catch(() => {
              });
            }
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    },
    editMerchant(val) {
      this.addEditTitle = '编辑客户';
      this.addDia = true;
      //console.log(val);
      this.getCustomer(val.customerId, 1);
      //this.getPassword(val.customerId,this.addForm);
    },
    getPassword(val, el) {
      this.$post('/private/o-wms/v1/getCustomerPassword', {data: {customerId: val}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          el.password = res.data.body.password;
          el.confirmPassword = res.data.body.password;
          this.rawData = {...this.addForm};
        }
      }).catch(() => {
      });
    },
    detSubmit() {
      this.detDia = false;
    }
  }
}
</script>

<style lang="scss">
.authorization {
  min-width: 1070px;
  font-size: 16px;
  $black: #323C47;
  $gray: #A7ADB3;
  $placeHolder: #D8DAE0;
  color: $black;

  .tabPage .confirm .cell {
    color: rgba(87, 139, 189, 1);
    cursor: pointer;
  }

  .customerEdit,.customerDet{
    .basicInfo, .attrInfo {
      .infoLabel {
        height: 29px;
        margin-bottom: 8px;

        .infoIcon {
          display: inline-block;
          width: 4px;
          height: 17px;
          line-height: 29px;
          border-radius: 1px;
          background: rgba(17, 53, 86, 1);
        }

        .infoTitle {
          display: inline-block;
          margin-left: 8px;
          font-size: 20px;
          height: 29px;
          line-height: 29px;
          color: rgba(56, 56, 56, 1);
        }
      }
    }
    .basicInfoUl {
      padding: 24px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);

      .basicInfoLi {
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 27px;
        color: rgba(56, 56, 56, 1);

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          display: inline-block;
          width: 96px;
          color: rgba(128, 128, 128, 1);
        }

        .val {
          color: rgba(56, 56, 56, 1);
        }

        .continue {
          color: rgba(31, 137, 237, 1);
          margin-left: 52px;
          cursor: pointer;
        }
      }
    }
    .attrInfo {
      margin-top: 24px;
      .editForm,.detUl {
        padding: 24px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        .el-form-item {
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          color: rgba(152, 152, 152, 1) !important;

          .el-form-item__label {
            width: 116px !important;
            height: 44px;
            line-height: 44px;
            text-align: left;
            padding: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          .el-form-item__content {
            height: 40px;
            .el-form-item__error {
              left: 116px;
            }

            #select, #opInput {
              display: inline-block;
              height:40px;

              .el-input {
                width: 280px;
                height:40px;

                .el-input__inner {
                  padding: 0 10px;
                  width: 280px;
                  height:40px;
                  &::placeholder {
                    font-size: 16px;
                    color: rgba(214, 214, 214, 1);;
                  }
                }
              }
            }

            #radioBox {
              width: 400px;

              .el-radio {
                margin-right: 42px;

                &:last-child {
                  margin-right: 0;
                }

                .el-radio__label {
                  font-size: 18px !important;
                  color: rgba(56, 56, 56, 1);
                }
              }
            }
          }

          .orgPrompt {
            cursor: pointer;
            display: inline-block;
            margin-left: 12px;
            width: 20.9px;
            height: 20.9px;
            background: url("../../assets/prompt@2x.png") no-repeat center;
            background-size: cover;
          }

          .promptDia {
            width:227px;
            padding: 16px;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0 2px 20px 0 rgba(46, 46, 46, 0.3);
            position: absolute;
            top:18px;
            left:430px;
            z-index: 100;

            .promptLi {
              .label {
                font-size: 14px;
                color: rgba(56, 56, 56, 1);
              }

              .val {
                font-size: 12px;
                color: rgba(56, 56, 56, 1);
                line-height: 20px;
              }
            }
          }

          .gender {
            .el-form-item__label {
              width: 116px !important;
            }
          }
        }
      }
      .detUl{
        .detLi{
          font-size: 18px;
          margin-bottom: 21px;
          &:last-child{
            margin-bottom: 0;
          }
          .label{
            display: inline-block;
            width:116px;
            height:40px;
            color: rgba(128, 128, 128, 1);
            vertical-align: top;
          }
          .val{
            display: inline-block;
            width:368px;
            min-height: 39px;
            vertical-align: top;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            .pswVal{
              display: inline-block;
              width:200px;
            }
            .findPsw{
              cursor:pointer;
              color:$btnA;
            }
          }

        }
      }
    }

  }
}

</style>
