<template>
  <div class="addDiv">
    <div :class="$store.state.userInfo.role===3?'noClickBtn':'addBtn'" @click="addFunc">
      <i class="addIcon"></i>
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "addButton",
  props: {
    title: {
      type: String,
      default: '添加客户'
    },
    className: {
      type: String,
      default: ''
    },
    addFunc: {
      type: Function,
      default: () => {
      }
    },
  }
}
</script>

<style scoped lang="scss">
$gray: #A7ADB3;
.addDiv {
  display: flex;
  align-items: center;

  .noClickBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    height: 42px;
    padding: 0 18px;
    opacity: 1;
    pointer-events: none;
    border-radius: 8px;
    background: #edf2fc;
    color: rgba(20, 78, 130, 1);
    border: 1px solid rgba(20, 78, 130, 1);
    box-shadow: 19px 4px 20px 0 rgba(0, 0, 0, 0.05);

    .addIcon {
      display: inline-block;
      margin-left: 6px;
      margin-right: 14px;
      width: 16px;
      height: 16px;
      background: url("../../assets/addIcon@2x.png") no-repeat center;
      background-size: cover;
    }
  }

  .addBtn {
    display: flex;
    font-size: 18px;
    justify-content: flex-end;
    align-items: center;
    height: 42px;
    padding: 0 18px;
    opacity: 1;
    border-radius: 8px;
    background: $white;
    color: rgba(20, 78, 130, 1);
    border: 1.5px solid rgba(20, 78, 130, 1);
    box-shadow: 19px 4px 20px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;

    .addIcon {
      display: inline-block;
      margin-left: 6px;
      margin-right: 14px;
      width: 16px;
      height: 16px;
      background: url("../../assets/addIcon@2x.png") no-repeat center;
      background-size: cover;
    }
  }
}
</style>