<template>
  <div class="indoorList">
    <!--表格-->
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :cellClick="cellClick"
                       :params="listParams">
      <el-table-column width="150px"
                       label="操作">
        <template v-slot="scope">
          <basic-button :type="'text'"
                        :title="'配置大屏'"
                        @click.native="editBunk(scope.row)">
          </basic-button>
        </template>
      </el-table-column>
    </basic-table-pager>
  </div>
</template>

<script>
import BasicButton from '../../components/basic/basicButton';
import BasicTablePager from '../../components/basicTablePager';

export default {
  components: {BasicTablePager,BasicButton},
  data() {
    return {
      searchData: {

      },
      tableKey: [
        {
          name: '大屏名称',
          value: 'screenName',
        },
        {
          name: '所在区域',
          value: 'regionName',
        },
        {
          name: '所在楼',
          value: 'buildingName',
        },
       /* {
          name: '位置',
          value: 'floorName',
        },*/
        {
          name: '大屏尺寸',
          value: 'screenSize',
        },
        {
          name: '视频地址',
          value: 'url',
        },
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryScreenList',
        isNum:true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page={pageNum: '', pageSize: ''};
  },
  methods: {
    addClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1) {
        return 'confirm'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '铺位编号') {
        this.editDetDia=true;
        this.editDetTitle='铺位详情';
        this.disabled=true;
        this.getBunkInfo(row.bunkId);
      }
    },
  }
}
</script>

<style lang="scss">
.indoorList {
  min-width: 1070px;
  padding: 40px 40px 24px!important;
  margin: 0 40px 0!important;
  font-size: 14px;
  $black: #323C47;
  $gray: #A7ADB3;
  $placeHolder: #D8DAE0;
  color: $black;
  .tabPage .confirm .cell{
    color: #409EFF;
    cursor: pointer;
  }
}
</style>