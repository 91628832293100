import axios from 'axios';
import { Loading, Message } from 'element-ui';
import tools from '../utils/tools';
import router from "@/router";
import config from '../../config/config';

axios.defaults.baseURL = config.url;
axios.defaults.timeout = 10000;

//http request 拦截器
let loadinginstace;
axios.interceptors.request.use(
    config => {
        config.data = config.data || {};
        config.data.common={latitude: null, longitude: null};
        let loadCus = config.data.loadCus;
        delete config.data.loadCus;
        config.data = JSON.stringify(config.data);
        if(config.url=== "/public/o-auth/v1/auth/wms/login"){
            config.headers = {
                'X-AppKey':'lxvrnw7mvimcx',
                'Content-Type': 'application/json',
                'X-Platform':'PCWeb',
                'X-Equip':'888888',
            };
        }else{
            config.headers = {
                'X-AppKey':'lxvrnw7mvimcx',
                'Content-Type': 'application/json',
                'Authorization':tools.getCookie('accessToken'),
                'X-Platform':'PCWeb',
                'X-Equip':'888888',

            };
        }
       /*如果请求的路径是退出登录，添加X-ACCESS-TOKEN字段
        config.url = config.url.replace('operator', 'o-operator');
        config.url = config.url.replace('authopt', 'o-authopt');
        config.url = config.url.replace(/\/(.+)\//, ($0, $1)=>{ return $0.replace($1, "o-" + $1)});
        if(config.url=== "/o-authopt/oauth/token?grant_type=password"){
            config.headers={
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                'Authorization': 'Basic dGVzdGNsaWVudDpwYXNzd29yZA==',
                'X-ACCESS-TOKEN':tools.getCookie('verification')
            }
        }else{
            config.headers = {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                'Authorization': tools.getCookie('verification'),
                'X-ACCESS-TOKEN':'Bearer'+tools.getCookie('verification')
            };
            config.url += "?t=" + new Date().getTime();
        }*/
        //element ui Loading方法
        if(!loadCus) {
            loadinginstace = Loading.service({ fullscreen: true });
        }
        delete config.data.loadCus;
        return config;
    },
    error => {
        loadinginstace.close();
        Message.error({
            message: '加载超时'
        });
        return Promise.reject(error);
    }
);

//http response 拦截器
axios.interceptors.response.use(
    response => {
        if(response.data.rtnCode!=="10000"){
            let errMessage = response.data.messages[0].message;
            if(response.data.messages[0].code==="ERR.GATEWAY0011"){
                window.vm.$_warn({
                    type: "success",
                    message: errMessage,
                });
                router.replace({
                    path: '/login'
                });
            }else{
                window.vm.$_info({
                    type: "error",
                    message: errMessage
                })
            }
        }
        loadinginstace.close();
        return response;
    },
    error => {
        loadinginstace.close();
        window.vm.$_info({
                type: "error",
                message: "网络异常",
            }
        );
        return Promise.reject(error);
    }
);

/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function fetch(url,params={}){
    return new Promise((resolve,reject) => {
        axios.get(url,{
            params:params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.post(url,data)
            .then(response => {
                resolve(response);
            },err => {
                reject(err)
            })
    })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.delete(url,data)
            .then(response => {
                resolve(response);
            },err => {
                reject(err)
            })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.patch(url,data)
            .then(response => {
                resolve(response);
            },err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.put(url,data)
            .then(response => {
                resolve(response);
            },err => {
                reject(err)
            })
    })
}

export function throwError(res, sign) {
    let code = res.rtnCode;
    if (code !== "200") {
        const err = new Error();
        err.rtnCode = code;
        let message = res.messages && res.messages.length && res.messages[0] || {
            message: "接口返回不正确的状态码，未知错误"
        };
        err.message =  message.message;
        err.code = message.code;
        sign && this.$message(err.message);
        throw err;
    }
}
