<template>
    <div id="formSearch">
        <el-form inline>
            <el-form-item :class="item.class"
                           v-for='item in searchForm'
                          :label="item.label"
                          :label-width="item.labelWidth"
                          :width="item.width"
                          :key='item.id'>
                <!-- 下拉框 -->
                <select-input v-if="item.type==='Select'"
                              v-model="searchData[item.prop]"
                              :option="item.options"
                              :placeholder="item.placeholder"
                              @visibleChange="item.change(item.url)">
                </select-input>
                <!--品类-->
                <SelectMulti v-if="item.type==='Multi'"
                             :obj="item.config"
                             :placeholder="item.placeholder"
                             v-model="searchData[item.prop[0]]"
                             :plus.sync="searchData[item.prop[1]]"
                             :endVal.sync="searchData[item.prop[2]]">
                </SelectMulti>
              <SelectMultiTwo v-if="item.type==='MultiTwo'"
                           :obj="item.config"
                           :placeholder="item.placeholder"
                           v-model="searchData[item.prop[0]]"
                           :plus.sync="searchData[item.prop[1]]">
              </SelectMultiTwo>
              <SelectMultiBusiness v-if="item.type==='MultiBusiness'"
                              :obj="item.config"
                              :placeholder="item.placeholder"
                              v-model="searchData[item.prop[0]]">
              </SelectMultiBusiness>
                <!--联想搜索-->
                <search-input v-if="item.type==='SearchById'"
                              :placeholder="item.placeholder"
                              @choose="chooseId"
                              v-model="searchData[item.prop]">
                </search-input>
                <search-input v-if="item.type==='Search'"
                              :placeholder="item.placeholder"
                              v-model="searchData[item.prop]">
                </search-input>
                <!--日期选择范围-->
                <date-self v-if="item.type==='DateRange'"
                           :placeholder="item.placeholder"
                           :monthTime="item.monthTime"
                           :startTime.sync="searchData[item.prop[0]]"
                           :endTime.sync="searchData[item.prop[1]]">
                </date-self>
              <!--日期选择范围-->
              <date-pick-range90 v-if="item.type==='DateRange90'"
                                 :placeholder="item.placeholder"
                                 :monthTime="item.monthTime"
                                 :startTime.sync="searchData[item.prop[0]]"
                                 :endTime.sync="searchData[item.prop[1]]">
              </date-pick-range90>
                <!--精准搜索-->
                <search-accurate v-if="item.type==='SearchAccurate'"
                                 :placeholder="item.placeholder"
                                 @input="searchFun"
                                 v-model="searchData[item.prop]">
                </search-accurate>
                <!--按钮-->
                <basic-button v-if="item.type==='Button'"
                              :title="item.title"
                              @click.native="$emit('clear',item.id)"
                              class="userBtn info">
                </basic-button>
                <!--规则搜索-->
                <div class="ruleSearch" v-if="item.type==='RuleSearch'">
                    <op-input :placeholder="item.placeholder"
                              v-model="item.name">
                    </op-input>
                    <select-input v-model="rule"
                                  :option="item.options"
                                  @visibleChange="item.change(item.url)">
                    </select-input>
                    <basic-button title="查询"
                                  @click.native="item.find(item.name)"
                                  class="userBtn info">
                    </basic-button>
                </div>
                <!--按类型选择输入查询-->
                <div class="selectAndInput" v-if="item.type==='selectAndInput'">
                    <select-input v-model="item.typeName"
                                  :option="item.options"
                                  @change="item.selectChange"
                                  @visibleChange="item.change(item.url)">
                    </select-input>
                    <search-accurate :placeholder="item.inputHolder"
                                     v-model="item.shopName"
                                     @input="item.clickSearch">
                    </search-accurate>
                </div>
                <!--已开户商户搜索-->
                <search-shop v-if="item.type==='SearchShop'"
                             :search="true"
                             :bindKey="item.srhConfig.bindKey"
                             :speKey="item.srhConfig.speKey"
                             :speValue="item.srhConfig.speValue"
                             :lazy="true"
                             :dataUrl="item.srhConfig.dataUrl"
                             :dealFun="item.dealFun"
                             :syncKey.sync="searchData[item.prop]"
                             class="shopName"
                             :placeholder="item.srhConfig.placeholder"
                             v-model="item.srhConfig.select"
                             :pageSize="item.srhConfig.pageSize">
                </search-shop>
                <!--所有商户搜索-->
                <search-all-shop v-if="item.type==='SearchAllShop'"
                                 :isLastId="srhConfig.isLastId"
                                 :rtKey="srhConfig.rtKey"
                                 :search="srhConfig.search"
                                 :syncKey.sync="searchData[item.prop]"
                                 :bindKey="srhConfig.bindKey"
                                 :speKey="srhConfig.speKey"
                                 :speValue="srhConfig.speValue"
                                 :lazy="srhConfig.lazy"
                                 :dataUrl="srhConfig.dataUrl"
                                 :searchFun="srhConfig.searchFun"
                                 :cbFun="item.cbFun"
                                 :dealFun="srhConfig.dealFun||dealFun"
                                 :class="srhConfig.class"
                                 :placeholder="srhConfig.placeholder"
                                 v-model="srhConfig.select"
                                 :pageSize="srhConfig.pageSize">
                </search-all-shop>
                <add-button v-if="item.type==='AddButton'" :title="item.title" :add-func="item.addFunc"></add-button>
                <!-- 白名单按钮-->
                <white-button v-if="item.type==='WhiteButton'" :title="item.title" :add-func="item.addFunc"></white-button>
                <month-year v-if="item.type==='MonthYear'"
                            :monthVal.sync="item.monthVal"
                            :monthValMonth.sync="searchData[item.prop[0]]"
                            :monthValYear.sync="searchData[item.prop[1]]"
                            :yearVal.sync="searchData[item.prop[1]]">
                </month-year>
              <multi-more v-if="item.type==='MultiMore'" v-model="searchData[item.prop]" @input="item.changeFn">
                {{searchData[item.prop]}}
              </multi-more>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import SelectInput from '../components/basic/selectInput';
    import SearchInput from '../components/basic/search';
    import SelectMulti from "../components/basic/SelectMulti";
    import SelectMultiTwo from "../components/basic/SelectMultiTwo";
    import SelectMultiBusiness from "../components/basic/SelectMultiBusiness";
    import BasicButton from '../components/basic/basicButton';
    import SearchShop from '../components/basic/SelectSplit_sel';
    import SearchAllShop from '../components/basic/SelectSplit_sel';
    import DateSelf from "../components/basic/datePickRange1";
    import SearchAccurate from "../components/basic/searchInput";
    import OpInput from "../components/basic/opInput";
    import AddButton from "../components/basic/addButton";
    import WhiteButton from "../components/basic/whiteButton";
    import MonthYear from '../components/basic/monthYear';
    import MultiMore from '../components/basic/multiMore'
    import DatePickRange90 from '@/components/basic/DatePickRange90'
    export default {
        name: "formSearch",
        components: {
            OpInput,
            SelectInput,
            SearchInput,
            SelectMulti,
            SelectMultiTwo,
            SelectMultiBusiness,
            DateSelf,
            SearchAccurate,
            BasicButton,
            SearchShop,
            SearchAllShop,
            AddButton,
            WhiteButton,
            MonthYear,
            MultiMore,
            DatePickRange90
        },
        props: {
            selectRule:{
                type:String,
                default:''
            },
            timeAry:{
                type: Object,
                default() {
                    return {}
                }
            },
            searchForm: {
                type: Array,
                default() {
                    return []
                }
            },
            searchData: {
                type: Object,
                default() {
                    return {}
                }
            },
            srhConfig: {
                type: Object,
                default() {
                    return {
                        select: "",
                        search: true,
                        bindKey: "keyWord",
                        speKey: "lastId",
                        rtKey: "shopId",
                        placeholder: "搜索商户名称",
                        dataUrl: "/operator/private/V1/business/matchShopByName",
                        pageSize: 30,
                        isLastId: true,
                        lazy: true,
                    }
                }
            }
        },
        data() {
            return {}
        },
        computed:{
            rule: {
                get:function() {
                    return this.selectRule;
                },
                set:function(value) {
                    this.$emit("update:selectRule", value);
                }
            }
        },
        methods: {
            searchFun(val){
              if(val===''){
                this.searchData.customerName=null;
              }
            },
            chooseId(val){
                this.searchData.poiId=val;
            },
            dealFun(data = { shopList: [] }) {
                return (data.shopList || []).map((o) => {
                    return {
                        label: o.shopName + "",
                        value: o.shopId + "",
                        ...o
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    #formSearch {
      height: 100px;
      display: flex;
      align-items: center;
      background-color:rgba(255, 255, 255, 1);
      text-align: left;
      font-size: 13px;
      margin: 0 24px 24px;
      .el-form{
        width:100%;
        height:100px;
        display: flex;
        align-items: center;
        .el-form-item {
          margin-left: 64px;
          margin-bottom: 0;
          margin-right: 0;
          &.monthYearC{
            width:355px;
          }
          .el-form-item__label {
            width: 80px!important;
            padding: 0;
            margin-right: 24px;
            height: 40px;
            line-height: 40px;
            text-align: left;
            color: rgba(56, 56, 56, 1);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }
          .el-form-item__content {
            height: 40px;
            #opInput {
              display: inline-block;
            }
          }
          &:first-child{
            margin-left: 40px;
          }
        }
      }
      .reset{
        margin-left: 20px!important;
        .userBtn {
          display: inline-block;
          height: 39px;
          border-radius: 8px;
          color: rgba(39, 72, 102, 1);
          background: $white;
          border: 1px solid rgba(39, 72, 102, 1);
          box-shadow: 19px 4px 20px 0 rgba(0, 0, 0, 0.05);
        }
      }
      .addItem{
         margin-left: auto!important;
         margin-right: 28px!important;
      }
      .whiteBtn{
        margin-left: auto!important;
        margin-right: 32px!important;
      }
    }
</style>
