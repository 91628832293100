<template>
        <el-button :type="type" :size="size" :disabled="disabled" :class="className"
                   :round="round" :plain="plain" :circle="circle" :autofocus="autofocus"
                   :icon="iconName" :native-type="nativeType" :loading="loading">
            {{title}}
        </el-button>
</template>

<script>
    export default {
        name: "CommonButton",
        // 接受父组件的值
        props: {
            type: {  //type	类型	string	primary / success / warning / danger / info / text
                type: String,
                default: 'warning'
            },
            title: {  //按钮Name
                type: String,
                default: '我是按钮'
            },
            size: {  //尺寸	string	medium / small / mini
                type: String,
                default: 'medium'
            },
            disabled: {//是否禁用状态	boolean
                type: Boolean,
                default: false
            },
            round: {//圆角
                type: Boolean,
                default: false
            },
            plain: {//朴素
                type: Boolean,
                default: false
            },
            circle: {//是否圆形按钮
                type: Boolean,
                default: false
            },
            loading: {//是否加载中状态
                type: Boolean,
                default: false
            },
            autofocus: {//是否默认聚焦	boolean
                type: Boolean,
                default: false
            },
            nativeType: {  //原生 type 属性	string	button / submit / reset
                type: String,
                default: 'button'
            },
            iconName: {  //icon	图标类名	string
                type: String,
                default: ''
            },
            className: { //class
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss">

</style>
