import Vue from "vue";
import Vuex from "vuex";
import getters from "./getter.js";

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    tags: [
      {
        name: "客户管理",
        status: "active",
        type: "default",
        path: "/customerManage",
      },
    ],
    //中间内容区域的高度，监控windows，动态变化
    contentHeight: 0,
    //缓存用户信息
    userInfo: {},
    //左侧导航栏收缩和展开控制  true-收起  false-展开
    isCollapse: false,
    //260px
    collWidth: 260
  },
  mutations: {
    SET_TAGS: (state, _tags) => {
      state.tags = _tags;
    },
    ADD_TAGS: (state, _tag) => {
      state.tags.push(_tag);
    },
    //重置头部标签
    RESET_TAGS: (state) => {
      state.tags = [
        /*{
          name: "默认页",
          status: "active",
          type: "default",
          path: "/dashboard",
        },*/
        {
          name: "客户管理",
          status: "active",
          type: "default",
          path: "/customerManage",
        },
      ];
    },
    SET_HEIGHT: (state, _height) => {
      state.contentHeight = _height;
    },
    //添加缓存用户信息
    SET_USERINFO: (state, _info) => {
      state.userInfo = _info;
    },
    //重置state数据状态
    RESET_STATE:(state) => {
      state.userInfo = {};
      state.contentHeight = 0;
      state.tags = [
        {
          name: "客户管理",
          status: "active",
          type: "default",
          path: "/customerManage",
        },
      ];
    },
    SET_COLLAPSE: (state, _isCollapse) => {
      state.isCollapse = _isCollapse;
    },
    CHANGE_COLLAPSE: (state) => {
      if(state.isCollapse){
        state.isCollapse = false
        state.collWidth = 260
      }else{
        state.isCollapse = true
        state.collWidth = 125
      }
    }
  },
  actions: {
    setTags({ commit }, _tags) {
      commit("SET_TAGS", _tags);
    },
    addTags({ commit }, _tag) {
      commit("ADD_TAGS", _tag);
    },
    resetTags({commit}){
      commit("RESET_TAGS");
    },
    setContentHeight({ commit }, _height) {
      commit("SET_HEIGHT", _height - 60 - 40 - 30);
    },
    setUserInfo({commit}, _info){
      commit("SET_USERINFO", _info);
    },
    resetState({commit}){
      commit("RESET_STATE");
    },
    setCollapse({commit}, _isCollapse){
      commit("SET_COLLAPSE", _isCollapse);
    },
    changeCollapse({commit}){
      commit("CHANGE_COLLAPSE")
    }
  },
  getters
});
