<template>
  <div class="indoorList">
    <search-form
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <!--表格-->
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :cellClick="cellClick"
                       :params="listParams">
      <el-table-column width="150px"
                       label="操作">
        <template v-slot="scope">
          <basic-button :type="'text'"
                        :title="'编辑点位'"
                        @click.native="editPoint(scope.row)">
          </basic-button>
        </template>
      </el-table-column>
    </basic-table-pager>
    <comfort-dia v-if="editDetDia"
                 :title="editDetTitle"
                 ref="dia"
                 :dialogVisible="editDetDia"
                 :isTextArea="false"
                 :isCancel="editDetTitle==='点位信息详情'?false:true"
                 :cancelTitle="'取消'"
                 :submitTitle="editDetTitle==='点位信息详情'?'关闭':'确认'"
                 :cancel="editCancel"
                 :submit="editDetTitle==='点位信息详情'?detSubmit:editSubmit"
                 :width="editDetWidth">
      <div slot="header"
           class="title">
        <div class="headInfo">
          <div>点位id：{{ pointInfo.workAreaId || pointInfo.facilityId }}</div>
          <div>点位名称：{{ pointInfo.name }}</div>
          <div>所属区域：{{ pointInfo.regionName }}</div>
          <div>所属楼：{{ pointInfo.buildingName }}</div>
          <div>所属层：{{ pointInfo.floorName }}</div>
        </div>
        <el-form class="editForm"
                 id="editForm"
                 v-if="type===1"
                 :model="facilityForm"
                 :rules="facilityFormRules"
                 autocomplete="on"
                 ref="editForm">
          <el-form-item label="设施类型"
                        prop="facilityType">
            <op-input v-model="facilityForm.facilityType"
                      :disabled="disabled"
                      placeholder="请输入经营业态"></op-input>
          </el-form-item>
          <el-form-item label="设施介绍"
                        prop="introduction">
            <op-input v-model="facilityForm.introduction"
                      :disabled="disabled"
                      placeholder="请输入铺位面积"></op-input>
          </el-form-item>
        </el-form>
        <el-form class="editForm"
                 v-else
                 id="editForm"
                 :model="workForm"
                 :rules="workFormRules"
                 autocomplete="on"
                 ref="editForm">
          <el-form-item label="工区面积"
                        prop="area">
            <op-input v-model="workForm.area"
                      :disabled="disabled"
                      placeholder="请输入工区面积"></op-input>
          </el-form-item>
          <el-form-item label="工区朝向"
                        prop="faceTo">
            <op-input v-model="workForm.faceTo"
                      :disabled="disabled"
                      placeholder="请输入工区朝向"></op-input>
          </el-form-item>
          <el-form-item label="配套设备"
                        prop="facility">
            <op-input v-model="workForm.facility"
                      :disabled="disabled"
                      placeholder="请输入配套设备"></op-input>
          </el-form-item>
          <el-form-item label="工区高"
                        prop="height">
            <op-input v-model="workForm.height"
                      :disabled="disabled"
                      placeholder="请输入工区高"></op-input>
          </el-form-item>
          <el-form-item label="工区宽"
                        prop="width">
            <op-input v-model="workForm.width"
                      :disabled="disabled"
                      placeholder="请输入工区宽"></op-input>
          </el-form-item>
          <el-form-item label="工区介绍"
                        prop="introduction">
            <op-input v-model="workForm .introduction"
                      :disabled="disabled"
                      placeholder="请输入工区介绍"></op-input>
          </el-form-item>
        </el-form>
      </div>
    </comfort-dia>
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import ComfortDia from '../../components/basic/comfortDialog';
import BasicButton from '../../components/basic/basicButton';
import OpInput from '../../components/basic/opInput';

export default {
  components: {SearchForm, BasicTablePager, ComfortDia, BasicButton, OpInput},
  data() {
    return {
      //是否可编辑
      disabled: false,
      //区分是设施点位还是工区点位
      type: '',

      //区域
      regionList: [],
      //建筑
      buildingMap: {},
      //楼层
      floorMap: {},
      //弹框标题
      editDetTitle: '',
      //弹框显隐
      editDetDia: false,
      //弹框宽度
      editDetWidth: '880px',

      //接口获取的数据对象
      pointInfoObj: {},
      //获取的点位信息
      pointInfo: {
        facilityId: '',
        workAreaId: '',
        name: '',
        regionName: '',
        buildingName: '',
        floorName: ''
      },

      //设施点位Form
      facilityForm:{
        facilityId:'',
        facilityType: '',
        facilityTypeEng:'',
        introduction: '',
        introductionEng:'',
        modifyTime:'',
      },
      //工区点位Form
      workForm: {
        workAreaId:'',
        area: '',
        faceTo: '',
        faceToEng:'',
        depth:'',
        height: '',
        width: '',
        facility: '',
        facilityEng:'',
        introduction:'',
        introductionEng:'',
        modifyTime:'',
      },
      //设施点位校验规则
      facilityFormRules: {
        area: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        faceTo: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        height: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        width: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        facility: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        introduction: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
      },
      //工区点位校验规则
      workFormRules: {
        facilityType: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        introduction: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
      },

      //列表查询参数
      searchData: {
        //区域
        regionId: null,
        //建筑
        buildingId: null,
        //楼层
        floorId: null,
        //点位类型
        indoorPoiType: "0",
        //点位名称
        name: '',
        refresh: false,
      },
      //列表筛选表单
      searchForm: [
        //选择区域
        {
          type: 'Select',
          label: '所属区域',
          labelWidth: '70px',
          prop: 'regionId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          id: 1,
          change: () => {
            this.regionList.forEach(item => {
              this.searchForm[0].options.push({label: item.name, value: item.id});
            })
          }
        },
        //选择建筑
        {
          type: 'Select',
          label: '所属建筑',
          labelWidth: '70px',
          prop: 'buildingId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          id: 2,
          change: () => {
            let currentRegionId = this.searchData.regionId;
            //console.log(currentRegionId);
            this.buildingMap[currentRegionId].forEach(item => {
              this.searchForm[1].options.push({label: item.name, value: item.id})
            })
          }
        },
        //选择楼层
        {
          type: 'Select',
          label: '所属楼层',
          labelWidth: '70px',
          prop: 'floorId',
          options: [{label: "全部", value: null}],
          placeholder: '请选择',
          id: 3,
          change: () => {
            let currentBuildingId = this.searchData.buildingId;
            this.floorMap[currentBuildingId].forEach(item => {
              this.searchForm[2].options.push({label: item.name, value: item.id})
            })
          }
        },
        //编辑状态
        {
          type: 'Select',
          label: '点位类型',
          labelWidth: '70px',
          prop: 'indoorPoiType',
          options: [{label: "全部", value: "0"}],
          placeholder: '请选择',
          id: 4,
          change: () => {
            this.searchForm[3].options = [{label: "全部", value: "0"}, {label: "设施点位", value: "1"}, {
              label: "工区点位",
              value: "2"
            }];
          }
        },
        //点位名称
        {
          type: 'SearchAccurate',
          label: '',
          placeholder: '请输入点位名称',
          prop: 'name',
          id: 5,
        },
      ],
      //列表配置参数
      tableKey: [
        {
          name: '点位名称',
          value: 'name',
        },
        {
          name: '点位类型',
          value: 'poiType',
        },
        {
          name: '所属区域',
          value: 'regionName',
        },
        {
          name: '所属楼',
          value: 'buildingName',
        },
        {
          name: '所属层',
          value: 'floorName',
        },
        {
          name: '点位信息',
          value: 'indoorPoiId',
          formatVal: (row, column, cellValue) => {
            return '查看'
          }
        },
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryIndoorPoiList',
        isNum: true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
    }
  },
  created() {
    //获取区域建筑楼层
    this.$post('/private/o-wms/v1/getAreaComboBox', {data: {}}).then((res) => {
      if (res.data.rtnCode === '10000') {
        //console.log(res.data.body);
        let blockData = res.data.body;
        //区域
        this.regionList = blockData.regionList;
        //建筑
        this.buildingMap = blockData.buildingMap;
        //楼层
        this.floorMap = blockData.floorMap;
      } else {
        let errMessage = res.data.messages[0].message;
        this.$_info({
          type: "error",
          message: errMessage,
        });
      }
    }).catch(() => {
    });
    //获取列表
    this.listParams.data = this.searchData;
    this.listParams.data.page = {pageNum: '', pageSize: ''};
  },
  methods: {
    //查看样式
    addClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 6) {
        return 'confirm'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '点位信息') {
        this.editDetDia = true;
        this.editDetTitle = '点位信息详情';
        this.disabled = true;
        if (row.poiType === 1) {
          this.type = 1;
          this.getFacilitiesInfo(row.indoorPoiId);
        } else {
          this.type = 2;
          this.getWorkAreaInfo(row.indoorPoiId);
        }
      }
    },
    //取消按钮
    editCancel() {
      this.editDetDia = false;
    },
    editSubmit() {
      if(this.type===1){
        this.$post('/private/o-wms/v1/setIndoorFacilityPoiInfo', {data: this.facilityForm}).then((res) => {
          if (res.data.rtnCode === '10000') {
            this.$_info({
                  type: "success",
                  message: "编辑成功",
                },
                () => {
                  this.editDetDia = false;
                  this.searchData.refresh = !this.searchData.refresh;
                }
            );
          } else {
            let errMessage = res.data.messages[0].message;
            this.$_info({
                  type: "error",
                  message: errMessage,
                }
            );
          }
        }).catch(() => {});
      }else{
        this.$post('/private/o-wms/v1/setIndoorWorkAreaPoiInfo', {data: this.workForm}).then((res) => {
          if (res.data.rtnCode === '10000') {
            this.$_info({
                  type: "success",
                  message: "编辑成功",
                },
                () => {
                  this.editDetDia = false;
                  this.searchData.refresh = !this.searchData.refresh;
                }
            );
          } else {
            let errMessage = res.data.messages[0].message;
            this.$_info({
                  type: "error",
                  message: errMessage,
                }
            );
          }
        }).catch(() => {});
      }
    },
    //获取室内设施点位信息
    getFacilitiesInfo(id) {
      this.$post('/private/o-wms/v1/getIndoorFacilityPoiInfo', {data: {facilityId: id}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          this.pointInfoObj = res.data.body.indoorFacilityVO;
          this.pointInfo.bunkId = this.pointInfoObj.bunkId;
          this.pointInfo.bunkNo = this.pointInfoObj.bunkNo;
          this.pointInfo.regionName = this.pointInfoObj.regionName;
          this.pointInfo.buildingName = this.pointInfoObj.buildingName;
          this.pointInfo.floorName = this.pointInfoObj.floorName;
          this.facilityForm.facilityId=this.pointInfoObj.facilityId;
          this.facilityForm.facilityType=this.pointInfoObj.facilityType;
          this.facilityForm.facilityTypeEng=this.pointInfoObj.facilityTypeEng;
          this.facilityForm.introduction=this.pointInfoObj.introduction;
          this.facilityForm.introductionEng=this.pointInfoObj.introductionEng;
          this.facilityForm.modifyTime=this.pointInfoObj.modifyTime;
        } else {
          let errMessage = res.data.messages[0].message;
          this.$_info({
                type: "error",
                message: errMessage,
              }
          );
        }
      }).catch(() => {
      });
    },
    //获取室内工区点位信息
    getWorkAreaInfo(id) {
      this.$post('/private/o-wms/v1/getIndoorWorkAreaPoiInfo', {data: {workAreaId: id}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          this.pointInfoObj = res.data.body.indoorWorkAreaVO;
          this.pointInfo.workAreaId = this.pointInfoObj.workAreaId;
          this.pointInfo.name = this.pointInfoObj.name;
          this.pointInfo.regionName = this.pointInfoObj.regionName;
          this.pointInfo.buildingName = this.pointInfoObj.buildingName;
          this.pointInfo.floorName = this.pointInfoObj.floorName;
          this.workForm.workAreaId= this.pointInfoObj.workAreaId;
          this.workForm.area= this.pointInfoObj.area;
          this.workForm.faceTo= this.pointInfoObj.faceTo;
          this.workForm.faceToEng= this.pointInfoObj.faceToEng;
          this.workForm.facility= this.pointInfoObj.facility;
          this.workForm.facilityEng= this.pointInfoObj.facilityEng;
          this.workForm.depth= this.pointInfoObj.depth;
          this.workForm.height= this.pointInfoObj.height;
          this.workForm.width= this.pointInfoObj.width;
          this.workForm.introduction= this.pointInfoObj.introduction;
          this.workForm.introductionEng= this.pointInfoObj.introductionEng;
          this.workForm.modifyTime= this.pointInfoObj.modifyTime;
        } else {
          let errMessage = res.data.messages[0].message;
          this.$_info({
                type: "error",
                message: errMessage,
              }
          );
        }
      }).catch(() => {
      });
    },
    //点击点位信息
    editPoint(val) {
      this.editDetTitle = '编辑点位';
      this.editDetDia = true;
      this.disabled=false;
      if (val.poiType === 1) {
        this.type = 1;
        this.getFacilitiesInfo(val.indoorPoiId);
      } else {
        this.type = 2;
        this.getWorkAreaInfo(val.indoorPoiId);
      }
    },
    //关闭按钮
    detSubmit() {
      this.editDetDia = false;
    }
  }
}
</script>

<style lang="scss">
.indoorList {
  min-width: 1070px;
  padding: 40px 40px 24px !important;
  margin: 0 40px 0 !important;
  font-size: 14px;
  $black: #323C47;
  $gray: #A7ADB3;
  $placeHolder: #D8DAE0;
  color: $black;

  #formSearch {
    .el-form-item {
      margin-bottom: 24px;
    }

    .el-form-item:last-child {
      margin-bottom: 24px;
    }
  }

  .btnArea {
    text-align: left;
    margin-bottom: 10px;
  }

  .headInfo {
    margin: 20px;

    div {
      margin: 10px;
    }

    .expireTime {
      display: inline-block;
      margin-right: 50px;
    }

    .continue {
      cursor: pointer;
    }
  }

  #addForm {
    .el-form-item {
      margin-bottom: 24px;

      .el-form-item__label {
        width: 92px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        color: $gray;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .el-form-item__content {
        height: 40px;

        #opInput {
          display: inline-block;
          width: 200px;
        }
      }

      /*表单验证错误提示*/
      .el-form-item__error {
        color: #F56C6C;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 92px;
      }
    }
  }

  .tabPage .confirm .cell {
    color: #409EFF;
    cursor: pointer;
  }
}
</style>
