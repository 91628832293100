<template>
  <div class="staffList">
    <search-form
        @clear="clear"
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <div class="roleSet" @click="roleEmpower">
      角色授权
    </div>
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :checkSelect="checkSelectFn"
                       :params="listParams">
    </basic-table-pager>
    <!--角色授权弹框-->
    <comfort-dia v-if="roleDia"
                 ref="dia"
                 class="loadingDia"
                 :width="'600px'"
                 :title="'角色授权'"
                 :submitTitle="'确认'"
                 :cancel="addCancel"
                 :submit="addSubmit"
                 :dialogVisible="roleDia"
                 :isTextArea="false">
      <div slot="header"
           class="roleDiv">
        <el-form class="addForm"
                 id="addForm"
                 :model="addForm"
                 :rules="addFormRules"
                 autocomplete="on"
                 ref="addForm">

          <el-form-item label=""
                        class="role"
                        prop="role">
            <radio-box :radioAry="roles"
                       @input="roleChange"
                       v-model="addForm.role"></radio-box>
          </el-form-item>
        </el-form>

      </div>
    </comfort-dia>
    <!--白名单弹框-->
    <comfort-dia v-if="whiteListDia"
                 ref="dia"
                 class="whiteListDia"
                 :width="'905px'"
                 :title="'设置白名单'"
                 :submitTitle="'确认'"
                 :cancel="addCancelWhite"
                 :submit="addSubmitWhite"
                 :dialogVisible="whiteListDia"
                 :isTextArea="false">
      <div slot="header"
           class="whiteListDiv">
        <div class="leftDiv">
          <search-accurate @input="searchFun"
                           placeholder="输入组织/员工姓名/员工账号"
                           v-model="searchAccount">
          </search-accurate>
          <div class="titleDiv">
            <span class="titleIcon"></span>
            <span class="titleText">组织架构</span>
          </div>
          <div class="orgDiv">
            <tree-node v-if="!searchAccount" ref="childRef" @input="checkFn" v-model="selectedKeys"></tree-node>
            <ul class="orgUl" v-if="searchAccount">
              <li class="orgLi" v-show="orgList.length">
                <div class="orgLabel">组织</div>
                <el-tree class="orgTree" ref="orgTree" node-key="orgSysId" show-checkbox :data="orgList" :props="orgProps" v-model="orgs" @check-change="orgClick"></el-tree>
              </li>
             <li class="orgLi" v-show="userList.length">
               <div class="orgLabel">人员</div>
               <el-tree class="userTree" ref="userTree" node-key="orgSysId" show-checkbox :data="userList" :props="userProps" v-model="users" @check-change="userClick"></el-tree>
             </li>
            </ul>
          </div>
        </div>
        <div class="rightDiv">
          <ul class="rightUl">
            <li class="rightLi" v-for="checkItem in checkArr" :key="checkItem.orgSysId">
              <span class="orgName">{{checkItem.curName}}</span>
              <span class="clearIcon" v-if="!searchAccount" @click="deleteOrg(checkItem)"></span>
              <span class="clearIcon" v-if="searchAccount" @click="deleteOrgSearch(checkItem)"></span>
            </li>
          </ul>
        </div>
      </div>
    </comfort-dia>
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import ComfortDia from '../../components/basic/comfortDialog';
import OpInput from '../../components/basic/opInput';
import BasicButton from '../../components/basic/basicButton';
import SelectInput from '../../components/basic/selectInput';
import RadioBox from '../../components/basic/radioBox';
import rules from "@/utils/rules";
import SearchAccurate from "../../components/basic/searchInput";
import TreeNode from "../../components/basic/treeNode";


export default {
  components: {SearchForm, BasicTablePager, ComfortDia, OpInput, BasicButton, SelectInput,RadioBox,SearchAccurate,TreeNode},
  inject: ['reload'],
  data() {
    return {
      //列表多选存放数组
      checkSelectArr:[],
      roleDia:false,
      whiteStaffCount:'',
      searchData: {
        orgSysId: '',
        account: '',
      },
      searchForm: [
        //选择组织
        {
          type: 'MultiMore',
          label: '',
          prop: 'orgSysId',
          changeFn:(val) => {
            this.searchData.orgSysId=val;
          },
          id: 1,
        },
        {
          type: 'SearchAccurate',
          label: '',
          placeholder: '请输入员工账号',
          prop: 'account',
          id: 2,
          class: 'searchItem'
        },
        {
          type: 'Button',
          class: 'reset',
          title: '重置',
          id: 3,
        },
        {
          type: 'WhiteButton',
          class: 'whiteBtn',
          id: 4,
          addFunc:() => {
            this.searchAccount='';
            this.whiteListDia=true;
            this.getHklcnWhiteSetting();
          }
        }
      ],
      tableKey: [
        {
          name: '员工姓名',
          value: 'name',
          width: 200,
        },
        {
          name: '性别',
          value: 'gender',
          width: 140,
          formatVal: (row, column, cellValue) => {
            return cellValue===1?'男':'女'
          }
        },
        {
          name: '所属部门',
          value: 'org',
        },
        {
          name: '账号',
          value: 'account',
          width: 200
        },
        {
          name: '邮箱',
          value: 'email',
          width: 200
        },
        {
          name: '权限',
          value: 'roleName',
          width: 100
        },
        {
          name: '状态',
          value: 'status',
          width: 100
        }
      ],
      tablep: {
        url: '/private/o-wms/v1/staff/queryHklcnUsersForWhite',
        isNum: true,
        isChecks:true
      },
      listParams: {
        data: {},
      },
      roles: [],
      addForm: {
        role: '',
      },
      addFormRules: {
        role: [{
          validator: rules.validateGender,
          trigger: 'change',
        }],
      },
      whiteListDia:false,
      searchAccount:'',
      checkArr:[],
      selectedKeys: [],
      orgProps: {
        children: 'children',
        label: 'curName'
      },
      userProps: {
        children: 'children',
        label: 'curName'
      },
      orgList:[],
      userList:[],
      orgs:[],
      users:[],
      dataVersion:''
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page = {pageNum: '', pageSize: ''};
  },
  mounted(){
    this.getHklcnRoleConfig();
  },
  methods: {
    //获取香港置地角色信息
    getHklcnRoleConfig(){
      this.$post('/private/o-wms/v1/staff/getHklcnRoleConfig',{}).then((res) => {
        if (res.data.rtnCode === '10000') {
          let roleList=res.data.body.roleList;
          roleList.forEach(item=>{
            this.roles.push({label:item.name,value:item.code})
          })
        }
      }).catch(() => {
      });
    },
    //获取香港置地白名单信息
    getHklcnWhiteSetting(){
      this.checkArr=[];
      this.$post('/private/o-wms/v1/staff/getHklcnWhiteSetting',{}).then((res) => {
        if (res.data.rtnCode === '10000') {
          this.dataVersion=res.data.body.dataVersion;
          let whiteList=res.data.body.whiteList;
          whiteList.forEach((item,index)=>{
            this.checkArr.push({info:item.info,type:item.type,curName:item.info,orgSysId:index })
          })
          console.log(this.checkArr);
          this.selectedKeys=this.checkArr.map(
              (item) => item
          );
        }
      }).catch(() => {
      });
    },
    //列表多选按钮点击
    checkSelectFn(val){
      console.log(val);
      this.checkSelectArr=val.map(item=>{
        return item.account
      })
    },
    //判断数组B中每一项是否在A中有，有不添加到A，没有就添加
    arrAdd(arrayA,arrayB){
      //遍历数组B中的每个对象
      arrayB.forEach(objB => {
        //检查数组A中是否存在相同属性值的对象
        let isDuplicate = arrayA.some(objA => objA.info === objB.info);
        //如果数组A中不存在相同属性值的对象，则将对象添加到数组A中
        if (!isDuplicate) {
          arrayA.push(objB);
        }
        return arrayA;
      });
    },
    //非搜索状态下获取所有选中项数据
    checkFn(val){
      console.log(val);
      //console.log(this.checkArr);
      //判断checkArr是否有数据，如果有则添加并去重
      if(this.checkArr.length){
        this.arrAdd(this.checkArr,val);
        console.log(this.checkArr);
      }else{
        this.checkArr=val;
      }
    },
    //非搜索状态下的右侧删除点击
    deleteOrg(currentOrg){
      this.checkArr = this.checkArr.filter(
          (item) => item.curName!==currentOrg.curName
      );
      this.selectedKeys=this.checkArr.map(
          (item) => item
      );
      this.$nextTick(() => {
        // 重新设置勾选状态
        this.$refs.childRef.$refs.tree.setCheckedNodes(this.selectedKeys);
      });
    },
    //搜索状态下的右侧删除点击
    deleteOrgSearch(currentOrg){
      this.checkArr = this.checkArr.filter(
          (item) => item.curName!==currentOrg.curName
      );
      //如果删除的是员工
      if(currentOrg.type){
        this.users = this.checkArr.filter(
            (item) => item.curName!==currentOrg.curName
        );
        this.$nextTick(() => {
          this.$refs.userTree.setCheckedNodes(this.users);
        });
      }else{
        //如果删除的是组织
        this.orgs = this.checkArr.filter(
            (item) => item.curName!==currentOrg.curName
        );
        this.$nextTick(() => {
          this.$refs.orgTree.setCheckedNodes(this.orgs);
        });
      }
    },
    clear() {
      this.reload();
    },
    //角色授权按钮
    roleEmpower(){
      if(this.checkSelectArr.length){
        this.roleDia=true;
      }else{
        this.$_info({
              type: "error",
              message: "请选择员工",
            },
            () => {

            }
        );
      }
    },
    addCancel() {
      this.roleDia=false;
      this.addForm.role='';
    },
    addSubmit() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$post('/private/o-wms/v1/staff/setHklcnUsersRole',{data:{accountNoList:this.checkSelectArr,roleCode:this.addForm.role}}).then((res) => {
            if (res.data.rtnCode === '10000') {
              this.$_info({
                    type: "success",
                    message: "设置成功",
                  },
                  () => {
                    this.reload();
                  }
              );
            }
          }).catch(() => {
          });
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          }, 1);
          return false;
        }
      });
    },
    roleChange(val) {
      if (val) {
        this.$refs.addForm.validateField('role');
      }
    },
    addCancelWhite() {
      this.whiteListDia=false;
    },
    //设置白名单
    addSubmitWhite() {
      //console.log(this.checkArr);
      let whiteList=this.checkArr.map(item=>{
        return {info:item.info,type:item.type}
      })
      //console.log(whiteList);
      this.$post('/private/o-wms/v1/staff/setHklcnWhiteSetting',{data:{dataVersion:this.dataVersion,whiteList:whiteList}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          this.$_info({
                type: "success",
                message: "设置成功",
              },
              () => {
                this.reload();
              }
          );
        }
      }).catch(() => {
      });
    },
    //搜索图标点击
    searchFun(val){
      if(val) {
        this.$post('/private/o-wms/v1/staff/queryHklcnOrgTree', {data: {key: val}}).then((res) => {
          if (res.data.rtnCode === '10000') {
            let resultData = res.data.body;
            this.orgList = resultData.orgList.map((item, index) => {
              return {curName: item.org, orgSysId: index + item.org, type: 0, info: item.org}
            });
            this.userList = resultData.userList.map((item, index) => {
              return {curName:item.name?item.name+'（'+item.account+'）':item.account, orgSysId: index + item.name, type: 1, info: item.name?item.name+'（'+item.account+'）':item.account}
            });
          }
        }).catch(() => {
        });
        //this.checkArr=[];
        //console.log(this.checkArr);
      }
    },
    //组织选中状态变化
    orgClick(data, checked, node){
      this.orgs=this.$refs.orgTree.getCheckedNodes();
      let checkOrgs=[];
      if(this.users.length){
        checkOrgs=this.orgs.concat(this.users);
      }else{
        checkOrgs=this.orgs;
      }
      //判断checkArr是否有数据，如果有则添加并去重
      if(this.checkArr.length){
        this.arrAdd(this.checkArr,checkOrgs);
      }else{
        this.checkArr=checkOrgs;
      }
      console.log(this.checkArr);
    },
    //人员选中状态变化
    userClick(data, checked, node){
      this.users=this.$refs.userTree.getCheckedNodes();
      let checkUsers=[];
      if(this.orgs.length){
        checkUsers=this.orgs.concat(this.users);
      }else{
        checkUsers=this.users;
      }
      //判断checkArr是否有数据，如果有则添加并去重
      if(this.checkArr.length){
        this.arrAdd(this.checkArr,checkUsers);
      }else{
        this.checkArr=checkUsers;
      }
    }
  },
}
</script>

<style lang="scss">
.staffList {
  min-width: 1070px;
  font-size: 16px;
  .roleSet{
    margin:18px 0 16px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 40px;
    border-radius: 8px;
    background: rgba(65, 93, 120, 1);
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
  .comfortDialog .el-dialog{
    .el-dialog__header {
      padding: 0;
      height: 71px;
      border-radius: 8px 8px 0 0;
      background: rgba(17, 53, 86, 1);
      .el-dialog__title {
        height:32px;
        padding-left: 0;
        font-size: 22px;
        font-weight: 400;
        line-height: 71px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .el-dialog__body{
      width:600px;
      margin: 0;
      .roleDiv{
        margin:24px 24px 0;
        width: 552px;
        height: 77px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        .role{
          height:77px;
          .el-form-item__content {
            height: 77px;
            #radioBox{
              padding: 24px 120px 24px;
              height:29px;
              .el-radio {
                height: 29px;
                font-size: 20px;
                font-weight: 400;
                line-height: 29px;
                color: rgba(103, 111, 117, 1);
                text-align: left;
                vertical-align: top;
                margin-right: 150px;
                &:last-child{
                  margin-right: 0;
                }
                .el-radio__label {
                  font-size: 20px;
                }
                .el-radio__input{
                  height:29px;
                  line-height: 29px;
                  .el-radio__inner{
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
        .el-form .el-form-item .el-form-item__content .el-form-item__error {
          left: 0;
        }
      }
      .btn {
        padding: 24px 24px 32px 0;
        text-align: right;
      }
    }
  }
  #formSearch{
    height: 88px;
    .el-form {
      height: 88px;
    }
    #searchInput{
      width:529px;
      .el-input{
        width:529px;
        .el-input__inner {
          width:529px;
        }
      }
    }
  }
  .whiteListDia{
    .whiteListDiv{
      width:857px;
      padding: 24px 24px 0;
      .leftDiv{
        display: inline-block;
        vertical-align: top;
        width: 420px;
        height:556px;
        margin-right: 17px;
        .titleDiv{
          height:29px;
          margin-top: 16px;
          .titleIcon{
            display: inline-block;
            width: 4px;
            height: 17px;
            line-height: 29px;
            border-radius: 1px;
            background: rgba(17, 53, 86, 1);
            margin-right: 8px;
            vertical-align:middle;
          }
          .titleText{
            display: inline-block;
            height: 29px;
            font-size: 20px;
            font-weight: 400;
            line-height: 29px;
            color: rgba(21, 56, 87, 1);
            vertical-align: middle;
          }
        }
        .orgDiv{
          margin-top: 8px;
          width: 420px;
          height: 463px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 1);
          overflow-y: auto;
          .el-tree{
            padding: 32px 16px;
          }
          .orgUl{
            .orgLi{
              .orgLabel{
                padding-left: 32px;
                padding-top: 16px;
              }
              .orgTree{
                padding:8px 16px 0 8px ;
              }
              .userTree{
                padding:8px 16px 8px 8px;
              }
            }
          }
        }
        #searchInput{
          .el-input{
            width:420px;
            .el-input__inner{
              width:420px;
            }
          }
        }
      }
      .rightDiv{
        display: inline-block;
        vertical-align: top;
        width: 341px;
        height: 492px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        padding: 32px 45px 32px 34px;
        overflow-y: auto;
        .rightUl{
          .rightLi{
            margin-bottom: 16px;
            &:last-child{
              margin-bottom: 0;
            }
            .orgName{
              display: inline-block;
              width:305px;
              font-size: 16px;
              line-height: 24px;
              color: rgba(103, 111, 117, 1);
              vertical-align:middle;
              text-align: left;
            }
            .clearIcon{
              display: inline-block;
              width:10px;
              height:10px;
              background: url('../../assets/checkClear@2x.png') no-repeat center;
              background-size: cover;
              vertical-align:middle;
              cursor: pointer;
            }
          }
        }
      }
    }
    .btn {
      width:881px;
      padding: 24px 24px 33px 0;
      text-align: right;
    }
  }
}

</style>
