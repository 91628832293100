<template>
  <div class="main-tags-v">
    <el-tag
        class="tag-v"
        :class="[tag.type === 'default' ? 'default-tags' : '',tag.status === 'active' ? 'active-tags' : '',]"
        v-for="tag in tags"
        :key="tag.name"
        closable
        :type="tag.status"
        @close="handleClose(tag)"
        @click.stop="selectTags(tag)">
      <span class="tagIcon"></span>
      {{ tag.name }}
    </el-tag>
  </div>
</template>

<script>
export default {
  name:"mainTags",
  data() {
    return {
      tags: this.$store.state.tags,
    };
  },
  methods: {
    handleClose(tag) {
      this.$store.state.actIndex = "1";
      var _index = this.tags.indexOf(tag);
      this.tags.splice(_index, 1);
      //重置标签状态，刷新页面
      var _obj = this.$manageTags.resetTags(_index);
      if (_obj && _obj.length === 2) {
        var _path = _obj[0];
        var _tagIndex = _obj[1];
        console.log(_obj)
        if (_path) {
          this.$router.replace(_path);
          this.$store.state.actIndex = _tagIndex;
        }
      }
    },
    selectTags(tag) {
      var _path = this.$manageTags.selectTag(tag);
      if (_path) {
        this.$router.replace(_path);
      }
    },
  },
};
</script>
<style lang="scss">
.main-tags-v {
  height:52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(245, 246, 250, 1);
  .el-tag{
    padding: 0!important;
  }
  .tag-v {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px!important;
    background: none !important;
    border-radius: 0 !important;
    margin-left: 24px;
    border: none !important;
    color:rgba(56, 56, 56, 1) !important;
    height: 24px !important;
    cursor: pointer;
    .tagIcon{
      display: inline-block;
      width: 4px;
      height: 17px;
      opacity: 1;
      border-radius: 1px;
      background: rgba(17, 53, 86, 1);
      margin-right: 8px;
    }
  }
  .el-icon-close {
    color: #666666 !important;
  }
  .el-icon-close:hover {
    background-color: #eeeeee !important;
  }
  .default-tags .el-icon-close {
    display: none !important;
  }
  .active-tags {
    background: none !important;
    color: rgba(56, 56, 56, 1) !important;
    border: none !important;
    //border-left: 4px solid $bgColor !important;
  }
  .active-tags .el-icon-close {
    color: rgba(56, 56, 56, 1) !important;
  }
}


</style>
