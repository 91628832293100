<template>
  <div id="opInput">
    <el-input
        :show-password="password"
        v-model="currentValue"
        :placeholder="placeholder"
        @change="upValue"
        :maxlength="maxLen"
        @blur="blurs"
        @focus="focus"
        :auto-complete="isComplete"
        :clearable="clearable"
        :disabled="disabled"
        :readonly="readonly"
        :autosize="autosize"
        :type="type"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: "opInput",
  data() {
    return {
      initValue: this.value
    }
  },
  props: {
    maxLen:{
      type: Number,
      default:50
    },
    value: {
      // type: String,
      // default: ''
    },
    isComplete:{
      type: String,
      default:'new-password'
    },
    password: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    autosize: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    },
    focus: {
      type: Function,
      default: () => {
      }
    },
  },
  methods: {
    upValue(value) {
      this.$emit('change', value);
    },
    blurs() {
      this.$emit('blur',this.value)
    }
  },
  computed: {
    currentValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('update:diff', value !== this.initValue ? 1 : 0);
        this.$emit('input', value);
      }
    }
  }
}
</script>
