<template>
  <div class="applicationLog">
    <search-form
        @clear="clear"
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <!--表格-->
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :params="listParams">
    </basic-table-pager>
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import BasicButton from '../../components/basic/basicButton';

export default {
  components: {SearchForm, BasicTablePager,BasicButton},
  inject:['reload'],
  data() {
    return {
      searchData: {
        startTime: new Date(new Date().setMonth(new Date().getMonth() -1)).Format("yyyy-MM-dd"),
        endTime: new Date().Format("yyyy-MM-dd"),
      },
      searchForm: [
        {
          type: 'DateRange90',
          label: '时间范围',
          labelWidth: '40px',
          width: '130px',
          prop: ['startTime','endTime'],
          id: 1
        },
        {
          type:'Button',
          title:'重置',
          class: 'reset',
          id:2,
        },
      ],
      tableKey: [
        {
          name: '用户账号',
          value: 'accountNo',
        },
        {
          name: '用户姓名',
          value: 'userName',
        },
        {
          name: '事件',
          value: 'event',
        },
        {
          name: '异常',
          value: 'errorInfo',
        },
        {
          name: '操作时间',
          value: 'operationTime',
          formatVal: (row, column, cellValue) => {
            return new Date(cellValue).Format('yyyy-MM-dd hh:mm:ss')
          }
        }
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryClientLogList',
        isNum:true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page={pageNum: '', pageSize: ''};
  },
  methods: {
    clear(){
      this.reload();
    },
    addClass ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 3&&row.event==='登录失败') {
        return 'confirm'
      }
    },
  }
}
</script>

<style lang="scss">
.applicationLog {
  width:100%;
  min-width: 1070px;
  font-size: 14px;
  $black: #323C47;
  $gray: #A7ADB3;
  $placeHolder: #D8DAE0;
  color: $black;
  .tabPage .confirm .cell {
    color: rgba(255, 0, 0, 1);
  }
}
</style>
