<template>
  <div class="addDiv">
    <div class="addBtn" @click="addFunc">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "addButton",
  props: {
    title: {
      type: String,
      default: '白名单'
    },
    className: {
      type: String,
      default: ''
    },
    addFunc: {
      type: Function,
      default: () => {
      }
    },
  }
}
</script>

<style scoped lang="scss">
$gray: #A7ADB3;
.addDiv {
  display: flex;
  align-items: center;
  .addBtn {
    display: flex;
    font-size: 16px;
    color: rgba(39, 72, 102, 1);
    justify-content: center;
    align-items: center;
    opacity: 1;
    background: $white;
    width: 96px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid rgba(39, 72, 102, 1);
    cursor: pointer;
  }
}
</style>
