<template>
  <div class="select-multi">
    <el-cascader
        ref="casc"
        :popper-class="popper"
        v-model="values"
        v-autoH="dealAuto"
        :options="options"
        separator=" / "
        @change="handleChange"
        @expand-change="expand"
        @visible-change="visible">
    </el-cascader>
  </div>
</template>

<script>

export default {
  props: {
    obj: {
      type: Object,
      default() {
        return {}
      }
    },
    value: {},
    autoH: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(v) {
      if (!v) {
        this.values = [null,null,null];
      }
    }
  },
  computed: {
    popper() { // 自动高度处理，是通过在父元素上添加特殊的类名来实现的
      return (this.autoH ? "autoH " : "") + "select-multi-downArea";
    }
  },
  data() {
    return {
      values: [null,null,null],
      options: [],
    };
  },
  directives: {
    autoH: { // 每次控件更新（即触发一次选择联动），都触发自动高度的设置
      update: function (el, binding) {
        binding.value(el);
      }
    }
  },
  created(){
    //this.options=[];
    this.$post(this.obj.url, {data: {}}).then((res) => {
      let regionList = res.data.body.regionList;
      //console.log(regionList);
      //建筑
      let buildingMap = res.data.body.buildingMap;
      buildingMap = JSON.parse(JSON.stringify(buildingMap).replace(/name/g, 'label').replace(/id/g, 'value'));
      //console.log(buildingMap);
      //楼层
      let floorMap = res.data.body.floorMap;
      floorMap = JSON.parse(JSON.stringify(floorMap).replace(/name/g, 'label').replace(/id/g, 'value'));
      //console.log(floorMap);
      this.options.push({
        label: "全部区域",
        value: null,
        children: [{
          label: '全部楼',
          value: null,
          children: [{
            label: '全部楼层',
            value: null,
          }]
        }]
      });
      regionList.forEach(item => {
        let obj = {};
        obj.label = item.name;
        obj.value = item.id;
        obj.children = buildingMap[item.id]||[];
        obj.children.forEach(item1 => {
          item1.children = floorMap[item1.value] || [];
        })
        this.options.push(obj);
      })
      //console.log(this.options);
      // 赋值后，根据初始默认值，自动通过代码触发父节点的点击事件，展开对应的子节点
      /* this.$nextTick(() => {
        let lists = document.querySelector('.select-multi-downArea .el-scrollbar__view.el-cascader-menu__list');
        for (let i = 0; i < this.options.length; i++) {
          let o = this.options[i];
          if (o.value === this.values[0]) {
            lists.childNodes[i].click();
            break;
          }
        }
      });*/
    }).catch(() => {

    });
  },
  methods: {
    dealAuto() { // 选择自动高度，将左侧父节点区域的高度限制级滚动样式去掉
      if (!this.autoH) return;
      let dom = document.querySelector('.select-multi-downArea .el-scrollbar__wrap');
      if (!dom) return;
      dom.style.height = "auto";
      dom.style.marginBottom = "0";
      dom.style.overflowX = "auto";
    },
    // 根据左侧父节点区域的高度，设置右侧子节点区域的高度（与左侧保持一致，但可以滚动）
    expand() {
      this.resetPos();
      if (this.autoH) this.$nextTick(() => {
        let dom = document.querySelectorAll('.select-multi-downArea .el-cascader-menu');
        if (dom.length < 2) return;
        let ch = dom[0].childNodes[0].clientHeight + 'px';
        dom.forEach((o, i) => {
          if (i && o.childNodes[0]) o.childNodes[0].style.height = ch;
        });
      });
    },
    resetPos() { // 组件原来的竖滚动条有显示bug，此处是修复的代码
      this.$nextTick(() => {
        let cssStr = '.select-multi-downArea .el-cascader-menu:last-child ',
            wrap = document.querySelector(cssStr + '.el-cascader-menu__wrap'),
            thumb = document.querySelector('.is-vertical .el-scrollbar__thumb');
        if (wrap) wrap.scrollTop = 0;
        if (thumb) {
          thumb.style.transform = 'translateX(0%)';
          thumb.style.transform = 'translateY(0%)';
        }
      });
    },
    // 组件发生change时的处理，将值反馈给父组件
    handleChange([first, second, third]) {
      this.$emit("input", first.toString());
      this.$emit("update:plus", second.toString());
      this.$emit("update:endVal", third.toString());
    },
    // 从接口获取数据，分为多级给组件赋值
    visible(value) {
     /* if (value) {
        this.options = [
          {
            label: "全部区域",
            value: null,
            children: [{
              value: null,
              label: '全部楼',
              children: [{
                value: null,
                label: '全部楼层',
              }]
            }]
          }
        ];
        this.$post(this.obj.url, {data: {}}).then((res) => {
          let regionList = res.data.body.regionList;
          //console.log(regionList);
          //建筑
          let buildingMap = res.data.body.buildingMap;
          buildingMap = JSON.parse(JSON.stringify(buildingMap).replace(/name/g, 'label').replace(/id/g, 'value'));
          //console.log(buildingMap);
          //楼层
          let floorMap = res.data.body.floorMap;
          floorMap = JSON.parse(JSON.stringify(floorMap).replace(/name/g, 'label').replace(/id/g, 'value'));
          //console.log(floorMap);
          regionList.forEach(item => {
            let obj = {};
            obj.label = item.name;
            obj.value = item.id;
            obj.children = buildingMap[item.id];
            obj.children.forEach(item1 => {
              //console.log(item1);
              item1.children = floorMap[item1.value] || [];
            })
            this.options.push(obj);
          })
          //console.log(this.options);
          // 赋值后，根据初始默认值，自动通过代码触发父节点的点击事件，展开对应的子节点
          /!* this.$nextTick(() => {
            let lists = document.querySelector('.select-multi-downArea .el-scrollbar__view.el-cascader-menu__list');
            for (let i = 0; i < this.options.length; i++) {
              let o = this.options[i];
              if (o.value === this.values[0]) {
                lists.childNodes[i].click();
                break;
              }
            }
          });*!/
        }).catch(() => {
          this.$emit("update:dialogVisible", false);
          this.$_info({
            type: "error",
            message: "获取数据失败"
          });
        });
      }*/
    }
  }
};
</script>

<style lang="scss" scoped>
.select-multi > > > {
  display: inline-block;


  .el-input__inner:hover {
    border-color: $borderColor !important;
  }

  .el-input__inner:focus {
    border-color: $btnA !important;
  }

  .el-cascader__label span {
    margin: 0 3px;
  }

  .el-icon-arrow-down {
    color: $normal;
    font-size: 15px !important;
  }
}
</style>
<style lang="scss">
.select-multi-downArea {
  .in-active-path .el-cascader-node__label, .is-active {
    color: $btnA;
  }

  .el-cascader-menu {
    min-height: 206px;

    .el-scrollbar__wrap {
      height: 240px;
    }
  }
  .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
    color: $btnA !important;
  }

  &.autoH .el-cascader-menu__wrap {
    overflow-x: auto;
  }
}
</style>
