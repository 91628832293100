import Vue from 'vue';
import VueRouter from 'vue-router';
import MainPage from '../views/mainPage.vue';
import store from "../store";
import base from "../utils/base.js";
import customerManage from "../views/customerManage/customerManage.vue";
import bunkManage from "../views/bunkManage/bunkManage.vue";
import rentSaleStatus from "../views/rentSaleStatus/rentSaleStatus.vue";
import screen from "../views/contentManage/screen.vue";
import indoor from "../views/contentManage/indoor.vue";
import outdoor from "../views/contentManage/outdoor.vue";
import planInfo from "../views/contentManage/planInfo.vue";
import operationLog from "../views/operationLog/operationLog.vue";
import applicationLog from "../views/operationLog/applicationLog.vue";
import highLightsManage from "../views/highLightsManage/highLightsManage.vue";
import massifManage from "../views/massifManage/massifManage.vue";
import franchiserVisit from "../views/dataStatistics/franchiserVisit.vue";
import customerVisit from "../views/dataStatistics/customerVisit.vue";
import authorization from "../views/authorization/authorization.vue";
import staffManage from "@/views/staffManage/staffManage";

import tools from "@/utils/tools";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}


const routes = [
  {
    path: "/login",
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/',
    component: MainPage,
    redirect: "/login",
    children: [
      {
        path: "/customerManage",
        component: customerManage,
      },
      {
        path: "/massifManage",
        component: massifManage,
      },
      {
        path: "/bunkManage",
        component: bunkManage,
      },
      {
        path: "/highLightsManage",
        component: highLightsManage,
      },
      {
        path: "/rentSaleStatus",
        component: rentSaleStatus,
      },
      {
        path: "/screen",
        component: screen,
      },
      {
        path: "/indoor",
        component: indoor,
      },
      {
        path: "/outdoor",
        component: outdoor,
      },
      {
        path: "/planInfo",
        component: planInfo,
      },
      {
        path:"/franchiserVisit",
        component:franchiserVisit,
      },
      {
        path:"/customerVisit",
        component:customerVisit,
      },
      {
        path:"/operationLog",
        component:operationLog,
      },
      {
        path:"/applicationLog",
        component:applicationLog,
      },
      {
        path:"/authorization",
        component:authorization,
      },
      {
        path: "/staffManage",
        component: staffManage,
      }
    ]
  }
]

const router = new VueRouter({
  routes
});

//全局前置守卫
router.beforeEach((to, from, next) => {
  if (to.path !== "/login") {
    if(to.path === "/customerManage" && from.path === "/login"){
      //重置头部标签
      store.dispatch("resetTags");
      //清空头部标签缓存信息
      localStorage.setItem(base.tags, "");
    }
    //判断是否缓存用户信息token
    if (tools.getCookie('accessToken')){

    } else {
      //打开登录页面
      next("/login");
    }
  }
  next();
});

export default router
