<template>
  <el-tree
      :data="treeData"
      :props="defaultProps"
      :load="loadNode"
      lazy
      show-checkbox
      node-key="orgSysId"
      ref="tree"
      accordion
      :check-strictly="true"
      :default-checked-keys="defaultCheckedKeys"
      @check-change="handleCheckChange"
  ></el-tree>
</template>

<script>
export default {
  data(){
    return{
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'curName',
        /*isLeaf: 'leaf'*/
        isLeaf: (data, node) => {
          //根据后台返回的数据判断是否还有下级 是否显示箭头图标
          //el-tree懒加载无子级数据时去掉下拉箭头
          //console.log(data);
          if(data.flag === false) {
            return true
          }
        },
      },
      defaultCheckedKeys:this.value,
    }
  },
  props:{
    value: Array
  },
  methods: {
    loadNode(node, resolve) {
      if(!node){
        return false
      }
      this.$post('/private/o-wms/v1/staff/getHklcnOrgDetail',{data:{orgSysId:node.data.orgSysId}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          if(res.data.body.userAndSubOrgList.length){
            const currentChildren=res.data.body.userAndSubOrgList;
            resolve(
                currentChildren.map((val)=>{
                  return {
                    orgSysId:val.orgSysId||val.userSysId,
                    curName:val.orgCurName||(val.userName?val.userName+'（'+val.userAccount+'）':val.userAccount),
                    info:val.orgName||(val.userName?val.userName+'（'+val.userAccount+'）':val.userAccount),
                    type:val.dataType,
                    flag:val.flag||false
                  }
                })
            )
          }else{
            resolve([]);
          }
        }
      }).catch(() => {});
    },
    handleCheckChange(data, checked, node) {
      //console.log(this.$refs.tree.store);
      /*let parentArr = this.$refs.tree.getHalfCheckedKeys();
      let childeArr = this.$refs.tree.getCheckedKeys();
      let arr = childeArr.concat(parentArr);*/
      let parentArr = this.$refs.tree.getHalfCheckedNodes();
      let childeArr = this.$refs.tree.getCheckedNodes();
      let arr = childeArr.concat(parentArr);
      this.$emit('input',arr)
    },
  }
};
</script>
