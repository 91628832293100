<template>
    <div id="searchInput1">
        <el-autocomplete
                v-model="input23"
                :placeholder="placeholder"
                :trigger-on-focus="false"
                :fetch-suggestions="querySearchAsync"
                v-loadmore="loadMore"
                @select="handleSelect">
            <i slot="suffix"
               class="el-input__icon el-icon-search"
               @click="sendContent">
            </i>
        </el-autocomplete>
    </div>
</template>

<script>
    export default {
        name: "search",
        props: {
            placeholder: {
                type: String,
                default: '请输入内容'
            },
            value: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                input23: this.value,
                restaurants: [],
                timeout: null,
                findData: {
                    data: {keyWord: '',lastId:''},
                },
                //总页数
                totalPage: '',
                results: [],
                id:''
            }
        },
        watch: {
            value(v) {
                this.input23 = v;
            }
        },
        methods: {
            sendContent() {
                this.$emit('input', this.input23);
                this.$emit('choose',this.id);
            },
            searchDatas(cb) {
                this.findData.data.keyWord = this.input23;
                this.findData.data.lastId='0';
                this.$post('/operator/private/v1/business/matchShopByName', this.findData).then(res => {
                    if (res.data.rtnCode === '200') {
                        this.restaurants = res.data.body.data.shopList||[];
                        this.totalPage = res.data.body.data.excess;
                        this.results = [];
                        if (this.restaurants.length) {
                            this.restaurants.forEach(item => {
                                this.results.push({value: item.shopName,shopId:item.shopId+'',poiId:item.poiId+''})
                            });
                            this.findData.data.lastId=this.results[this.results.length-1].shopId;
                            clearTimeout(this.timeout);
                            this.timeout = setTimeout(() => {
                                cb(this.results);
                            }, 1000);
                        } else {
                            this.timeout = setTimeout(() => {
                                cb([]);
                            }, 1000);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                });
            },
            querySearchAsync(queryString, cb) {
                if (queryString) {
                    this.searchDatas(cb);
                }
            },
            handleSelect(item) {
                this.id=item.poiId;

            },
            loadMore() {
                if (this.totalPage) {
                    this.$post('/operator/private/v1/business/matchShopByName', this.findData).then(res => {
                        if (res.data.rtnCode === '200' && res.data.body.data) {
                            this.totalPage = res.data.body.data.excess;
                            res.data.body.data.shopList.forEach(item => {
                                this.results.push({value: item.shopName,shopId:item.shopId+'',poiId:item.poiId+''})
                            });
                            this.findData.data.lastId=this.results[this.results.length-1].shopId;
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }
            }
        }
    }
</script>

<style lang="scss">
    #searchInput1{
        .el-icon-search {
            cursor: pointer;
        }
    }

</style>
