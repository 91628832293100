import { render, staticRenderFns } from "./indoor.vue?vue&type=template&id=56c204a3"
import script from "./indoor.vue?vue&type=script&lang=js"
export * from "./indoor.vue?vue&type=script&lang=js"
import style0 from "./indoor.vue?vue&type=style&index=0&id=56c204a3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports