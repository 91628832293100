<template>
    <div id="select">
        <el-select
                v-cloak
                v-model="isValue"
                :placeholder="placeholder"
                @change="handleInput"
                :disabled="disabled"
                :popper-class="popper"
                :popper-append-to-body="toBody"
                value=""
                @visible-change="visibleChange">
            <el-option
                    v-for="item in option"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name: 'selectInput',
        props: {
            placeholder: {
                type: String,
                default: '请选择'
            },
            option: {
                type: Array
            }, //选项
            check: {
                // type:String,
                // default: ''
            }, //默认选项
            disabled: {
                type: Boolean,
                default: false
            },
            popClass: {
                type: String,
                default: "el-custom-drop-poper"
            },
            autoH: {
                type: Boolean,
                default: false
            },
            toBody: {
                type: Boolean,
                default: false
            }
        },
        model: {
            prop: 'check',
            event: 'change'
        },
        computed: {
            isValue: {
                get() {
                    return this.check;
                },
                set(v) {
                    this.$emit("check", v);
                }
            },
            popper() {
                return (this.autoH ? "autoH " : "") + this.popClass;
            }
        },
        data() {
            return {
                //isValue: this.check
            }
        },
        methods: {
            visibleChange(status) {
                if (status) {
                    this.$emit('visibleChange', status)
                } else {
                    this.$emit('visibleChangeNot')
                }
            },
            handleInput(value) {
                this.$emit('change', value)
            }
        }
    }
</script>

<style lang="scss">
    #select {
        display: inline-block;
        [v-cloak] {
            display: none;
        }
        .el-input {
            overflow: hidden;
            &.is-focus .el-input__inner {
                border-color: $btnA;
            }
        }
        .el-select {
            display: block;
        }
        .el-icon-arrow-up {
            margin-top: 1px;
            width: 34px;
            height: 38px;
            line-height: 38px;
            cursor: pointer;
            color: $normal;
            font-size: 15px;
        }
        .el-input__suffix {
            right: 1px;
        }
    }

</style>
