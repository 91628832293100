<template>
  <div class="select-multi">
    <!-- v-model="selectedValues"   -->
    <el-cascader
        clearable
        :options="options"
        :props="props"
        placeholder="请选择组织"
        ref="myCascader"
        @change="handleChange"
        @visible-change="blurDept">
    </el-cascader>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options:[],
      props: {
        value: 'orgSysId',
        label: 'curName',
        children: 'children',
        checkStrictly: true,
        lazy:true,
        lazyLoad:this.cascaderLazyLoad
      },
      checkVal:''
    };
  },
  /*props: {
    value: {
      // type: String,
      // default: ''
    },
  },*/
  /*computed: {
    selectedValues: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        value=value.at(-1);
        this.$emit('input', value);
      }
    }
  },*/
  methods: {
    cascaderLazyLoad(node,resolve){
      if(node.noData) {
        return resolve([])
      }
      if(!node){
        return false
      }
      this.$post('/private/o-wms/v1/staff/getHklcnOrgSimple',{data:{orgSysId:node.value}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          if(res.data.body.subOrgList.length){
            const currentChildren=res.data.body.subOrgList;
            resolve(currentChildren.map((val)=>{
              return {
                orgSysId:val.orgSysId,
                name:val.name,
                curName:val.curName,
                flag:val.flag
              }
            }))
            const panelRefs = this.$refs.myCascader.$refs.panel;
            this.$nextTick(() =>{
              node?.children?.forEach((o) => {
                if(o?.data?.flag === false){
                  o.noData = true;
                  panelRefs.lazyLoad(o)
                }
              })
            })
          }else{
            resolve([]);
          }
        }
      }).catch(() => {});
    },
    handleChange(value) {
      value=value.at(-1);
      this.$emit('input', value);
      this.checkVal=value;
      const panelRefs = this.$refs.myCascader.$refs.panel;
      if (panelRefs.activePath.length !== 0) {
        panelRefs.activePath.forEach(item => {
          if (item.children.length === 0) {
            panelRefs.lazyLoad(panelRefs.getCheckedNodes()[0])
          }
        })
      }
    },
    blurDept(val){
      if(val&&!this.checkVal){
        const _cascader = this.$refs.myCascader
        if (_cascader) {
          _cascader.$refs.panel.activePath = []
          _cascader.$refs.panel.syncActivePath()
        }
      }
    },
  },
  created(){}
};
</script>
<style lang="scss">
.select-multi > > > {
  display: inline-block;


  .el-input__inner:hover {
    border-color: $borderColor !important;
  }

  .el-input__inner:focus {
    border-color: $btnA !important;
  }

  .el-cascader__label span {
    margin: 0 3px;
  }

  .el-icon-arrow-down {
    color: $normal;
    font-size: 15px !important;
  }
}
</style>
