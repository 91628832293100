<template>
  <div class="monthYear">
    <radio-box :radioAry="timeTypes"
               @input="timeTypesChange"
               v-model="timeType">
    </radio-box>
    <div class="monthDiv" v-show="timeType==='1'">
      <el-date-picker
          v-model="checkMonth"
          type="month"
          ref="monthPicker"
          @change="monthChange"
          @blur="monthBlur"
          value-format="yyyy-MM"
          placeholder="选择月">
      </el-date-picker>
    </div>
    <div class="yearDiv" v-show="timeType==='2'">
      <el-date-picker
          v-model="checkYear"
          type="year"
          ref="yearPicker"
          @change="yearChange"
          @blur="yearBlur"
          value-format="yyyy"
          placeholder="选择年">
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import RadioBox from '../../components/basic/radioBox';
import {getDateTime} from "@/utils/tools";

export default {
  name: "monthYear",
  components: {RadioBox},
  props: {
    monthVal:{
      type: String,
      default() {
        return '';
      }
    },
    yearVal:{
      type: String,
      default() {
        return '';
      }
    },
  },
  data() {
    return {
      timeTypes: [
        {label: '月', value: '1'},
        {label: '年', value: '2'}
      ],
      timeType:'1',
    }
  },
  computed: {
    checkMonth: {
      get() {
        return this.monthVal;
      },
      set(v) {
        this.$emit("update:monthVal",v);
        this.$emit("update:monthValMonth",v.split('-')[1]);
        this.$emit("update:monthValYear",v.split('-')[0]);
      }
    },
    checkYear: {
      get() {
        return this.yearVal;
      },
      set(v) {
        this.$emit("update:yearVal",v);
      }
    },
  },
  created(){

  },
  methods:{
    timeTypesChange(val){
      if(val==='1'){
        this.checkYear=this.monthVal.split('-')[0];
        this.checkMonth=getDateTime('month');
      }else{
        this.checkYear=String(getDateTime('year'));
        this.$emit("update:monthValMonth",'');
      }
    },
    monthChange(val){
      //this.checkYear='';
    },
    yearChange(val){
      //this.checkMonth='';
    },
    monthBlur(){
      //this.$refs.monthPicker.hidePicker();
    },
    yearBlur(){
      //this.$refs.yearPicker.hidePicker();
    },
  }
}

</script>

<style lang="scss">
.monthYear{
  #radioBox{
    display: inline-block;
  }
  .monthDiv,.yearDiv{
    display: inline-block;
    margin-left: 20px;
  }
}

</style>
