<template>
  <div id="searchInput">
      <el-input
              @input="input"
              @blur="blur"
              :clearable="clearable"
              :placeholder="placeholder"
              v-model="input23">
          <i v-if="btn" slot="suffix" class="el-input__icon el-icon-search" @click="sendContent"></i>
      </el-input>
      <!--子：{{input23}}-->
  </div>
</template>

<script>
    export default {
        name: "searchInput",
        props:{
          clearable:{
            type: Boolean,
            default: true
          },
            placeholder:{
                type:String,
                default:'请输入内容'
            }, //提示
            value:{
                type: String,
                default: ''
            },  //默认值
            type:{
                type: String,
                default: "0"
            },
            btn: {
                type: Boolean,
                default: true
            },
            searchFun: {
                type: Function
            }
        },
        data() {
            return {
                input23: this.value,
            }
        },
        watch: {
            value(v) {
              this.input23 = v;
            }
        },
        methods: {
            sendContent () {
                this.$emit('input',this.input23);
                this.searchFun && this.searchFun();
            },
            blur() {  // 备用处理
                if(this.type === "1") {
                    let timer = null;
                    timer = setTimeout(()=>{
                        clearTimeout(timer);
                        timer = null;
                        this.input23 = this.value;
                    },200);
                }
            },
            input(v) {
                if(this.btn) return;
                this.$emit('input',v);
            }
        }
    }
</script>

<style lang="scss">
    #searchInput {
      .el-input.el-input--suffix .el-input__inner:focus{
         border-color: $btnA;
      }
      .el-icon-search {
            width: 40px;
            cursor: pointer;
            color: $iconColor;
            font-size: 15px;
        }
        .el-input__suffix {
           right: 1px;
        }
      .el-input_clear{
            text-align: left;
      }
    }
</style>
