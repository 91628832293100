<template>
  <div class="bunkList">
    <search-form
        @clear="clear"
        :searchData="searchData"
        :searchForm="searchForm">
    </search-form>
    <div class="timeOrder" @click="order">
      <span class="timeTitle">{{searchData.orderFlag?'时间正序':'时间倒序'}}</span>
      <span class="timeIcon"></span>
    </div>
    <!--表格-->
    <basic-table-pager :tableKey="tableKey"
                       :tablep="tablep"
                       :add-class="addClass"
                       :cellClick="cellClick"
                       :params="listParams">
      <el-table-column width="150px"
                       label="操作">
        <template v-slot="scope">
          <basic-button :type="'text'"
                        :title="'编辑'"
                        @click.native="editBunk(scope.row)">
          </basic-button>
        </template>
      </el-table-column>
    </basic-table-pager>
    <comfort-dia v-if="editDetDia"
                 :title="editDetTitle"
                 ref="dia"
                 :dialogVisible="editDetDia"
                 :isTextArea="false"
                 :isCancel="editDetTitle==='铺位详情'?false:true"
                 :cancelTitle="'取消'"
                 :submitTitle="editDetTitle==='铺位详情'?'关闭':'确认'"
                 :cancel="editCancel"
                 :submit="editDetTitle==='铺位详情'?detSubmit:editSubmit"
                 :width="editDetWidth">
      <div slot="header"
         class="bunkEdit">
        <div class="basicInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">基础信息</span>
          </div>
          <ul class="basicInfoUl">
            <li class="basicInfoLi">
              <span class="liLabel">铺位编号：</span>
              <span class="liVal">{{bunkInfo.bunkNo}}</span>
            </li>
            <li class="basicInfoLi">
              <span class="liLabel">所属地块：</span>
              <span class="liVal">{{bunkInfo.regionName}}</span>
            </li>
            <li class="basicInfoLi">
              <span class="liLabel">所属楼号：</span>
              <span class="liVal">{{bunkInfo.buildingName}}</span>
            </li>
            <li class="basicInfoLi">
              <span class="liLabel">所属楼层：</span>
              <span class="liVal">{{bunkInfo.floorName}}</span>
            </li>
          </ul>
        </div>
        <div class="bunkInfo">
          <div class="infoLabel">
            <span class="infoIcon"></span>
            <span class="infoTitle">铺位属性</span>
          </div>
          <el-form v-if="editDetTitle==='编辑铺位'"
                   class="editForm"
                   id="editForm"
                   :model="editForm"
                   :rules="editFormRules"
                   autocomplete="on"
                   ref="addForm">
            <el-form-item label="商业业态"
                          prop="busyType">
              <select-input v-model="editForm.busyType"
                            :option="busyTypes"
                            placeholder="请选择">
              </select-input>
            </el-form-item>
            <el-form-item label="铺位朝向"
                          prop="bunkArea">
              <select-input v-model="editForm.bunkArea"
                            :option="busyTypes"
                            placeholder="请选择">
              </select-input>
            </el-form-item>
            <el-form-item label="铺位层高"
                          prop="height">
              <op-input v-model="editForm.height"
                            placeholder="请输入（m）">
              </op-input>
            </el-form-item>
            <el-form-item label="铺位进深"
                          prop="depth">
              <op-input v-model="editForm.depth"
                            placeholder="请输入（m）">
              </op-input>
            </el-form-item>
            <el-form-item label="门头信息"
                          prop="wide">
              <op-input v-model="editForm.wide"
                        placeholder="门头长（m）">
              </op-input>
              <op-input v-model="editForm.wide"
                        placeholder="门头宽（m）">
              </op-input>
              <op-input v-model="editForm.wide"
                        placeholder="门头高（m）">
              </op-input>
            </el-form-item>
            <el-form-item label="铺位面积"
                          prop="depth">
              <op-input v-model="editForm.depth"
                        placeholder="请输入（m）">
              </op-input>
            </el-form-item>
            <check-box-ary :tempAppInit.sync="facilities"
                           :disabled="disabled"
                           :cities="checkboxList">
            </check-box-ary>
            <el-form-item label="主力铺位"
                          class="gender"
                          prop="gender">
              <radio-box :radioAry="genders"
                         @input="genderChange"
                         v-model="editForm.gender"></radio-box>
            </el-form-item>
          </el-form>
          <el-form v-if="editDetTitle==='铺位详情'"
                   class="detForm">
            <el-form-item label="业态：">
              <div>{{bunkInfo.businessTypeName}}</div>
            </el-form-item>
            <el-form-item label="朝向：">
              <div>{{bunkInfo.faceTo}}</div>
            </el-form-item>
            <el-form-item label="建筑面积：">
              <div>{{bunkInfo.bunkArea}}</div>
            </el-form-item>
            <el-form-item label="门头展面：">
              <div>{{bunkInfo.doorHeaderArea}}</div>
            </el-form-item>
            <el-form-item label="铺位层高：">
              <div>{{bunkInfo.height}}</div>
            </el-form-item>
            <el-form-item label="铺位进深：">
              <div>{{bunkInfo.depth}}</div>
            </el-form-item>
            <check-box-ary :tempAppInit.sync="facilities"
                           :disabled="disabled"
                           :cities="checkboxList">
            </check-box-ary>
            <el-form-item label="主力铺位："
                          class="gender">
              <radio-box :radioAry="specialBunks"
                         :disabled="disabled"
                         @input="genderChange"
                         v-model="bunkInfo.specialBunk"></radio-box>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </comfort-dia>
  </div>
</template>

<script>
import SearchForm from '../../components/formSearch';
import BasicTablePager from '../../components/basicTablePager';
import ComfortDia from '../../components/basic/comfortDialog';
import OpInput from '../../components/basic/opInput';
import SelectInput from '../../components/basic/selectInput';
import RadioBox from '../../components/basic/radioBox';
import BasicButton from '../../components/basic/basicButton';
import CheckBoxAry from '../../components/basic/checkBoxAry';

export default {
  components: {SearchForm, BasicTablePager,ComfortDia,OpInput,RadioBox,BasicButton,CheckBoxAry,SelectInput},
  data() {
    return {
      disabled:false,
      // 给排水 供电 煤气 排烟
      checkboxList: [{id: '给排水', name: '给排水'},{id: '煤气', name: '煤气'}, {id: '排烟', name: '排烟'}],
      specialBunks: [{label: '是', value: 1}, {label: '否', value: 0}],
      //区域
      regionList:[],
      //建筑
      buildingMap:{},
      //楼层
      floorMap:{},

      editDetTitle:'',
      editDetDia:false,
      editDetWidth:'600px',
      bunkInfoObj:{},
      bunkInfo:{
        bunkId:'',
        bunkNo:'',
        regionName:'',
        buildingName:'',
        floorName:'',
        //业态
        businessTypeName:'',
        //朝向
        faceTo:'',
        //面积
        bunkArea:'',
        doorHeaderArea:'',
        height:'',
        depth:'',
        specialBunk:'',
      },
      facilities:[],
      busyTypes: [
        {
          label: "A1",
          value: "A1"
        },
        {
          label: "A2",
          value: "A2"
        },
        {
          label: "A3",
          value: "A3"
        },
      ],
      editForm:{
        bunkId:'',
        busyType:'' ,
        bunkArea:'',
        height:'',
        depth:'',
        wide:'',
        facilities:[],
        modifyTime:''
      },
      editFormRules:{
        busyType: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        bunkArea: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        height: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        depth: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        wide: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        gender: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
      },

      searchData: {
        //区域
        regionId:null,
        //楼
        buildingId:null,
        //楼层
        floorId:null,
        //商业业态
        businessTypeId:null,
        //铺位编号
        bunkNo:'',
        //时间倒序
        orderFlag:true,
      },
      searchForm: [
        {
          type: 'Multi',
          label: '铺位位置',
          labelWidth: '40px',
          prop: ["regionId", "buildingId","floorId"],
          placeholder: '请选择',
          config: {
            url: "/private/o-wms/v1/getAreaComboBox",
          },
          id:1
        },
        //编辑状态
        {
          type: 'MultiBusiness',
          label: '商业业态',
          labelWidth: '70px',
          prop: ["businessTypeId"],
          placeholder: '请选择',
          config: {
            url: "/private/o-wms/v1/getBusinessList",
          },
          id:2
        },
        //铺位编号
        {
          type:'SearchAccurate',
          label: '',
          placeholder:'请输入铺位编号',
          prop:'poiNo',
          id:3,
        },
        {
          type:'Button',
          class:'reset',
          title:'重置',
          id:4,
        },
      ],
      tableKey: [
        {
          name: '铺位编号',
          value: 'bunkNo',
        },
        {
          name: '业态',
          value: 'businessTypeName',
        },
        {
          name: '铺位位置',
          value: 'introduce',
        },
        {
          name: '建筑面积',
          value: 'bunkArea',
        },
        {
          name: '朝向',
          value: 'faceTo',
        },
        {
          name: '最后编辑时间',
          value: 'modifyTime',
          formatVal: (row, column, cellValue) => {
            return new Date(cellValue).Format('yyyy-MM-dd hh:mm:ss')
          }
        },
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryBunkList',
        isNum:true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
    }
  },
  created() {
    this.listParams.data = this.searchData;
    this.listParams.data.page={pageNum: '', pageSize: ''};
  },
  methods: {
    order(){
      this.searchData.orderFlag=!this.searchData.orderFlag;
    },
    genderChange(val) {
      if (val) {
        this.$refs.editForm.validateField('specialBunk');
      }
    },
    clear(){
      this.reload();
    },
    addClass({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 1) {
        return 'confirm'
      }
    },
    cellClick(row, column, cell, event) {
      if (column.label === '铺位编号') {
        this.editDetDia=true;
        this.editDetTitle='铺位详情';
        this.disabled=true;
        this.getBunkInfo(row.id,2);
      }
    },
    editCancel(){
      this.editDetDia=false;
      this.facilities=[];
    },
    editSubmit(){
      this.editForm.facilities=this.facilities.join(",");
        this.$post('/private/o-wms/v1/setBunkInfo', {data: this.editForm}).then((res) => {
          if (res.data.rtnCode === '10000') {
            this.$_info({
                  type: "success",
                  message: "编辑成功",
                },
                () => {
                  this.editDetDia = false;
                  this.searchData.refresh = !this.searchData.refresh;
                }
            );

          }
        }).catch(() => {
        });
    },
    getBunkInfo(id,type){
      this.$post('/private/o-wms/v1/getBunkInfo',{data:{bunkId:id}}).then((res) => {
        if (res.data.rtnCode === '10000') {
          this.bunkInfoObj=res.data.body.bunkInfo;
          this.bunkInfo.bunkNo=this.bunkInfoObj.bunkNo;
          this.bunkInfo.regionName=this.bunkInfoObj.regionName;
          this.bunkInfo.buildingName=this.bunkInfoObj.buildingName;
          this.bunkInfo.floorName=this.bunkInfoObj.floorName;
          if(type===1){
            this.editForm.bunkId=this.bunkInfoObj.bunkId;
            this.editForm.busyType=this.bunkInfoObj.busyType;
            this.editForm.bunkArea=this.bunkInfoObj.bunkArea;
            this.editForm.height=this.bunkInfoObj.height;
            this.editForm.depth=this.bunkInfoObj.depth;
            this.editForm.wide=this.bunkInfoObj.wide;
            this.editForm.modifyTime=this.bunkInfoObj.modifyTime;
            let facilityList=this.bunkInfoObj.facility.split(',');
            facilityList.forEach(item=>{
              this.facilities.push(item);
            })
          }else{
            this.bunkInfo.businessTypeName=this.bunkInfoObj.businessTypeName;
            //朝向
            this.bunkInfo.faceTo=this.bunkInfoObj.faceTo;
            //面积
            this.bunkInfo.bunkArea=this.bunkInfoObj.bunkArea;
            this.bunkInfo.doorHeaderArea=this.bunkInfoObj.doorHeaderArea;
            this.bunkInfo.height=this.bunkInfoObj.height;
            this.bunkInfo.depth=this.bunkInfoObj.depth;
            let facilityList=this.bunkInfoObj.facility.split(',');
            facilityList.forEach(item=>{
              this.facilities.push(item);
            })
            this.bunkInfo.specialBunk=this.bunkInfoObj.specialBunk;
          }
        }
      }).catch(() => {
      });
    },
    editBunk(val){
      //console.log(val);
      this.editDetTitle='编辑铺位';
      this.editDetDia=true;
      this.disabled=false;
      this.getBunkInfo(val.bunkId,1);
    },
    detSubmit(){
      this.editDetDia = false;
    }
  }
}
</script>

<style lang="scss">
.bunkList {
  min-width: 1070px;
  font-size: 14px;
  $black: #323C47;
  $gray: #A7ADB3;
  $placeHolder: #D8DAE0;
  color: $black;
  .timeOrder{
     text-align: right;
     height:18px;
     line-height: 18px;
     padding:0 35px 8px 0;
     cursor: pointer;
    .timeTitle{
      display: inline-block;
      font-size: 12px;
      height:18px;
      line-height: 18px;
      color: rgba(65, 93, 120, 1);
      vertical-align: top;
      margin-right: 7px;
    }
    .timeIcon{
      display: inline-block;
      vertical-align: middle;
      width:16px;
      height:16px;
      background: url('../../assets/orderIcon@2x.png') center center no-repeat;
      background-size: cover;
    }
  }
  .tabPage .confirm .cell{
    color:  rgba(87, 139, 189, 1);
    cursor: pointer;
  }
  .bunkEdit{
    .basicInfo,.bunkInfo{
      .infoLabel{
        height:29px;
        margin-bottom: 8px;
        .infoIcon{
          display: inline-block;
          width: 4px;
          height: 17px;
          line-height: 29px;
          border-radius: 1px;
          background: rgba(17, 53, 86, 1);
        }
        .infoTitle{
          display: inline-block;
          margin-left: 8px;
          font-size: 20px;
          height:29px;
          line-height: 29px;
          color: rgba(56, 56, 56, 1);
        }
      }
      .basicInfoUl{
        padding: 24px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        .basicInfoLi{
          display: inline-block;
          width:200px;
          margin-bottom: 8px;
          font-size: 18px;
          line-height: 27px;
          .liLabel{
            color: rgba(152, 152, 152, 1);
          }
          .liVal{
            color: rgba(56, 56, 56, 1);
          }
          &:nth-child(odd){
            margin-right: 99px;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .bunkInfo{
      margin-top: 24px;
    }
    .editForm,.detForm{
      padding: 24px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      .el-form-item{
        display: inline-block;
        width:229px;
        height:44px;
        line-height: 44px;
        font-size: 18px;
        color: rgba(152, 152, 152, 1)!important;
        &:nth-child(odd){
          margin-right: 43px;
        }
        &:nth-child(5){
          width:503px;
          #opInput{
            margin-right: 10px;
          }
        }
        .el-form-item__label {
          width: 92px!important;
          height: 44px;
          line-height: 44px;
          text-align: left;
          padding: 0;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        .el-form-item__content {
          height: 44px;
          #select,#opInput {
            display: inline-block;
            .el-input{
              width:127px;
              .el-input__inner {
                padding: 0 10px;
                width: 127px;
              }
            }
          }
          #radioBox{
            width:400px;
            .el-radio{
              margin-right: 90px;
              &:first-child{
                margin-left: 33px;
              }
              &:last-child{
                margin-right: 0;
              }
              .el-radio__label{
                font-size: 18px!important;
                color: rgba(56, 56, 56, 1);
              }
            }
          }
          div{
            height:44px;
            line-height: 44px;
            font-size: 18px;
            color: rgba(56, 56, 56, 1);
          }
        }
      }
      .gender{
        .el-form-item__label {
          width:140px!important;
        }
      }
    }
    .detForm{
      .el-form-item{
        border-bottom: 1px solid rgba(235, 235, 235, 1);
        &:last-child{
          border-bottom: none;
        }
        .el-form-item__label {
         width:108px!important;
        }
      }
    }
  }
}
</style>
