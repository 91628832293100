import store from "../store";
export default {
  checkTags(_path, _name) {
    var _hasTag = false;
    var _tags = store.state.tags;
    if (_tags && _tags.length > 0) {
      for (var i = 0; i < _tags.length; i++) {
        if (_tags[i].path === _path && _tags[i].name === _name) {
          //设置激活状态
          _tags[i].status = "active";
          //已有此页面标签
          _hasTag = true;
        } else {
          //重置激活状态
          _tags[i].status = "normal";
        }
      }
    }
    return _hasTag;
  },
  /* 点击标签 */
  selectTag(_tag) {
    var _path = "";
    if (_tag) {
      var _index = store.state.tags.indexOf(_tag);
      if (_index >= 0) {
        var _tags = store.state.tags;
        if (_tags && _tags.length > 0) {
          for (var i = 0; i < _tags.length; i++) {
            if (i ===_index) {
              //设置激活状态
              _tags[i].status = "active";
              _path = _tags[i].path;
              //已有此页面标签
            } else {
              //重置激活状态
              _tags[i].status = "normal";
            }
          }
        }
      }
    }
    return _path;
  },
  /* 重置 标签组的状态, 并返回需要激活的页面路径 */
  resetTags(_index) {
    var _path = "";
    var _tagIndex = "-1";
    if (_index >= 0) {
      var _tags = store.state.tags;
      var _hasAct = false;
      for (var i = 0; i < _tags.length; i++) {
        if (_tags[i].status === "active") {
          //删除的不是已激活的页面
          _hasAct = true;
        }
      }
      if (!_hasAct) {
        //没有激活的页面，说明删除的是激活页面，取前一个页面激活
        if (_index > 0) {
          _tags[_index - 1].status = "active";
          _path = _tags[_index - 1].path;
          _tagIndex = _tags[_index - 1].index;
        }
      }
    }
    return [_path, _tagIndex];
  },
};
