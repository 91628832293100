import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
//axios
import axios from 'axios';
//引入公共样式
import './utils/css/reset.css';
import './utils/css/global.scss';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { Message } from 'element-ui';
import ManageTags from "./utils/manage_tags.js";
//配置全局的api请求
import {post,del} from './api/index';
import './utils/tools';
import CustomBox from './components/CustomBox';
//引入图片查看器
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

// 处理笔记本系统默认系统比例为150%带来的布局影响
import { detectZoom } from '@/utils/detectZoom';
const m = detectZoom();
document.body.style.zoom = 100 / Number(m);
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  }
});

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$manageTags = ManageTags;
//将axios绑定到vue的原型上
Vue.prototype.$axios = axios;
Vue.prototype.$post=post;
Vue.prototype.$del=del;
Vue.prototype.$_confirm=CustomBox.confirm;
Vue.prototype.$_prompt=CustomBox.submit;
Vue.prototype.$_info=CustomBox.info;
Vue.prototype.$_warn=CustomBox.warn;

window.vm=new Vue({
  router,
  store,
  Message,
  render: h => h(App)
}).$mount('#app');
