<template>
    <div id="radioBox">
        <el-radio v-model="currentRadio"
                  :label="item.value"
                  v-for="item in radioAry"
                  :key="item.label"
                  :disabled="disabled">
          {{item.label}}
        </el-radio>
    </div>
</template>

<script>
    export default {
        name: "radioBox",
        props:{
            radioAry:{
                type:Array,
                default(){
                    return []
                }
            },
            value:{
                type:[String, Number],
                default:''
            },
            disabled: {
              type: Boolean,
              default: false
            }
        },
        data() {
            return {
                radio: ''
            }
        },
        computed:{
            currentRadio: {
                get:function() {
                    return this.value;
                },
                set:function(value) {
                    this.$emit('input', value);
                }
            }
        },
    }
</script>
