<template>
    <div class="el-select-custom">
        <el-select
                :class="{'el-select-custom-search':search}"
                v-model="select"
                popper-class="el-drop-custom"
                filterable
                remote
                :placeholder="placeholder"
                reserve-keyword
                :remote-method="remoteMethod"
                @visible-change="visible"
                @change="change"
                @blur="isFocus=false"
                @focus="isFocus=true"
                @input.native="input"
                :loading="loading"
                :disabled="disabled"
                :clearable="associate"
                :popper-append-to-body="false"
                v-scroll="bindFetch">
            <el-option
                    v-for="(item, i) in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :data-id="i">
                <span v-html="markText(item.label)"></span>
            </el-option>
        </el-select>
        <i v-if="search" class="el-input__icon el-icon-search custom" @click="doSearch"></i>
    </div>
</template>

<script>
    import {post, throwError} from '../../api/index';

    let timerR = null;

    /** author: meng */
    export default {
        props: {
            pageSize: {
                type: Number,
                default: 10
            },
            placeholder: {
                type: String,
                default: "请输入关键词"
            },
            disabled: {
                type: Boolean,
                default: false
            },
            value: {
                type: String
            },
            cbFun: {
                type: Function,
                default:() => {}
            },
            dealFun: {
                type: Function,
                default(data) {
                    return data;
                }
            },
            dataUrl: {
                type: String,
                default: ""
            },
            search: {
                type: Boolean,
                default: false
            },
            bindKey: {
                type: String
            },
            speKey: {
                type: String,
                default: ""
            },
            speValue: {
                type: String,
                default: ""
            },
            searchFun: {
                type: Function
            },
            associate: {
                type: Boolean,
                default: true
            },
            record: {
                type: Boolean,
                default: false
            },
            opts: {
                type: Array,
                default() {
                    return []
                }
            },
            lazy: {
                type: Boolean,
                default: false
            },
            isLastId: {
                type: Boolean,
                default: false
            },
            rtKey: {
                type: String,
                default: ""
            }
        },
        directives: {
            scroll: { // 在组件初始化时绑定逻辑，滚动触发查询
                bind: function(el, binding) {
                    binding.value();
                }
            }
        },
        computed: {
            select: {
                get() {
                    return this.value;
                },
                set(v) {
                    if(!v && !this.isFocus) this.$emit('update:syncKey', "");
                    this.$emit('input', v);
                }
            }
        },
        watch: {
            opts(v) {
                this.options = v;
            }
        },
        methods: {
            change(v) {
                this.cbFun(this.options.find(o => {
                    return o.value === v;
                }));
            },
            markText(v) { // 将下拉区域中的每项文本中，与输入完全相同的部分变色
                let rs = v.slice(v.toUpperCase().indexOf(this.tempValue.toUpperCase()), this.tempValue.length);
                return v.replace(rs, `<span class="el-drop-custom-mark">${ rs }</span>`);
            },
            doSearch() { // 触发查询，返回需要的值给父组件（很大可能不是输入的值）
                if(this.record && this.select) {
                    try {
                        let record = JSON.parse(localStorage.getItem("record")) || [];
                        record.push({
                            label: this.select,
                            value: this.select
                        });
                        while (record.length > 10) {
                            record.shift();
                        }
                        localStorage.setItem('record', JSON.stringify(record));
                    } catch (e) {
                        console.log(e)
                    }
                }
                // console.log(this.select)
                this.$emit('update:syncKey', this.select);
                this.searchFun && this.searchFun();
            },
            input(e) { // 输入时触发查询
                this.resetPos(true);
                this.remoteMethod(e.target.value);
            },
            // click(e) {
            //     this.$nextTick(() => {
            //         e.target.readOnly = false;
            //     });
            // },
            _debounce(wait, fn) { // 稀释输入的查询触发
                let timer = null;
                return function(){
                    let target = event.target;
                    if(timer){
                        clearTimeout(timer);
                        timer = null;
                    }
                    timer = setTimeout(function(){
                        fn(target);
                    }, wait)
                }
            },
            bindFetch() { // 下拉区域滚动查询的事件的绑定，通过计算滚动的高度与容器的高度
                if(!this.associate) return;
                this.$nextTick(() => {
                    let that = this;
                    document.querySelector('.el-drop-custom .el-select-dropdown__wrap').addEventListener('scroll', this._debounce(30, function(o){
                        const ch = o.childNodes[0].clientHeight;
                        if(Math.round(o.scrollTop + o.clientHeight) === ch && ch) {
                            that.fetchData();
                        }
                    }));
                });
            },
            fetchData(query) { // 获取下拉区域的数据
                let po = {},
                    qStr = query || (this.lazy ? this.tempValue : this.select);
                if(this.isLastId) { // 区分两种情况，正常page分页的，和使用lastId分页的
                    let slice = this.options.slice(-1); // 请求下次的数据需要上一次最后一条数据的id
                    po = {
                        data: {
                            [this.bindKey]: qStr,
                            [this.speKey]: !query && slice[0] && (slice[0][this.rtKey] + "") || "0"
                        }
                    };
                } else {
                    po = {
                        data: {
                            [this.bindKey]: qStr,
                            [this.speKey]: this.speValue
                        },
                        page: {
                            pageSize: this.pageSize,
                            pageNum: this.nowPage
                        }
                    }
                }
                post(this.dataUrl, po).then((res) => {
                    throwError.call(this, res.data);
                    let data = res?.data?.body?.data,
                        total = res?.data?.body?.page?.total;

                    let oData = this.dealFun(data);
                    if(this.nowPage === 1) {
                        this.options = oData;
                        this.$nextTick(() => {
                            this.resetPos(true);
                        })
                    } else {
                        this.options.push(...oData);
                    }
                    if(this.nowPage !== Math.ceil(total/this.pageSize) + 1) {
                        this.nowPage++;
                    }
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            },
            resetPos(query) { // 下拉框竖滚动条的显示bug的处理
                let wrap = document.querySelector('.el-drop-custom .el-select-dropdown__wrap'),
                    thumb = document.querySelector('.el-drop-custom .is-vertical .el-scrollbar__thumb');
                if(wrap && query) wrap.scrollTop = 0;
                if(thumb) thumb.style.transform = 'translateX(0%)';
            },
            remoteMethod(query) { // 输入时触发的逻辑
                if(!this.associate) {
                    this.select = query;
                    this.$nextTick(() => {
                        this.options = [];
                    });
                    this.loading = false;
                    return;
                }
                this.resetPos(query);
                if(event) {
                    this.cbFun && this.cbFun();
                    // this.select = '';
                    this.nowPage = 1;
                    if(!query) {
                        clearTimeout(timerR);
                        timerR = null;
                        this.options = [];
                        this.loading = false;
                    }
                }
                this.$nextTick(() => {

                    if (query !== '') {
                        this.loading = true;
                        if(this.lazy) {
                            this.tempValue = query;
                        } else {
                            this.select = query;
                        }
                        if(timerR){
                            clearTimeout(timerR);
                            timerR = null;
                        }
                        timerR = setTimeout(() => {
                            // let result = fn.apply();
                            // clearTimeout(timer);
                            // timer = null;
                            // return result;
                            this.fetchData(query)
                        }, 500);
                        // that.fetchData();
                        // this.fetchData(query)
                        // this._debounce(300, this.fetchData(query));
                    } else {
                        //this.select = query;
                        this.options = [];
                    }

                })
            },
            visible(sign) { // 关闭下拉框时清空下拉区域
                if(!sign) {
                    this.options = [];
                    this.nowPage = 1;
                }
            }
        },
        data() {
            return {
                isFocus: false,
                tempValue: "",
                obj: {},
                total: 0,
                nowPage: 1,
                options: [],
                loading: false
            }
        },
        components: {

        }
    }
</script>
<style lang="scss">
    .el-select-dropdown__wrap {
        height: 50px;
    }
    .el-select-custom {
        height: 40px;
        border: 1px solid $borderColor;
        border-radius: 4px;
        box-sizing: border-box;
        background-color: #FFF;
        .el-select-dropdown.el-popper.el-drop-custom{
            left: 0!important;
            top: 40px!important;
        }
        .el-select {
            width: 100%;
            border-radius: 4px;
            &.el-select-custom-search {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 40px);
                .el-input__suffix {
                    right: 0;
                }
            }
            .el-input__inner {
                height: 38px;
                border: none;
            }
        }
        .el-icon-search.custom {
            display: inline-block;
            width: 40px;
            height: 38px;
            background-color: #fff;
            cursor: pointer;
            color: $iconColor;
            font-size: 15px;
            border-radius: 4px;
        }
    }
    .el-drop-custom-value{
        float: right;
        color: #8492a6;
        font-size: 13px
    }
</style>