<template>
  <div class="planInfo">
    <tabs v-model="currentTab"
          @input="clickPane">
      <pane label="区位介绍"
            name="1">
        <div v-if="currentTab==='1'"
             class="paneContain">
          <basic-table-pager :tableKey="tableKey"
                             :tablep="tablep"
                             :params="listParams">
            <el-table-column width="150px"
                             label="操作">
              <template v-slot="scope">
                <basic-button :type="'text'"
                              :title="'编辑'"
                              @click.native="edit(scope.row)">
                </basic-button>
              </template>
            </el-table-column>
          </basic-table-pager>
        </div>
      </pane>
      <pane label="交通介绍"
            name="2">
        <div v-if="currentTab==='2'"
             class="paneContain">
          <basic-table-pager :tableKey="tableKey"
                             :tablep="tablep"
                             :params="listParams">
            <el-table-column width="150px"
                             label="操作">
              <template v-slot="scope">
                <basic-button :type="'text'"
                              :title="'编辑'"
                              @click.native="edit(scope.row)">
                </basic-button>
              </template>
            </el-table-column>
          </basic-table-pager>
        </div>
      </pane>
      <pane label="业态介绍"
            name="3">
        <div v-if="currentTab==='3'"
             class="paneContain">
          <basic-table-pager :tableKey="tableKey3"
                             :tablep="tablep"
                             :params="listParams">
            <el-table-column width="150px"
                             label="操作">
              <template v-slot="scope">
                <basic-button :type="'text'"
                              :title="'编辑'"
                              @click.native="edit(scope.row)">
                </basic-button>
              </template>
            </el-table-column>
          </basic-table-pager>
        </div>
      </pane>
      <pane label="地块介绍"
            name="4">
        <div v-if="currentTab==='4'"
             class="paneContain">
          <basic-table-pager :tableKey="tableKey"
                             :tablep="tablep"
                             :params="listParams">
            <el-table-column width="150px"
                             label="操作">
              <template v-slot="scope">
                <basic-button :type="'text'"
                              :title="'编辑'"
                              @click.native="edit(scope.row)">
                </basic-button>
              </template>
            </el-table-column>
          </basic-table-pager>
        </div>
      </pane>
      <pane label="地标介绍"
            name="5">
        <div v-if="currentTab==='5'"
             class="paneContain">
          <basic-table-pager :tableKey="tableKey5"
                             :tablep="tablep"
                             :params="listParams">
            <el-table-column width="150px"
                             label="操作">
              <template v-slot="scope">
                <basic-button :type="'text'"
                              :title="'编辑'"
                              @click.native="edit(scope.row)">
                </basic-button>
              </template>
            </el-table-column>
          </basic-table-pager>
        </div>
      </pane>
      <pane label="动线介绍"
            name="6">
        <div v-if="currentTab==='6'"
             class="paneContain">
          <basic-table-pager :tableKey="tableKey6"
                             :tablep="tablep"
                             :params="listParams">
            <el-table-column width="150px"
                             label="操作">
              <template v-slot="scope">
                <basic-button :type="'text'"
                              :title="'编辑'"
                              @click.native="edit(scope.row)">
                </basic-button>
              </template>
            </el-table-column>
          </basic-table-pager>
        </div>
      </pane>

    </tabs>
  </div>
</template>

<script>
import Tabs from '../../components/basic/tabs';
import Pane from '../../components/basic/pane';
import BasicButton from '../../components/basic/basicButton';
import BasicTablePager from '../../components/basicTablePager';
export default {
  name: "planInfo",
  components:{Tabs,Pane,BasicButton,BasicTablePager},
  data(){
    return{
      currentTab:"1",
      tableKey: [
        {
          name: '是否已经编辑',
          value: 'editFlag',
          formatVal: (row, column, cellValue) => {
            return cellValue===0?'否':'是'
          }
        },
        {
          name: '设施介绍-中文',
          value: 'introduction',
        },
        {
          name: '设施介绍-英文',
          value: 'introductionEng',
        },
      ],
      tableKey3: [
        {
          name: '业态类型名称',
          value: 'businessTypeName',
        },
        {
          name: '是否已经编辑',
          value: 'editFlag',
          formatVal: (row, column, cellValue) => {
            return cellValue===0?'否':'是'
          }
        },
        {
          name: '业态信息-中文',
          value: 'introduction',
        },
        {
          name: '业态信息-英文',
          value: 'introductionEng',
        },
      ],
      tableKey5: [
        {
          name: '名称',
          value: 'name',
        },
        {
          name: '所属区块名称',
          value: 'regionName',
        },
      ],
      tableKey6: [
        {
          name: '名称',
          value: 'name',
        },
        {
          name: '是否已经编辑',
          value: 'editFlag',
          formatVal: (row, column, cellValue) => {
            return cellValue===0?'否':'是'
          }
        },
        {
          name: '动线介绍信息-中文',
          value: 'introduction',
        },
        {
          name: '动线介绍信息-英文',
          value: 'introductionEng',
        },
      ],
      //表格URL
      tablep: {
        url: '/private/o-wms/v1/queryRegionList',
        isNum: true,
      },
      //传给表格分页组件的参数
      listParams: {
        data: {},
      },
    }
  },
  created(){
    this.getList();
  },
  methods:{
    getList(val){
      if(val){
        this.listParams.data.outdoorPoiType=3;
        this.listParams.data.page = {pageNum: '', pageSize: ''};
      }else{
        this.listParams.data = {};
        this.listParams.data.page = {pageNum: '', pageSize: ''};
      }
    },
    edit(val){

    },
    clickPane(val){
      switch(val){
        case '1':
          this.tablep.url='/private/o-wms/v1/queryRegionList';
          this.getList();
          break;
        case '2':
          this.tablep.url='/private/o-wms/v1/queryTrafficList';
          this.getList();
          break;
        case '3':
          this.tablep.url='/private/o-wms/v1/queryBusinessTypeList';
          this.getList();
          break;
        case '4':
          this.tablep.url='/private/o-wms/v1/queryLandMassListList';
          this.getList();
          break;
        case '5':
          this.tablep.url='/private/o-wms/v1/queryOutdoorPoiList';
          this.getList(val);
          break;
        default:
          this.tablep.url='/private/o-wms/v1/queryKinetonemaList';
          this.getList();
      }
    },
    clickRelease(){

    },
    //点击手动释放弹框的取消
    cancelRsn() {

    },
    cancel(){

    },
    submit(val){

    }
  }
}
</script>

<style lang="scss">
.planInfo{
  min-width: 1070px;
  min-height: calc(100% - 80px);
  padding: 40px 55px;
  margin: 24px 54px 0;
  font-size: 14px;
  text-align: left;
  /*黑色字体*/
  $black: #323C47;
  /*灰色字体*/
  $gray: #A7ADB3;
  color: $black;
  .paneContain{
    padding:30px 0 0;

  }
}
</style>
